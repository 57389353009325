import * as React from 'react';
import { Assets, SortOrder } from 'components/Catalog/Interfaces/List/IListResponse';

interface IProps {
    orders: SortOrder[];
    assets: Assets;
    orderField: string;
}

const Toolbar = (props: IProps) => {
    const { orders, assets, orderField } = props;
    // hack to move the selected field on top as data-default does not work in this context
    orders.sort((x, y) => {
        return x.value === orderField ? -1 : y.value === orderField ? 1 : 0;
    });
    return (
        <React.Fragment>
            <div className="group">
                <label>
                    {assets && <span className="label">{assets.sortLabel}</span>}
                    <select data-key="product_list_order">
                        {orders &&
                            orders.map((order, key) => {
                                return (
                                    <option
                                        key={order.value}
                                        value={order.value}
                                        data-default={order.value === orderField}
                                    >
                                        {order.label}
                                    </option>
                                );
                            })}
                    </select>
                    {assets && (
                        <span className="togglebutton">
                            <a
                                href="?dir=asc"
                                data-key="product_list_dir"
                                data-value="asc"
                                data-default
                                className="active"
                            >
                                <span className="icon">
                                    <img src={assets.iconUp} alt="" />
                                </span>
                            </a>
                            <a href="?dir=desc" data-key="product_list_dir" data-value="desc">
                                <span className="icon">
                                    <img src={assets.iconDown} alt="" />
                                </span>
                            </a>
                        </span>
                    )}
                </label>
            </div>
            <div className="group">
                <label>
                    {assets && <span className="label">{assets.showLabel}</span>}
                    <span className="pillbutton">
                        <a href="?mode=grid" data-key="mode" data-value="grid" data-default className="active">
                            {assets && (
                                <span className="icon">
                                    <img src={assets.spriteGrid} alt="" />
                                </span>
                            )}
                        </a>
                        <a href="?mode=list" data-key="mode" data-value="list">
                            {assets && (
                                <span className="icon">
                                    <img src={assets.spriteList} alt="" />
                                </span>
                            )}
                        </a>
                    </span>
                </label>
            </div>
        </React.Fragment>
    );
};

export default Toolbar;
