import { empty } from '../../../../../helpers/empty';

export enum slice3Types {
    UPDATE = 'UPDATE',
}

interface IAction {
    type: string;
    payload?: ISliceConfiguration;
}

export interface ISliceConfiguration {
    finalPrice?: string;
    rawProductPrice?: number;
    isSliceValidationError?: boolean;
    maxSum?: string;
    minSum?: string;
    labels: ILabels;
    modalLabels: ISlice3ModalLabels;
}

interface ILabels {
    methodName: string;
    description: string;
    aboutSlice3: string;
    termsSlice3: string;
}

export interface ISlice3ModalLabels {
    slice3ModalTitle: string;
    slice3ModalDescription: string;
    slice3ModalConfirmLabel: string;
    slice3ModalNotAgreeButtonLabel: string;
}

export const slice3ConfigReducer = (state: ISliceConfiguration | undefined, action: IAction) => {
    switch (action.type) {
        case slice3Types.UPDATE:
            return !empty(action.payload) ? action.payload : undefined;
        default:
            return state;
    }
};
