import React, { useState } from 'react';
import ControlSwitch from 'vkid-ui/lib/Components/ControlSwitch';
import { useMutation, useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import SetHasInsuranceRequest, {
    ISetHasInsuranceRequestData,
} from 'components/InbankRental/requests/SetHasInsuranceRequest';
import { HttpStatusCode } from '../../enums/HttpStatusCode';
import GetHasInsuranceRequest, {
    IGetHasInsuranceResponse,
} from 'components/InbankRental/requests/GetHasInsuranceRequest';
interface IProps {
    config: InsuranceSwitcherConfig;
}

export interface InsuranceSwitcherConfig {
    extraInfo?: string;
    useInsuranceLabel: string;
}

const mapStateToProps = (state): IGetHasInsuranceResponse => {
    return {
        hasInsurance: state.entities.hasInbankRentalInsurance?.hasInsurance,
        switchingEnabled: state.entities.hasInbankRentalInsurance?.switchingEnabled,
    };
};

const InsuranceSwitcher = (props: IProps) => {
    const { extraInfo, useInsuranceLabel } = props.config;

    const [{}] = useRequest(GetHasInsuranceRequest);
    const [{ isPending }, setHasInsurance] = useMutation((data: ISetHasInsuranceRequestData) =>
        SetHasInsuranceRequest(data),
    );

    const { hasInsurance, switchingEnabled } = useSelector(mapStateToProps);

    const handleSwitch = () => {
        void setHasInsurance({ hasInsurance: !hasInsurance }).then((res) => {
            if (res.status !== HttpStatusCode.OK) {
                return;
            }

            window.dispatchEvent(new CustomEvent('hasInsuranceChanged'));
        });
    };

    return (
        <>
            {switchingEnabled && (
                <ControlSwitch
                    label={useInsuranceLabel}
                    isLabelVisible={true}
                    description={extraInfo ?? ''}
                    checked={hasInsurance}
                    onChange={handleSwitch}
                    disabled={isPending}
                />
            )}
        </>
    );
};

export default InsuranceSwitcher;
