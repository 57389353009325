import { request } from 'data/requests/request';

const productInbankRentalRequest = (productId: string, period?: string) =>
    request({
        type: 'productInbankRentalRequest',
        url: `inbank_rental/index/index?productId=${productId}&period=${period}`,
        notApi: true,
    });

export default productInbankRentalRequest;
