import * as React from 'react';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    buyBackSteps: string[];
}

const BuyBackSteps = (props: IProps) => {
    const { buyBackSteps } = props;

    return (
        <React.Fragment>
            <div className="buyback">
                <ol className="buyback__steps">
                    {buyBackSteps.map((htmlItem: string, key: number) => {
                        return <RenderHTML key={key} nowrapper={true} html={htmlItem} />;
                    })}
                </ol>
            </div>
        </React.Fragment>
    );
};
export default BuyBackSteps;
