import React from 'react';
import getSmartdealB2BFee, { IMonthlyFeeRequest } from 'components/SmartdealB2B/requests/GetSmartdealB2BFee';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { IB2BCalculatorLabels } from 'components/SmartdealB2B/SmartdealB2BCalculator';

interface IProps {
    productName: string;
    agreementDescription: string;
    sku: string;
    imageUrl: string;
    labels: IB2BCalculatorLabels;
}

const mapStateToProps = (state): IMonthlyFeeRequest => {
    return {
        formattedMonthlyFee: state.entities.smartdealB2BFee?.formattedMonthlyFee,
        paymentData: state.entities.smartdealB2BFee?.paymentData,
        productPrice: state.entities.smartdealB2BFee?.productPrice,
        extraData: state.entities.smartdealB2BFee?.extraData,
    };
};

const SmartdealB2BCalculatorPaymentData = (props: IProps) => {
    const { agreementDescription, sku, productName, labels, imageUrl } = props;

    const [{ isFinished, status }] = useRequest(getSmartdealB2BFee(sku));
    const { paymentData, productPrice, extraData } = useSelector(mapStateToProps);

    if (status !== 200 || !paymentData || !isFinished) {
        return <></>;
    }

    return (
        <>
            <img className="smartdeal-product-data__image" src={imageUrl} alt={productName} />
            <div className="smartdeal-product-data__content">
                <h5 className="smartdeal-product-data__title">{productName}</h5>
                <RenderHTML html={agreementDescription} nowrapper={true} />
                <p className="smartdeal-device-price">
                    <span>{labels.devicePriceExclVat}</span> <span>{productPrice}</span>
                </p>
                <table className="smartdeal-periods-table">
                    {paymentData.map((item, key) => {
                        return (
                            <tbody key={key}>
                                <tr className="primary">
                                    <th>{item.smartdeal.label}</th>
                                    <td>
                                        {item.smartdeal.value}/{labels.month}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{item.regular.label}</th>
                                    <td>
                                        <s>
                                            {item.regular.value}/{labels.month}
                                        </s>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{item.initialPayment.label}</th>
                                    <td>{item.initialPayment.value}</td>
                                </tr>
                            </tbody>
                        );
                    })}
                </table>
                <p className="fs-medium">{labels.legalLabel}</p>
                {extraData.map((extraDataRow, i) => {
                    return (
                        <p className="fs-medium" key={i}>
                            {extraDataRow}
                        </p>
                    );
                })}
            </div>
        </>
    );
};

export default SmartdealB2BCalculatorPaymentData;
