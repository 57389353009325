import React, { useEffect, useState } from 'react';
import Block from 'vkid-ui/lib/Components/Block';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    dom: string;
    config: {
        instanceId: number;
    };
}

const CtaPopup = (props: IProps): JSX.Element => {
    const { config } = props;
    const cacheKey = `cta-popup-${config.instanceId}`;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!hasSeenPopup()) {
            setVisible(true);

            window.addEventListener('unload', handleCloseOverlay);
        }

        return function cleanup() {
            window.removeEventListener('unload', handleCloseOverlay);
        };
    }, []);

    const hasSeenPopup = () => {
        const hasSeenPoup = window.sessionStorage.getItem(cacheKey);

        return hasSeenPoup === 'yes';
    };

    const handleCloseOverlay = () => {
        setVisible(false);
        window.sessionStorage.setItem(cacheKey, 'yes');
    };

    return (
        <Overlay
            isOpen={visible}
            canOutsideClickClose={true}
            doClose={handleCloseOverlay}
            layout="focusview"
            title="&nbsp;"
            buttonIcons={[
                {
                    icon: 'close',
                    onClick: () => handleCloseOverlay(),
                },
            ]}
            size="medium"
        >
            <Block>
                <RenderHTML html={props.dom} nowrapper />
            </Block>
        </Overlay>
    );
};

export default CtaPopup;
