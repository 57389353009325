import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import classNames from 'classnames';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

export interface IInbankRentalProps {
    cart: ICartTotals;
    emptyMessage?: string;
    tabActive: boolean;
    inbankRentalConfig: IInbankRentalConfig;
}

export interface IInbankRentalLabels {
    extraInfo?: string;
    useInsuranceLabel?: string;
}

export interface IInbankRentalConfig {
    inbankRentalLabel: string;
    interestLabel: string;
    depositLabel: string;
    contractFeeLabel: string;
    monthlyFeeLabel: string;
    deposit: string;
    interest: string;
    contractFee: string;
    monthlyFee: string;
    inbankRentalIntroductionHtml: string;
    inbankRentalTermsHtml: string;
    inbankRentalInfoLabel: string;
    tabMonthlyPaymentLabel: string;
}

const InbankRentalSidebar = (props: IInbankRentalProps) => {
    const { cart, emptyMessage, tabActive } = props;

    if (!cart || emptyMessage) {
        return <React.Fragment />;
    }

    return (
        <div className={classNames('layout-cart__sidebar', { hidden: !tabActive })}>
            <div className="cart-block primary">
                <h2>{props.inbankRentalConfig.inbankRentalLabel}</h2>
                <ul className="cart-prices smartdeal">
                    <li>
                        <span className="label">{props.inbankRentalConfig.depositLabel}</span>{' '}
                        <span className="value">
                            {<RenderHTML html={props.inbankRentalConfig.deposit} nowrapper={true} />}
                        </span>
                    </li>
                    <li>
                        <span className="label">{props.inbankRentalConfig.contractFeeLabel}</span>{' '}
                        <span className="value">{props.inbankRentalConfig.contractFee}</span>
                    </li>
                    <li>
                        <span className="label">{props.inbankRentalConfig.monthlyFeeLabel}</span>{' '}
                        <span className="value">{props.inbankRentalConfig.monthlyFee}</span>
                    </li>
                </ul>
                <p className="footnote02">
                    {props.inbankRentalConfig.inbankRentalInfoLabel && (
                        <RenderHTML html={props.inbankRentalConfig.inbankRentalInfoLabel} nowrapper={true} />
                    )}
                </p>
                <p className="footnote02">
                    {props.inbankRentalConfig.inbankRentalIntroductionHtml && (
                        <RenderHTML html={props.inbankRentalConfig.inbankRentalIntroductionHtml} nowrapper={true} />
                    )}
                    <br />
                    {props.inbankRentalConfig.inbankRentalTermsHtml && (
                        <RenderHTML html={props.inbankRentalConfig.inbankRentalTermsHtml} nowrapper={true} />
                    )}
                </p>
                <ul className={'cart-buttons'}>
                    <li>
                        <a className={'cart-button primary'} href={cart.checkoutUrl.value}>
                            {cart.checkoutUrl.label}
                        </a>
                    </li>
                    {cart.returnUrl && (
                        <li>
                            <a className={'cart-button'} href={cart.returnUrl.value}>
                                {cart.returnUrl.label}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default InbankRentalSidebar;
