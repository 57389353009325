import * as React from 'react';
import { IConfigPrice } from 'components/Catalog/Product/Detail';

interface IProps {
    price: IConfigPrice;
    oldPrice: string | undefined;
    inbankRentalDisabled?: boolean | undefined;
    children?: any;
}
const AdditionalProductHtml = (props: IProps) => {
    const { price, oldPrice } = props;

    return (
        <React.Fragment>
            {props.inbankRentalDisabled ? (
                <>
                    <div className="label">{price.label}</div>
                    <div className="price">{price.valueLabel}</div>
                    {!!oldPrice && (
                        <div className="price old">
                            <del>{oldPrice}</del>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <span className="label">{price.label}</span>
                    <span className="price">{price.valueLabel}</span>
                    {!!oldPrice && (
                        <span className="price">
                            <del>{oldPrice}</del>
                        </span>
                    )}
                </>
            )}
            {props.children}
        </React.Fragment>
    );
};

export default AdditionalProductHtml;
