import * as React from 'react';
import Icon from 'vkid-ui/lib/Components/Icon';
import { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { without } from 'lodash';
import { ActiveFilter } from 'components/Catalog/Interfaces/List/IListResponse';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { empty } from '../../../helpers/empty';

export interface IFilterTagsProps {
    activeFilters: ActiveFilter[];
    queryData: {
        [key: string]: string;
    };
    resetFilterLabel?: string;
}
interface IActiveFilter {
    key: string;
    value: string;
}

const tagsExcludeQueryParams: string[] = [
    'order',
    'direction',
    'p',
    'product_list_dir',
    'product_list_order',
    'mode',
    'slug',
];

const getFilterLabel = (item: IActiveFilter, activeFilters: ActiveFilter[]) => {
    let activeFilter = activeFilters.find((filter: ActiveFilter) => filter.id === item.key);

    const label: string | undefined =
        activeFilter?.originLabel && !empty(activeFilter?.originLabel)
            ? activeFilter?.originLabel
            : activeFilter?.label;

    return label ?? item.value;
};

const FilterTags: React.FunctionComponent<IFilterTagsProps> = (props) => {
    const { activeFilters, queryData } = props;

    const location = useLocation();
    const history = useHistory();
    const activeFilterStateless: IActiveFilter[] = [];
    const handleFilterParams = () => {
        for (const [key, value] of Object.entries(queryData)) {
            if (!tagsExcludeQueryParams.includes(key)) {
                ((value || '') as string).split(',').forEach((attribute) => {
                    activeFilterStateless.push({ key, value: attribute as string });
                });
            }
        }
    };
    handleFilterParams();

    const removeFilter = (filter: IActiveFilter) => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        const searchStrings = searchParams.get(filter.key)?.split(',');
        if (searchStrings && searchStrings.length < 2) {
            searchParams.delete(filter.key);
        }
        if (searchStrings && searchStrings.length > 1) {
            searchParams.set(filter.key, without(searchStrings, filter.value).join(','));
        }

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };
    const removeAll = () => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        activeFilterStateless.forEach((filter) => searchParams.delete(filter.key));

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    return (
        <React.Fragment>
            {activeFilterStateless.length > 0 && !!activeFilters && (
                <div className="product-tags">
                    <ul className="category-tree__tags__list">
                        {activeFilterStateless.map((item: IActiveFilter) => (
                            <li key={item.key}>
                                <button className="category-tree__tags__tag" onClick={() => removeFilter(item)}>
                                    <span className="wrapper">
                                        <span className="label">
                                            {<RenderHTML html={getFilterLabel(item, activeFilters)} nowrapper={true} />}
                                        </span>
                                        <Icon width={8} height={8} kind="remove" wrapperClassName="icon" />
                                    </span>
                                </button>
                            </li>
                        ))}
                        <li className="reset">
                            <button className="category-tree__tags__tag" onClick={removeAll}>
                                <span className="wrapper">
                                    <span className="label">{props.resetFilterLabel}</span>
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(FilterTags);
