import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import ListView from 'components/Catalog/Product/ListView';

interface IProps {
    dom: string;
}

const ListViewWrapper = (props: IProps) => {
    const { dom } = props;

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route exact={true} path="/:level1?/:level2?/:level3?/:level4?/:level5?/">
                        <ListView dom={dom} />
                    </Route>
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default ListViewWrapper;
