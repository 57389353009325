import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import paymentCheckRequest from 'data/requests/everyPay/paymentCheckRequest';
import paymentStatusRequest from 'data/requests/everyPay/paymentStatusRequest';

export interface IPaymentCheckProps {
    config: {
        waitingMessage: string;
        paymentCheckUrl: string;
        paymentStatusUrl: string;
        orderReference: string;
        paymentReference: string;
        successUrl: string;
        successMessage: string;
        failureMessage: string;
        failureUrl: string;
    };
}

const EveryPayLanding: React.FunctionComponent<IPaymentCheckProps> = (props) => {
    const { config } = props;
    const [{}, requestCheck] = useMutation(() =>
        paymentCheckRequest(props.config.paymentCheckUrl, config.orderReference),
    );
    const [{}, requestStatus] = useMutation(() =>
        paymentStatusRequest(config.paymentStatusUrl, config.orderReference, config.paymentReference),
    );

    const [message, setMessage] = useState<string>(config.waitingMessage);

    const handleCheck = () => {
        requestCheck().then((response) => {
            if (response.body.result != null && !response.body.isError && response.body.result === true) {
                setMessage(props.config.successMessage);
                window.location.replace(props.config.successUrl);
                return;
            }
            setMessage(props.config.failureMessage);
            window.location.replace(props.config.failureUrl);
            return;
        });
    };

    useEffect(() => {
        requestStatus()
            .then(() => handleCheck())
            .catch(() => handleCheck());
    }, []);

    return (
        <React.Fragment>
            <h2>{message}</h2>
        </React.Fragment>
    );
};

EveryPayLanding.displayName = 'Every pay landing';

export default EveryPayLanding;
