import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { IRequestData, request } from 'data/requests/request';
import Summary from 'vkid-ui/lib/Applications/Checkout/Components/Summary';
import { ISummaryMeta, ISummaryProduct } from 'vkid-ui/lib/Applications/Checkout/Components/Summary/index';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { MutableRefObject } from 'react';
import { hiDiscount } from '../../../components/checkout/cart/hiDiscount';
import { trhDiscount } from '../../../components/checkout/cart/trhDiscount';
import { sum } from '../../../helpers/cart/sum';
import { ContentLoading } from '../../../components/loading/ContentLoading';
import useErrorState from '../../../components/error/Error';
import InbankRentalExtra from '../../../components/checkout/cart/InbankRental/InbankRentalExtra';
import { empty } from '../../../helpers/empty';
import useSlice3Cart from 'data/requests/product/slice3/hooks/useSlice3Cart';

export interface IProps {
    customer: ICustomer | null;
    cart: any;
    shippingPrice: MutableRefObject<number>;
    setCart: any;
}

const A_MONEY_SEGMENT = 'apotheka_money';
const PRODUCT_CAMPAIGN_SEGMENT = 'product_campaign_discount';
const GIFT_CARD_SEGMENT = 'gift_card_discount';
const HI_SEGMENT = 'health_insurance';
const TRH_SEGMENT = 'trh';

const Cart = (props: IProps) => {
    const { customer, cart, setCart, shippingPrice } = props;
    const queryingCart = useRef(false);
    const { t } = useTranslation();
    const data: IRequestData = {
        type: 'cart',
        url: isLoggedIn ? 'carts/mine/totals' : `guest-carts/${window.quoteIdMask}/totals`,
    };
    const { isSlice3Cart, slice3Label, rawPrice, formattedPrice } = useSlice3Cart();
    const [{ isFinished, status }, cartRequest] = useMutation(() => request(data));
    const responseSuccessful = () => !!cart && status === 200;
    const { setError } = useErrorState();
    const [updateState, setUpdateState] = useState(false);
    const metaDefault: ISummaryMeta[] = [];
    const cartMetaData = useRef(metaDefault);

    let cartItems: ISummaryProduct[] =
        responseSuccessful() &&
        cart.items.map((item, key) => {
            const sumAddOn: React.ReactNode[] = [];

            if (window.isTrhEnabled) {
                sumAddOn.push(hiDiscount(item));
                sumAddOn.push(trhDiscount(item));
            }
            return {
                name: item.name,
                amount: item.qty,
                sum: `${sum(item.row_total_incl_tax)}&nbsp;€`,
                sumAddOn,
            };
        });

    if (!cartItems) {
        cartItems = [];
    }
    const getSegmentAttribute = (attributeName: string, usePartialSearch: boolean = false) => {
        if (responseSuccessful()) {
            const attribute = cart.total_segments.find((item) => item.code === attributeName);
            if (attribute) {
                return attribute;
            }

            if (usePartialSearch) {
                const attributes = cart.total_segments.filter((item) => {
                    return item.code.substr(0, attributeName.length) === attributeName;
                });

                if (attributes) {
                    return attributes;
                }
            }
        }
        return undefined;
    };

    const handleInbankRentalOrSummary = (meta: ISummaryMeta[], metaCart) => {
        if (window.inbankRentalSummary) {
            meta.push({
                label: window.inbankRentalSummary.depositLabel,
                sum: window.inbankRentalSummary.deposit,
            });
            meta.push({
                label: window.inbankRentalSummary.contractFeeLabel,
                sum: window.inbankRentalSummary.contractFee,
            });
            meta.push({
                label: window.inbankRentalSummary.monthlyFeeLabel,
                sum: window.inbankRentalSummary.monthlyFee,
            });
        } else {
            meta.push({
                label: t('checkout.Cart Total'),
                sum: `${metaCart ? sum(metaCart.base_grand_total) : 0}&nbsp;€`,
                total: true,
            });
        }
    };

    const updateMeta = () => {
        const metaCart = cart;
        if (!metaCart || !responseSuccessful()) {
            return;
        }
        if (metaCart) {
            metaCart.base_grand_total = parseFloat(
                (metaCart.base_grand_total - (cart?.shipping_incl_tax || 0)).toFixed(2),
            );
            metaCart.base_grand_total = parseFloat((metaCart.base_grand_total + shippingPrice.current).toFixed(2));
            metaCart.shipping_incl_tax = shippingPrice.current;
        }
        let subtotal = metaCart ? metaCart.subtotal_incl_tax : 0;
        let hkSegment;
        let trhSegment;
        if (window.isTrhEnabled) {
            hkSegment = getSegmentAttribute(HI_SEGMENT);
            trhSegment = getSegmentAttribute(TRH_SEGMENT);
            if (hkSegment) {
                subtotal += hkSegment.value;
            }
            if (trhSegment) {
                subtotal += trhSegment.value;
            }
        }

        const meta: ISummaryMeta[] = [];
        if (!window.inbankRentalSummary) {
            meta.push({
                label: t('checkout.Cart Subtotal'),
                sum: `${sum(subtotal)}&nbsp;€`,
            });

            meta.push({
                label: t('checkout.Shipping'),
                sum: `${metaCart ? sum(metaCart.shipping_incl_tax) : 0}&nbsp;€`,
            });
        }

        if (isSlice3Cart) {
            meta.push({
                label: slice3Label,
                sum: `${sum(rawPrice)}&nbsp;€`,
            });
        }

        const productCampaignDiscount = getSegmentAttribute(PRODUCT_CAMPAIGN_SEGMENT, true);
        const giftCardDiscount = getSegmentAttribute(GIFT_CARD_SEGMENT);

        if (productCampaignDiscount) {
            productCampaignDiscount.forEach((discount) => {
                meta.push({
                    label: discount.title,
                    sum: `-${sum(discount.value)}&nbsp;€`,
                });
            });
        }

        if (giftCardDiscount) {
            meta.push({
                label: t('checkout.Gift Card Discount'),
                sum: `${sum(giftCardDiscount.value)}&nbsp;€`,
            });
        }

        if (cart.discount_amount) {
            meta.push({
                label: t('checkout.Cart discount'),
                sum: `${sum(cart.discount_amount)}&nbsp;€`,
            });
        }

        if (window.isTrhEnabled && hkSegment) {
            meta.push({
                label: hkSegment.title,
                sum: `-${sum(hkSegment.value)}&nbsp;€`,
            });
        }

        if (window.isTrhEnabled && trhSegment) {
            meta.push({
                label: trhSegment.title,
                sum: `-${sum(trhSegment.value)}&nbsp;€`,
            });
        }

        handleInbankRentalOrSummary(meta, metaCart);

        cartMetaData.current = meta;
    };
    updateMeta();

    const shippingDataSave = () => {
        updateCart(true);
    };

    const updateCart = (updateShippingPrice = false) => {
        if (!queryingCart.current) {
            queryingCart.current = true;
            cartRequest().then((response) => {
                queryingCart.current = false;
                if (updateShippingPrice) {
                    shippingPrice.current = response.body.shipping_incl_tax;
                }
                setCart(response.body);
            });
        }
    };
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        updateMeta();
        setUpdateState(!updateState);
    };

    if ((!cart && !queryingCart.current) || (!isFinished && !!cart)) {
        updateCart();
    }
    useEffect(() => {
        if (isFinished && !!cart && !responseSuccessful()) {
            setError(true);
        }
        window.addEventListener('shipping-additional-change', updateShipping);
        window.addEventListener('shipping-additional-save', shippingDataSave);
        return function cleanup() {
            window.removeEventListener('shipping-additional-save', shippingDataSave);
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });

    const inbankRentalSummaryComponent = window.inbankRentalSummary ? (
        <React.Fragment>
            <InbankRentalExtra inbankRentalSummary={window.inbankRentalSummary} />
        </React.Fragment>
    ) : null;

    return isFinished && responseSuccessful() ? (
        <React.Fragment>
            <Summary
                title={`${t('checkout.Order')} ${responseSuccessful() ? cart.extension_attributes.order_id ?? '' : ''}`}
                products={cartItems}
                meta={cartMetaData.current}
                extra={<>{inbankRentalSummaryComponent}</>}
            />
        </React.Fragment>
    ) : (
        <ContentLoading />
    );
};
export default Cart;
