import * as React from 'react';
import { includes } from 'lodash';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { IStoreState } from '../../helpers/rootReducer';
import BuyBack, { IBuyBackConfig } from 'components/BuyBack/BuyBack';

interface IProps {
    config: IBuyBackConfig;
}

const BuyBackModal = (props: IProps) => {
    const { config } = props;

    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    return (
        <Overlay
            layout="focusview"
            title={config.labels.modalTitle}
            isOpen={includes(openOverlays, 'buyBack')}
            doClose={() => {
                dispatch(closeOverlay({ name: 'all' }));
            }}
            zIndex={16000004}
            size="max"
            buttonIcons={[
                {
                    icon: 'close',
                    onClick: () => {
                        dispatch(closeOverlay({ name: 'all' }));
                    },
                },
            ]}
        >
            <BuyBack config={config} />
        </Overlay>
    );
};
export default BuyBackModal;
