import { request } from 'data/requests/request';

const productAvailabilityRequest = (productId: string) =>
    request({
        type: 'productAvailabilityRequest',
        url: `availability/index/index/productId/${productId}`,
        notApi: true,
    });

export default productAvailabilityRequest;
