import * as React from 'react';
import { Categories } from 'components/Catalog/Interfaces/List/IListResponse';

interface IProps {
    categories: Categories;
}

const CategoryFilters = (props: IProps) => {
    const { categories } = props;

    const categorySubComponents = (children) => {
        return (
            <React.Fragment>
                {!!children?.length && (
                    <ul>
                        {children.map((childCategory, filterKey) => {
                            return (
                                <li key={filterKey}>
                                    <a href={`${childCategory.url}`} className={childCategory.active ? 'active' : ''}>
                                        {childCategory.name}
                                    </a>
                                    {categorySubComponents(childCategory.children)}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className={'category-tree__group'} key={`group-0`}>
                <input type="checkbox" id={'sidebar-group-999'} defaultChecked={true} />
                <label htmlFor={'sidebar-group-999'}>{categories.label}</label>
                <ul>
                    {categories.items &&
                        categories.items.map((category) => {
                            return (
                                <li key={`cat-${category.id}`}>
                                    <a href={`${category.url}`} className={category.active ? 'active' : ''}>
                                        {category.name}
                                    </a>
                                    {categorySubComponents(category.children)}
                                </li>
                            );
                        })}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default CategoryFilters;
