import * as React from 'react';
import { ActiveFilter, Assets, Categories, Filter } from 'components/Catalog/Interfaces/List/IListResponse';
import CategoryFilters from 'components/Catalog/Product/ListView/CategoryFilters';
import Icon from 'vkid-ui/lib/Components/Icon/index';

interface IProps {
    filters: Filter[];
    activeFilters: ActiveFilter[];
    assets: Assets;
    categories: Categories;
}

const Filters = (props: IProps) => {
    const { filters, activeFilters, assets, categories } = props;
    const isSearch = window.location?.pathname?.startsWith('/catalogsearch');
    const PRICE_DELTA = 0.001;
    const getPrice = (price: number | undefined, filter: Filter, isMinPrice: boolean = true) => {
        if (!price) {
            return price;
        }
        const calculatedPrice = filter.taxRate ? price + price * (filter.taxRate / 100) : price;
        // min and max price are rounded by magento, add +-1 by this operation
        return Math.ceil(isMinPrice ? calculatedPrice - PRICE_DELTA : calculatedPrice + PRICE_DELTA);
    };
    return (
        <div className={'category-tree'}>
            {assets && (
                <a href="#" className="menutoggler">
                    <span className="icon">
                        <img src={assets.spriteFilter} alt="" />
                    </span>
                    <span className="label">{assets.openFilterLabel}</span>
                </a>
            )}
            {!isSearch && <CategoryFilters categories={categories} />}
            {filters &&
                filters.map((filter, key) => {
                    const isActive = !!activeFilters.find((activeFilter) => filter.id === activeFilter.id);
                    const htmlKey = `sidebar-group-${filter.id}`;
                    if (!filter.items.length || (!isSearch && filter.id === 'cat')) {
                        return <React.Fragment key={key} />;
                    }
                    return (
                        <div className={'category-tree__group'} key={`group-${filter.id}`}>
                            <input type="checkbox" id={htmlKey} defaultChecked={isActive || filter.id === 'cat'} />
                            <label htmlFor={htmlKey}>{filter.name}</label>
                            {filter.id === 'price' && (
                                <div
                                    className="range-select"
                                    data-key="price"
                                    data-min={filter.dataMin === filter.dataMax ? 0 : getPrice(filter.dataMin, filter)}
                                    data-max={getPrice(filter.dataMax, filter, false)}
                                    data-value={`${filter.dataMin}-${filter.dataMax}`}
                                />
                            )}
                            {filter.id !== 'price' && (
                                <ul>
                                    {filter.items &&
                                        filter.items.map((filterItem, filterKey) => {
                                            return (
                                                <li key={filterKey}>
                                                    {filterItem.isActive && (
                                                        <a
                                                            href={`${filterItem.url}`}
                                                            data-key={filter.id}
                                                            data-value={''}
                                                            className={'active'}
                                                        >
                                                            {filterItem.label + ' '}{' '}
                                                            <Icon width={10} height={10} kind="remove" />
                                                        </a>
                                                    )}
                                                    {!filterItem.isActive && (
                                                        <a
                                                            href={`${filterItem.url}`}
                                                            data-key={filter.id}
                                                            data-value={filterItem.id}
                                                        >
                                                            {filterItem.label}
                                                            <span className="count"> ({filterItem.count})</span>
                                                        </a>
                                                    )}
                                                </li>
                                            );
                                        })}
                                </ul>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default Filters;
