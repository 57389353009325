import * as React from 'react';
import { domToReact, htmlToDOM } from 'html-react-parser';

import './styles.scss';
import { useState } from 'react';

export interface ILongPageProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
    componentMapper: (maybeComponent: string, maybeContent: string, maybeConfig) => React.Component;
}

const LongPage: React.FunctionComponent<ILongPageProps> = (props) => {
    const domTree = Array.isArray(props.dom) ? props.dom : htmlToDOM(props.dom.replace(/>\s+</g, '><'));

    const [inputs, setInputs] = useState({});

    const changeInput = (event) => {
        setInputs(
            Object.assign({}, inputs, {
                [event.target.name]: event.target.value,
            }),
        );
    };
    const parserOptions = {
        replace: (domNode: any) => {
            if (domNode.name) {
                if (domNode.attribs['data-component']) {
                    const maybeConfig = JSON.parse(domNode.attribs['data-config'] || '{}');
                    const maybeComponent = domNode.attribs['data-component'];
                    const maybeContent = domNode.children ?? '';
                    const { class: className, ...rest } = domNode.attribs;
                    const mappedComponent = props.componentMapper(maybeComponent, maybeContent, maybeConfig);

                    if (mappedComponent) {
                        return (
                            <domNode.name className={className} {...rest}>
                                {mappedComponent}
                            </domNode.name>
                        );
                    }
                }
                if (
                    domNode.name === 'input' &&
                    (domNode.attribs.type === 'text' || domNode.attribs.type === 'number')
                ) {
                    const { style, name, value, ...rest } = domNode.attribs;

                    return (
                        <React.Fragment>
                            <input
                                name={name}
                                value={inputs[name] ? inputs[name] : ''}
                                onChange={(e) => changeInput(e)}
                                {...rest}
                            />
                        </React.Fragment>
                    );
                }
            }
            return false;
        },
    };

    // Main output
    return (
        <React.Fragment>
            <div className={`bootstrap`}>{domToReact(domTree, parserOptions)}</div>
        </React.Fragment>
    );
};

LongPage.displayName = 'LongPage';

export default LongPage;
