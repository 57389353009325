import * as React from 'react';
import Result from 'components/Catalog/Search/Result';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useKeypress } from 'vkid-ui/lib/Utilities/Generic/index';
import { useState } from 'react';

export interface IProps {
    queryString: string;
    // currentlyActive: number;
    // setTotalCount: (count: number) => void;
}
interface SearchResponse {
    products: [
        {
            name: string;
            url: string;
            price: string;
            priceCallout?: string;
            image: {
                image_url: string;
            };
        },
    ];
    totalCount: number;
}
const mapStateToProps = (state): SearchResponse => {
    return {
        products: state.entities.quickSearch,
        totalCount: state.entities.quickSearch?.length || 0,
    };
};
const Results = (props: IProps) => {
    const { queryString } = props;
    const [active, setActive] = useState(0);
    useRequest(
        request({ type: 'quickSearch', url: `search/ajax/suggest?q=${queryString}`, notApi: true, force: true }),
    );
    const { products, totalCount } = useSelector(mapStateToProps);

    useKeypress(
        'ArrowUp',
        () => {
            if (active > 0) {
                setActive(active - 1);
            } else {
                setActive(totalCount - 1);
            }
        },
        [totalCount, active],
    );

    useKeypress(
        'ArrowDown',
        () => {
            if (active < totalCount - 1) {
                setActive(active + 1);
            } else {
                setActive(0);
            }
        },
        [totalCount, active],
    );

    return (
        <ul className="search__results__list">
            {products &&
                products.map((product, i) => {
                    return (
                        <Result
                            key={product.name}
                            name={product.name}
                            imageUrl={product.image.image_url}
                            href={product.url}
                            price={product.price}
                            priceCallout={product.priceCallout}
                            active={active === i}
                        />
                    );
                })}
        </ul>
    );
};

export default Results;
