import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import Coupon from 'components/Cart/Coupon';
import classNames from 'classnames';
import { CartType } from 'components/Cart/Enum/CartType';
import { empty } from '../../helpers/empty';
import useSlice3Cart from 'data/requests/product/slice3/hooks/useSlice3Cart';

export interface IProps {
    cart: ICartTotals;
    cartContainer?: string;
    emptyMessage?: string;
    tabActive: boolean;
    activeBasket: string;
}

const CartSidebar = (props: IProps) => {
    const { cart, cartContainer, emptyMessage, tabActive } = props;

    const { isSlice3Cart, slice3Label, rawPrice, formattedPrice } = useSlice3Cart();

    if (!cart || emptyMessage || empty(cart.quoteId)) {
        return <React.Fragment />;
    }

    const getRedirectUrl = () => {
        switch (props.activeBasket) {
            case CartType.ESTO_SLICE3_CART:
                return `${cart.checkoutUrl.value}?esto_slice3`;
            case CartType.SLICE3_CART:
                return `${cart.checkoutUrl.value}?slice3`;
            default:
                return cart.checkoutUrl.value;
        }
    };

    return (
        <div className={classNames('layout-cart__sidebar', { hidden: !props.tabActive })}>
            <div className={'cart-block primary'}>
                <h2>{cart.totalsLabel}</h2>
                <ul className={'cart-prices'}>
                    <li>
                        <span className={'label'}>{cart.subtotal.label}</span>
                        <span className={'value'}>{cart.subtotal.value}</span>
                    </li>
                    <li>
                        <span className={'label'}>{cart.withoutTax.label}</span>
                        <span className={'value'}>{cart.withoutTax.value}</span>
                    </li>
                    {cart.taxes &&
                        cart.taxes.map((item, key) => (
                            <li key={key}>
                                <span className={'label'}>{item.label}</span>
                                <span className={'value'}>{item.value}</span>
                            </li>
                        ))}
                    {isSlice3Cart && (
                        <li>
                            <span className={'label'}>{slice3Label}</span>
                            <span className={'value'}>{formattedPrice}</span>
                        </li>
                    )}
                    <li className={'primary'}>
                        <span className={'label'}>{cart.grandTotal.label}</span>
                        <span className={'value'}>{cart.grandTotal.value}</span>
                    </li>
                </ul>
                <ul className={'cart-buttons'}>
                    <li>
                        <a className={'cart-button primary'} href={getRedirectUrl()}>
                            {cart.checkoutUrl.label}
                        </a>
                    </li>
                    {cart.returnUrl && (
                        <li>
                            <a className={'cart-button'} href={cart.returnUrl.value}>
                                {cart.returnUrl.label}
                            </a>
                        </li>
                    )}
                </ul>
                {cartContainer && <p dangerouslySetInnerHTML={{ __html: cartContainer }} />}
            </div>
            <Coupon quoteId={cart.quoteId} labels={cart.couponLabels} />
        </div>
    );
};

export default CartSidebar;
