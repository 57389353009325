import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormList from 'vkid-ui/lib/Components/FormList';
import FormListItem from 'vkid-ui/lib/Components/FormListItem';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
            formKey: string;
        };
        labels: {
            newPassword: string;
            confirmPassword: string;
            submitButton: string;
            errorMessage: string;
        };
    };
}

const ResetPasswordForm: React.FC<IProps> = ({ config }) => {
    const formElement = useRef<HTMLFormElement | null>(null);

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required'),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref<string>('password')], 'Passwords must match')
                .required(config.labels.confirmPassword),
        }),
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <div className="layout-login">
            <div className="layout-login__column">
                {formik.errors.password && formik.errors.password_confirmation && (
                    <div className="cart-callout intent-danger">{config.labels.errorMessage}</div>
                )}
                <form
                    action={config.formConfig.actionUrl}
                    method="post"
                    ref={formElement}
                    onSubmit={formik.handleSubmit}
                    id="form-validate"
                >
                    <input type="hidden" name="form_key" value={config.formConfig.formKey} />
                    <FormList>
                        <FormListItem
                            label={config.labels.newPassword}
                            error={formik.touched.password && formik.errors.password}
                            required={true}
                            labelFor="password"
                        >
                            <ControlInput
                                type="text"
                                name="password"
                                id="password"
                                secureTextEntry={true}
                                hasError={!!(formik.touched.password && formik.errors.password)}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormListItem>
                        <FormListItem
                            label={config.labels.confirmPassword}
                            error={formik.touched.password_confirmation && formik.errors.password_confirmation}
                            required={true}
                            labelFor="password_confirmation"
                        >
                            <ControlInput
                                type="text"
                                name="password_confirmation"
                                id="password_confirmation"
                                secureTextEntry={true}
                                hasError={
                                    !!(formik.touched.password_confirmation && formik.errors.password_confirmation)
                                }
                                value={formik.values.password_confirmation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormListItem>
                    </FormList>
                    <Buttons>
                        <Button type="submit" intent="primary" title={config.labels.submitButton} />
                    </Buttons>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
