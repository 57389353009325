import { request } from 'data/requests/request';

export interface InsuranceChangedResponse {
    inbankRentalMonthlyFee: string;
}

const setHasInsuranceRequest = (productId: number) => {
    return request({
        type: 'inbankRentalMonthlyFee',
        url: `crosssell/inbankRental/getMonthlyFee?productId=${productId}`,
        method: 'GET',
        notApi: true,
    });
};
export default setHasInsuranceRequest;
