import * as React from 'react';
import { IProductConfig, ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { empty } from '../../../../../helpers/empty';
import { CartType } from 'components/Cart/Enum/CartType';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { ISliceConfiguration } from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import { memo } from 'react';

interface IProps {
    isInterest: boolean;
    productId?: string;
    slice3MethodEnabled?: boolean;
    slice3Config?: ISliceConfiguration;
    cartFlowType: string;
    setCartFlowType: (value: string) => void;
    selectedProductInformation?: ISelectedProductInformation;
    productConfig: IProductConfig;
}

const Slice3Option = (props: IProps) => {
    const { isInterest, slice3MethodEnabled, cartFlowType, setCartFlowType, slice3Config } = props;

    return (
        <>
            {!empty(slice3MethodEnabled) && slice3Config && !empty(slice3Config.finalPrice) && (
                <li>
                    {!isInterest && (
                        <input
                            checked={cartFlowType === CartType.SLICE3_CART}
                            onChange={() => setCartFlowType(CartType.SLICE3_CART)}
                            type="radio"
                            name="r01"
                            id="r01_3"
                        />
                    )}
                    <div className="product-pricing__option">
                        <div className="columns">
                            <label htmlFor="r01_3" className="column">
                                <span className="label">{slice3Config.labels.methodName}</span>
                                <span className="price">{slice3Config.finalPrice}</span>
                            </label>
                            <div className="column">
                                <p className="size-large">
                                    <RenderHTML html={slice3Config.labels.description} nowrapper={true} />
                                </p>
                                <p>
                                    <>
                                        <RenderHTML html={slice3Config.labels.aboutSlice3} nowrapper={true} />
                                        <br />
                                        <RenderHTML html={slice3Config.labels.termsSlice3} nowrapper={true} />
                                    </>
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </>
    );
};

export default memo(Slice3Option);
