import FormRow from 'vkid-ui/lib/Components/FormRow/index';
import * as React from 'react';
import { IShippingMethod } from '../../../../interfaces/checkout/shipping/IShippingMethod';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { empty } from '../../../../helpers/empty';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm/index';
import ProceedButton from '../../../../components/checkout/ProceedButton';
import { useRef } from 'react';
import ControlInput from 'vkid-ui/lib/Components/ControlInput/index';
import { useMutation } from 'redux-query-react';
import { ResponseStatusEnum } from '../../../../enums/ResponseStatus';
import { dummyFields } from '../../../../components/address/dummyFields';
import { SHIPPING_METHOD_STEP } from '../../../../components/checkout/Content';
import { IAdditional, IAdditionalComponents } from '../../../../components/checkout/Shipping/Method/Additional';
import { request } from 'data/requests/request';
import ControlSelect from 'vkid-ui/lib/Components/ControlSelect/index';

interface IProps {
    selectedMethod?: IShippingMethod;
    proceedAction: () => void;
    resetAction: () => void;
    setAdditionalData: (additionalData) => void;
    shippingAdditional: any | undefined;
    setShippingAdditional: (additional) => void;
    setCurrentStep: (step: number) => void;
    continueDisabled: boolean;
    dropDownTitle: string;
    additionalComponents: IAdditionalComponents;
}

export interface IAgentAdditionalData extends IAdditional {
    shipit_agent_id: string;
    shipit_agent_data: {
        street_address: string;
        postcode: string;
        name: string;
        city: string;
        service_id: string;
        carrier: string;
    };
}
interface IAgent {
    id: string;
    address: string;
    carrier: string;
    carrierLogo: string;
    city: string;
    country: string;
    name: string;
    postcode: string;
    serviceId: string;
}

const ShipitDelivery = (props: IProps) => {
    const {
        selectedMethod,
        proceedAction,
        setCurrentStep,
        setAdditionalData,
        shippingAdditional,
        setShippingAdditional,
        additionalComponents,
        dropDownTitle,
        resetAction,
    } = props;
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<IAgent>();
    const [postCode, setPostCode] = useState('');
    const [list, setList] = useState<IAgent[]>();
    const defaultSelected = useRef(false);
    const querying = useRef(false);

    useEffect(() => {
        setList([]);
        defaultSelected.current = false;
        resetState();
        findAgent();
    }, [selectedMethod]);

    const selectItem = (item) => {
        if (!defaultSelected.current) {
            defaultSelected.current = true;
        }

        setSelectedItem(item);
    };
    const url = selectedMethod?.extra_data?.ajax_url || '';
    const methodCode = selectedMethod?.methodCode;
    const [{}, methodsRequest] = useMutation(() =>
        request({
            type: `postOffice`,
            url,
            data: {
                postcode: postCode,
                method: methodCode ? methodCode.substring(methodCode.indexOf('_') + 1) : methodCode,
                country: 'FI',
            },
        }),
    );

    const resetState = () => {
        if (selectedItem) {
            setSelectedItem(undefined);
        }
    };

    const findAgent = () => {
        setCurrentStep(SHIPPING_METHOD_STEP);
        if (postCode && postCode !== dummyFields.postcode && postCode.length === 5) {
            if (!empty(url)) {
                querying.current = true;
                methodsRequest().then((response) => {
                    const result: IAgent[] = JSON.parse(response.body);
                    if (response.status === ResponseStatusEnum.ok) {
                        setList(result);
                    }
                });
            }
        } else {
            resetState();
        }
    };
    useEffect(() => {
        if (!querying.current) {
            findAgent();
        }
    });

    return (
        <React.Fragment>
            <div className="longtext">
                {selectedItem && selectedItem.address ? (
                    <p>
                        <b>{selectedItem.name}</b>
                        <br />
                        <span>{selectedItem.address}</span>
                    </p>
                ) : (
                    <p>{t('checkout.To find the closest post office please fill in the postcode')}</p>
                )}
            </div>
            <React.Fragment>
                <LayoutForm layout="vertical">
                    <FormRow label={t('checkout.Postcode')} required={true}>
                        <ControlInput
                            value={postCode}
                            onChange={(e) => {
                                if (e.target.value.length < 6) {
                                    setPostCode(e.target.value);
                                    querying.current = false;
                                }
                            }}
                        />
                    </FormRow>
                    {list && list.length > 0 && (
                        <FormRow label={dropDownTitle} required={true}>
                            <ControlSelect
                                disableSearch={false}
                                value={selectedItem ? selectedItem.name : ''}
                                onChange={(e) => {
                                    resetAction();
                                    selectItem(e.target.value);
                                }}
                            >
                                <option value="" />
                                {list.map((location) => {
                                    return (
                                        <option value={location as any} key={location.id}>
                                            {location.name + ' ' + location.address}
                                        </option>
                                    );
                                })}
                            </ControlSelect>
                        </FormRow>
                    )}
                    {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
                </LayoutForm>
            </React.Fragment>
            <ProceedButton
                proceedAction={() => {
                    if (selectedItem) {
                        const additionalData: IAgentAdditionalData | undefined = {
                            shipit_agent_id: selectedItem.id,
                            shipit_agent_data: {
                                street_address: selectedItem.address,
                                postcode: selectedItem.postcode,
                                name: selectedItem.name,
                                city: selectedItem.city,
                                service_id: selectedItem.serviceId,
                                carrier: selectedItem.carrier,
                            },
                            courier_info: additionalComponents.courierInfo.exportField,
                        };
                        setAdditionalData(additionalData);
                        if (selectedMethod) {
                            shippingAdditional[selectedMethod.methodCode] = additionalData;
                            setShippingAdditional(shippingAdditional);
                        }
                        proceedAction();
                    }
                }}
                disabled={!selectedItem?.address || props.continueDisabled}
            />
        </React.Fragment>
    );
};
export default ShipitDelivery;
