import { request } from 'data/requests/request';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';

const getCouponRequest = (quoteIdMask?: string) =>
    request({
        type: 'coupons',
        url: isLoggedIn() ? `carts/mine/coupons` : `guest-carts/${quoteIdMask}/coupons`,
    });

export default getCouponRequest;
