import * as React from 'react';
import { memo, useState } from 'react';
import { IAttribute, IAttributeOptions } from 'components/Catalog/Interfaces/IAttribute';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { empty } from '../../../helpers/empty';
import Configurator, {
    ISelectedAttributes,
    ISelectedSwatch,
    ISwatchConfig,
} from 'components/Catalog/Product/Configurator';

interface IProps {
    config: ISwatchConfig;
    setSelectedProductInformation: (selectedProductInformation: ISelectedProductInformation) => void;
}

const findDefaultOption = (filteredAttribute: IAttribute) => {
    const searchParams = new URLSearchParams(location.search);

    const queryParamValue = searchParams.get(filteredAttribute.code);
    if (empty(queryParamValue)) {
        return filteredAttribute.options.find((option: IAttributeOptions) => option)?.id;
    }

    const selectedOption = filteredAttribute.options.find((option: IAttributeOptions) => option.id === queryParamValue);

    if (!selectedOption || empty(selectedOption)) {
        return filteredAttribute.options.find((option: IAttributeOptions) => option)?.id;
    }

    return selectedOption.id;
};

const ConfiguratorWrapper = (props: IProps) => {
    const { config, setSelectedProductInformation } = props;

    let defaultSelectedMainSwatch;
    let defaultSelectedSecondarySwatch;

    const attributes: IAttribute[] = config.options.attributes;

    const indexedAttribute = attributes.reduce((result, attribute: IAttribute) => {
        if (!result[attribute.id]) {
            result[attribute.id] = attribute;
        }
        return result;
    }, {});

    let secondaryAttributes: IAttribute[] = [];
    Object.keys(attributes).filter((attribute: string) => {
        secondaryAttributes.push(attributes[attribute]);
    });
    const selectedDefaults = secondaryAttributes.map((filteredAttribute: IAttribute) => {
        return {
            attributeId: filteredAttribute.id,
            optionId: findDefaultOption(filteredAttribute),
        } as ISelectedAttributes;
    });

    const [selectedAttributes, selectAttributes] = useState<ISelectedAttributes[]>(selectedDefaults);

    if (!defaultSelectedMainSwatch) {
        const firstSelectedDefault = selectedAttributes.find(
            (defaultSelection: ISelectedAttributes) => defaultSelection,
        );

        if (firstSelectedDefault) {
            const attributeId = firstSelectedDefault.attributeId.toString();
            const optionId = firstSelectedDefault.optionId.toString();
            const option = indexedAttribute[attributeId].options.find((option) => option.id.toString() === optionId);

            defaultSelectedMainSwatch = {
                attributeId,
                optionId,
                products: option.products,
            };
        }
    }

    if (defaultSelectedMainSwatch) {
        const secondSelectedDefault = selectedAttributes.find(
            (defaultSelection: ISelectedAttributes) =>
                defaultSelection.attributeId !== defaultSelectedMainSwatch?.attributeId,
        );

        if (secondSelectedDefault) {
            const secondOption = indexedAttribute[secondSelectedDefault.attributeId.toString()].options.find(
                (option) => option.id.toString() === secondSelectedDefault.optionId.toString(),
            );

            defaultSelectedSecondarySwatch = {
                attributeId: secondSelectedDefault.attributeId.toString(),
                optionId: secondSelectedDefault.optionId.toString(),
                products: secondOption.products,
            };
        }
    }

    const [defaultSelectedMainSwatchStated, setDefaultSelectedMainSwatch] = useState<ISelectedSwatch>(
        defaultSelectedMainSwatch,
    );

    secondaryAttributes = secondaryAttributes.map((secondarySwatch: IAttribute) => {
        if (secondarySwatch.id === defaultSelectedMainSwatchStated?.attributeId) {
            return secondarySwatch;
        }
        const swatchOptions = secondarySwatch.options.filter(
            (swatchOption) =>
                swatchOption.products.filter(
                    (value) =>
                        defaultSelectedMainSwatchStated?.products.includes(value) &&
                        (!defaultSelectedSecondarySwatch ||
                            secondarySwatch.id === defaultSelectedSecondarySwatch?.attributeId ||
                            defaultSelectedSecondarySwatch?.products.includes(value)),
                ).length > 0,
        );
        return { ...secondarySwatch, ...{ options: swatchOptions } };
    });

    if (!defaultSelectedMainSwatch) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Configurator
                defaultSelectedMainSwatch={defaultSelectedMainSwatch}
                setDefaultSelectedMainSwatch={setDefaultSelectedMainSwatch}
                secondaryAttributes={secondaryAttributes}
                selectedAttributes={selectedAttributes}
                selectAttributes={selectAttributes}
                attributes={attributes}
                config={config}
                setSelectedProductInformation={setSelectedProductInformation}
            />
        </React.Fragment>
    );
};

export default memo(ConfiguratorWrapper);
