import { request } from 'data/requests/request';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';

const deleteCouponRequest = (quoteIdMask?: string) =>
    request({
        method: 'DELETE',
        type: 'coupons',
        url: isLoggedIn() ? `carts/mine/coupons` : `guest-carts/${quoteIdMask}/coupons`,
    });

export default deleteCouponRequest;
