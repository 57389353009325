import { request } from 'data/requests/request';

const termsSaveRequest = () =>
    request({
        type: 'termsSaveRequest',
        url: `terms_verify/verify/save`,
        method: 'POST',
        notApi: true,
    });

export default termsSaveRequest;
