import * as React from 'react';
import { debounce } from 'lodash';
import Block from 'vkid-ui/lib/Components/Block';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm';
import FormRow from 'vkid-ui/lib/Components/FormRow';
import ControlAutocomplete from 'vkid-ui/lib/Components/ControlAutocomplete';
import BuyBackSteps from 'components/BuyBack/BuyBackSteps';
import { useMutation } from 'redux-query-react';
import devicesRequest from 'components/BuyBack/requests/devicesRequest';
import { useCallback, useState } from 'react';
import { IAutocompleteOption } from 'vkid-ui/lib/Components/ControlAutocomplete/index';
import Questions from 'components/BuyBack/Questions';
import BuyBackFinalize from 'components/BuyBack/BuyBackFinalize';
import Extras from 'components/BuyBack/Extras';

interface IProps {
    config?: IBuyBackConfig;
}

export interface IBuyBackConfig {
    labels: {
        title: string;
        buttonLabel: string;
        modalTitle: string;
        firstQuestionTitle: string;
        selectedDevice: string;
        deviceValue: string;
        yes: string;
        no: string;
    };
    buyBackSteps: string[];
}

export interface IBuyBackDevice {
    model_id: string;
    model_name: string;
    model_price: number;
}

export interface IAnswer {
    question_id: number;
    answer_id: number;
}

const BuyBack = (props: IProps) => {
    const { config } = props;

    if (!config) {
        return <></>;
    }

    const { labels, buyBackSteps } = config;
    const [quickSearchValue, setQuickSearchValue] = useState('');
    const [mappedBuyBackDevices, setMappedBuyBackDevices] = useState<IAutocompleteOption[]>([]);
    const [buyBackDevices, setBuyBackDevices] = useState<IBuyBackDevice[]>([]);
    const [selectedBuyBackDevice, setSelectedBuyBackDevice] = useState<IBuyBackDevice>();
    const [selectedAnswers, setSelectedAnswers] = useState<IAnswer[]>();
    const [selectedExtras, setSelectedExtras] = useState<number[]>();

    const [{ isPending }, requestDevices] = useMutation((term) => devicesRequest(term));

    const mapBuyBackDevices = (devices: IBuyBackDevice[]) => {
        if (!devices) {
            setMappedBuyBackDevices([]);
        }

        setBuyBackDevices(devices);
        const mappedDevices = devices.map((device) => {
            return {
                label: device.model_name,
                value: device.model_id,
            } as IAutocompleteOption;
        });

        setMappedBuyBackDevices(mappedDevices);
    };

    const handleSearch = useCallback(
        debounce((term) => {
            void requestDevices(term).then((res) => {
                if (res.status !== 200) {
                    return;
                }

                mapBuyBackDevices(res.body);
            });
        }, 500),
        [],
    );

    return (
        <Block>
            <LayoutForm layout="vertical">
                <FormRow>
                    <BuyBackSteps buyBackSteps={buyBackSteps} />
                </FormRow>
                <FormRow label={labels.firstQuestionTitle}>
                    <ControlAutocomplete
                        value={quickSearchValue}
                        data={mappedBuyBackDevices}
                        onChange={(searchValue) => {
                            setQuickSearchValue(searchValue);
                            handleSearch(searchValue);
                        }}
                        loading={isPending}
                        onSelect={(v) => {
                            setSelectedBuyBackDevice(
                                buyBackDevices.find((device) => device.model_id.toString() === v.value),
                            );
                            setSelectedAnswers([]);
                            setSelectedExtras([]);
                        }}
                        minChars={3}
                    />
                </FormRow>

                {selectedBuyBackDevice && (
                    <React.Fragment key={selectedBuyBackDevice.model_id}>
                        <FormRow label={labels.selectedDevice} value={selectedBuyBackDevice?.model_name} />
                        <hr />
                        <Questions selectedDevice={selectedBuyBackDevice} setParentAnswers={setSelectedAnswers} />
                        <Extras setParentExtras={setSelectedExtras} yesLabel={labels.yes} noLabel={labels.no} />
                    </React.Fragment>
                )}
                {selectedBuyBackDevice && selectedAnswers && selectedAnswers.length > 0 && (
                    <BuyBackFinalize
                        selectedDevice={selectedBuyBackDevice}
                        selectedAnswers={selectedAnswers}
                        selectedExtras={selectedExtras}
                        deviceValueLabel={labels.deviceValue}
                    />
                )}
            </LayoutForm>
        </Block>
    );
};
export default BuyBack;
