import React from 'react';
import { CartType } from 'components/Cart/Enum/CartType';
import { useRequest } from 'redux-query-react';
import estoSlice3Request from 'components/Catalog/Product/DetailComponent/EstoSlice3/requests/estoSlice3Request';
import { ISlice3ModalLabels } from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';

interface IProps {
    isInterest: boolean;
    productId?: string;
    setCartFlowType: (string) => void;
    cartFlowType: string;
    estoSlice3Config: IEstoSlice3Config;
}

export interface IEstoSlice3Config {
    isEnabled: boolean;
    cartMax: number;
    cartMin: number;
    labels: {
        title: string;
        priceLabel: string;
    };
    product: {
        finalPrice: string;
        rawProductPrice: number;
        isSliceValidationError: boolean;
        maxSumAmount: number;
        maxSum: number;
        minSum: number;
    };
    modalLabels: ISlice3ModalLabels;
}

const EstoSlice3Option = (props: IProps) => {
    const { isInterest, cartFlowType, setCartFlowType, productId, estoSlice3Config } = props;

    if (!productId) {
        return <></>;
    }

    const [{}] = useRequest(estoSlice3Request(productId ?? ''));

    return (
        <>
            {estoSlice3Config && estoSlice3Config.isEnabled && (
                <li>
                    {!isInterest && (
                        <input
                            checked={cartFlowType === CartType.ESTO_SLICE3_CART}
                            onChange={() => setCartFlowType(CartType.ESTO_SLICE3_CART)}
                            type="radio"
                            name="r01"
                            id="r01_3"
                        />
                    )}
                    <div className="product-pricing__option">
                        <div className="columns">
                            <label htmlFor="r01_3" className="column">
                                <span className="label">{estoSlice3Config.labels.priceLabel}</span>
                                <span className="price">{estoSlice3Config.product.finalPrice}</span>
                            </label>
                            <div className="column">
                                <p className="size-large">{estoSlice3Config.labels.title}</p>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </>
    );
};

export default EstoSlice3Option;
