import { request } from 'data/requests/request';

const devicesRequest = (term: string) =>
    request({
        type: 'buyBackDevices',
        url: `buyback/ajax/querydevices?term=${term}`,
        method: 'GET',
        notApi: true,
    });

export default devicesRequest;
