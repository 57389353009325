import { request } from 'data/requests/request';

const termsVerificationRequest = () =>
    request({
        type: 'termsVerificationRequest',
        url: `terms_verify/verify/check`,
        method: 'GET',
        notApi: true,
        force: true,
    });

export default termsVerificationRequest;

export interface ITermsVerificationResponse {
    needToRender: boolean;
}
