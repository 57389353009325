import React from 'react';
import { useRequest } from 'redux-query-react';
import priceQuoteRequest, { IPriceQuote } from 'components/BuyBack/requests/priceQuoteRequest';
import { useSelector } from 'react-redux';
import Callout from 'vkid-ui/lib/Components/Callout';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { IAnswer, IBuyBackDevice } from 'components/BuyBack/BuyBack';
import LoadingContent from 'vkid-ui/lib/Components/LoadingContent';

interface IProps {
    selectedDevice: IBuyBackDevice;
    selectedAnswers: IAnswer[];
    selectedExtras?: number[];
    deviceValueLabel: string;
}

const mapStateToProps = (state) => {
    return {
        priceQuote: state.entities?.buyBackPriceQuote?.priceQuote as IPriceQuote,
    };
};

const BuyBackFinalize = (props: IProps) => {
    const { selectedDevice, selectedAnswers, selectedExtras, deviceValueLabel } = props;
    const [{ isPending }] = useRequest(
        priceQuoteRequest({
            answers: selectedAnswers,
            extras: selectedExtras ?? [],
            model_id: selectedDevice.model_id,
        }),
    );

    const { priceQuote } = useSelector(mapStateToProps);

    if (isPending) {
        return <LoadingContent layout="inline" />;
    }

    return (
        <>
            {priceQuote && (
                <>
                    <Callout intent="success">
                        {deviceValueLabel?.replace('%1', priceQuote.model_name)}
                        &nbsp;
                        {<RenderHTML nowrapper={true} html={`<b>${priceQuote.offered_price_formatted}</b>`} />}
                    </Callout>
                </>
            )}
        </>
    );
};

export default BuyBackFinalize;
