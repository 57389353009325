import { request } from 'data/requests/request';

const estoSlice3Request = (productId: string) =>
    request({
        type: 'estoSlice3Request',
        url: `esto_slice3/index/index`,
        data: {
            productId,
        },
        notApi: true,
    });

export default estoSlice3Request;
