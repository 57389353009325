import * as React from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'vkid-ui/lib/Components/Toaster';
import webformRequest from 'data/requests/webform/webformRequest';
import Message from 'components/Messages/Message';

export interface IWebformsProps {
    config: {
        title: string;
        buttonLabel: string;
        uuid: string;
        url: string;
    };
}

const WebformSubmit: React.FunctionComponent<IWebformsProps> = (props) => {
    const [{}, request] = useMutation((data: Object, url: string) => webformRequest(data, url));
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationMessageType, setNotificationMessageType] = useState<string>('');

    const handleSubmit = useCallback(async () => {
        setNotificationMessage('');
        // @ts-ignore
        const form: HTMLFormElement | null = document.getElementById(`webform_${props.config.uuid}`);
        if (!form) {
            return;
        }
        const formData = new FormData(form);

        const response = await request(formData, props.config.url);

        setNotificationMessage(response.body.message);

        if (response.status !== 200) {
            setNotificationMessageType('danger');
        }

        if (response.status === 200) {
            setNotificationMessageType('success');
            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response.body.message,
                asHtml: true,
            });
            form.reset();
        }
    }, []);

    return (
        <React.Fragment>
            <p>
                {notificationMessage !== '' && (
                    <Message type={notificationMessageType} text={notificationMessage} key={props.config.uuid} />
                )}
            </p>
            <p>
                <button
                    onClick={() => handleSubmit()}
                    type="button"
                    className="form-buttons__button intent-primary"
                    id={`webform_${props.config.uuid}_submit_button`}
                    title={props.config.title}
                >
                    <span>{props.config.buttonLabel}</span>
                </button>
            </p>
        </React.Fragment>
    );
};

WebformSubmit.displayName = 'Webform submit';

export default WebformSubmit;
