import * as React from 'react';
import { IInbankRentalSummary } from '../../../../interfaces/payment/IInbankRentalSummary';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    inbankRentalSummary: IInbankRentalSummary;
}

const InbankRentalExtra = (props: IProps) => {
    return (
        <React.Fragment>
            <p className={'footnote02'}>
                <RenderHTML nowrapper={true} html={props.inbankRentalSummary.inbankRentalInfoLabel} />
            </p>
            <p className={'footnote02'}>
                <RenderHTML nowrapper={true} html={props.inbankRentalSummary.inbankRentalIntroductionHtml} />
                <br />
                <RenderHTML nowrapper={true} html={props.inbankRentalSummary.inbankRentalTermsHtml} />
            </p>
        </React.Fragment>
    );
};

export default InbankRentalExtra;
