import React from 'react';
import InsuranceSwitcher, { InsuranceSwitcherConfig } from 'components/InbankRental/InsuranceSwitcher';

interface IProps {
    image: string;
    text?: string;
    description?: string;
    insuranceSwitcherConfig: InsuranceSwitcherConfig;
}

const InbankRentalCrossSellBanner = (props: IProps) => {
    const { image, text, description, insuranceSwitcherConfig } = props;
    return (
        <>
            <div className="frame-ideal__pre-cart__banner">
                <div className="frame-ideal__pre-cart__limiter">
                    <div
                        className="frame-ideal__pre-cart__banner__image"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    ></div>
                    <div className="frame-ideal__pre-cart__banner__text">
                        {text && <h2>{text}</h2>}
                        {description && <p>{description}</p>}
                        <InsuranceSwitcher config={insuranceSwitcherConfig} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default InbankRentalCrossSellBanner;
