import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';

interface IProps {
    method: string;
    paymentMethodProps: IPaymentMethodProps;
    to: string;
    title?: string;
    disableDefaultLogo?: boolean;
}

const BanklinkComponent = (props: IProps) => {
    const { method, to, title, disableDefaultLogo } = props;
    const redirect = { to };

    return (
        <SimplePayment
            key={method}
            method={method}
            disableDefaultLogo={disableDefaultLogo}
            onClick={() => props.paymentMethodProps.onClick({ redirect })}
            label={title ?? ''}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default BanklinkComponent;
