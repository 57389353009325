import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { compose } from 'redux';
import FramePage from '../../../components/framePage/FramePage';

export interface IScreenError404Props extends WithTranslation {}

export interface IScreenError404State {}

class Error404 extends React.Component<IScreenError404Props, IScreenError404State> {
    static displayName: string = 'Error404';

    render() {
        return (
            <FramePage title={this.props.t('Sections.Dashboard')}>
                <div>
                    <h1>{this.props.t('Sections.Error')}</h1>
                    <span>{this.props.t('Sections.Page not found')}</span>
                </div>
            </FramePage>
        );
    }
}

export default compose<any>(withTranslation())(Error404);
