import * as React from 'react';

let redirectDone: boolean = false;

export interface IRedirectProps {
    to: string;
    absolute?: boolean;
}

const RedirectAbsolute = (props: IRedirectProps) => {
    const { to, absolute } = props;
    if (!redirectDone) {
        redirectDone = true;
        let url = '';
        if (!absolute) {
            const apiUrl = window.API_URL;
            url = `${apiUrl}/${to}`;
        } else {
            url = `${to}`;
        }
        window.location.href = url;
    }
    return <React.Fragment />;
};

export default RedirectAbsolute;
