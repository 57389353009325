import React from 'react';
import { ISmartdealB2BConfig } from 'components/Catalog/Product/Detail';
import { useRequest } from 'redux-query-react';
import getSmartdealB2BFee, { IMonthlyFeeRequest } from 'components/SmartdealB2B/requests/GetSmartdealB2BFee';
import { useSelector } from 'react-redux';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    config: ISmartdealB2BConfig;
    sku: string;
}

const mapStateToProps = (state) => {
    return {
        formattedMonthlyFee: state.entities.smartdealB2BFee?.formattedMonthlyFee,
        paymentData: state.entities.smartdealB2BFee?.paymentData,
    };
};

const SmartdealB2BButton = (props: IProps) => {
    const { config, sku } = props;

    const [{}] = useRequest(getSmartdealB2BFee(sku));

    const { formattedMonthlyFee } = useSelector(mapStateToProps);

    return (
        <>
            {formattedMonthlyFee && (
                <p className="smartdeal-business">
                    <a href={config.url ?? ''}>
                        <span>{config.labels.pay}</span>
                        <img src={config.imageUrl} height="35" width="76" alt={config.labels.smartdealBusiness} />
                        <span>
                            <RenderHTML html={formattedMonthlyFee} nowrapper={true} />
                        </span>
                    </a>
                </p>
            )}
        </>
    );
};

export default SmartdealB2BButton;
