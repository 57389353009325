import * as React from 'react';
import { IAvailabilityResponse } from 'components/Catalog/Product/Interfaces/IAvailability';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import productAvailabilityRequest from 'data/requests/product/availability/productAvailabilityRequest';

interface IProps {
    items: IAvailabilityResponse[];
    availabilityLabel: string;
    productId?: string;
    setIsLoadingAvailability: (isLoading: boolean) => void;
}

const Availability = (props: IProps) => {
    const { items, availabilityLabel, productId, setIsLoadingAvailability } = props;
    const [availability, setAvailability] = useState(items);
    const [{}, availabilityRequest] = useMutation((id: string) => productAvailabilityRequest(id));

    useEffect(() => {
        if (productId) {
            setIsLoadingAvailability(true);
            availabilityRequest(productId).then((response) => {
                setAvailability(response.body);
                setIsLoadingAvailability(false);
            });
        }
    }, [productId]);

    return (
        <React.Fragment>
            {availability && availability.length > 0 && (
                <React.Fragment>
                    <hr />
                    <p>
                        <b>{availabilityLabel}</b>
                    </p>
                    <ul className="product-availability">
                        {availability.map((item) => {
                            return (
                                <li className={item.class} key={item.name}>
                                    <a>{item.name}</a>
                                    {item.message && (
                                        <>
                                            {' '}
                                            - <span className="status">{item.message}</span>
                                        </>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Availability;
