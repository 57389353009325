import { request } from 'data/requests/request';

interface ICalculateRequest {
    type: string;
    productId: number;
    period: string;
    downPayment: string;
}

const calculateRequest = (data: ICalculateRequest) =>
    request({
        method: 'POST',
        notApi: true,
        type: 'calculate',
        url: `financial/calculate/index/type/${data.type}/productId/${data.productId}/period/${data.period}/downPayment/${data.downPayment}`,
    });

export default calculateRequest;
