import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import cartSlice3Request from 'data/requests/product/slice3/cartSlice3Request';
import { useEffect, useState } from 'react';
import { CartType } from 'components/Cart/Enum/CartType';

export interface ISlice3Cart {
    rawPrice: number;
    formattedPrice: string;
    slice3Label: string;
    isSlice3Cart: boolean;
}

const mapStateToProps = (state) => {
    return {
        cartSlice3Data: state.entities?.cartSlice3Data as ISlice3Cart,
    };
};

const useSlice3Cart = (): ISlice3Cart => {
    const { cartSlice3Data } = useSelector(mapStateToProps);
    const [] = useRequest(cartSlice3Request);

    const [rawPrice, setRawPrice] = useState(0);
    const [formattedPrice, setFormattedPrice] = useState('');
    const [slice3Label, setSlice3Label] = useState('');
    const [isSlice3Cart, setIsSlice3Cart] = useState(
        window.sessionStorage.getItem('cartFlowSliceOrRegular') === CartType.SLICE3_CART,
    );

    useEffect(() => {
        setRawPrice(cartSlice3Data?.rawPrice);
        setFormattedPrice(cartSlice3Data?.formattedPrice);
        setSlice3Label(cartSlice3Data?.slice3Label);
    }, [cartSlice3Data]);

    return {
        rawPrice,
        formattedPrice,
        slice3Label,
        isSlice3Cart,
    };
};

export default useSlice3Cart;
