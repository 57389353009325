import { request } from 'data/requests/request';
import { IProductRequest } from 'data/requests/product/data/formProductRequest';

const checkoutInbankRentalRequest = (data: IProductRequest) =>
    request({
        type: 'productInbankRentalRequest',
        url: `inbank_rental/cart/add`,
        notApi: true,
        data,
        method: 'POST',
    });

export default checkoutInbankRentalRequest;
