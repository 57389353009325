import * as React from 'react';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import SimplePaymentButton from 'components/Checkout/components/checkout/Payment/Methods/SimplePaymentButton';

interface IProps {
    method: string;
    paymentMethodProps: IPaymentMethodProps;
    to: string;
    alt?: string;
    disableDefaultLogo?: boolean;
}

const PaymentButtonComponent = (props: IProps) => {
    const { method, to, alt, disableDefaultLogo } = props;
    const redirect = { to };

    return (
        <li className={`primary`}>
            <SimplePaymentButton
                key={method}
                method={method}
                disableDefaultLogo={disableDefaultLogo}
                onClick={() => props.paymentMethodProps.onClick({ redirect })}
                alt={alt ?? ''}
                paymentMethodProps={props.paymentMethodProps}
            />
        </li>
    );
};

export default PaymentButtonComponent;
