import { getHeaders } from '../../helpers/request/getHeaders';
import { QueryConfig, RequestBody } from 'redux-query';

export interface IRequestData {
    type: string;
    url: string;
    method?: string;
    data?: RequestBody;
    useStoreCode?: boolean;
    absoluteUrl?: boolean;
}

interface Window {
    API_URL: string;
    STORE_CODE: string;
}

export const postRequest = (data: IRequestData): QueryConfig => {
    const url = window.API_URL;
    let storeString = '';
    if (data.useStoreCode) {
        storeString = `${window.STORE_CODE}/`;
    }
    return {
        url: data.absoluteUrl ? data.url : `${url}/rest/${storeString}V1/${data.url}`,
        options: {
            method: data.method ?? 'POST',
            headers: getHeaders(),
        },
        body: data.data ?? {},
        transform: (response: any) => ({
            [`${data.type}`]: response as any,
        }),
        update: {
            [`${data.type}`]: (prev: any, next: any) => {
                return next;
            },
        },
    };
};
