import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormList from 'vkid-ui/lib/Components/FormList';
import FormListItem from 'vkid-ui/lib/Components/FormListItem';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';
import CheckboxWithLabel from 'vkid-ui/lib/Components/CheckboxWithLabel';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import classNames from 'classnames';

enum UserType {
    GENERAL = 'general',
    STUDENT = 'student',
    BUSINESS = 'business',
}

interface RegisterFormProps {
    config: {
        urls: {
            postActionUrl: string;
            successUrl: string;
            backUrl: string;
        };
        formKey: string;
        labels: {
            register: string;
            privateClient: string;
            businessClient: string;
            teacherOrStudent: string;
            personalInformation: string;
            companyInformation: string;
            addressInformation: string;
            signInInformation: string;
            firstName: string;
            lastName: string;
            email: string;
            password: string;
            confirmPassword: string;
            createAccount: string;
            back: string;
            requiredField: string;
            invalidEmail: string;
            passwordsMustMatch: string;
            passwordTooShort: string;
            passwordCharacterSetsError: string;
            agreeToTerms: string;
            newsSignUp: string;
            errorMessage: string;
            schoolName: string;
            uploadPhoto: string;
            companyName: string;
            fax: string;
            taxVat: string;
            clientType: string;
        };
        enabledFeatures: {
            businessGroupRegistration: boolean;
            studentRegistration: boolean;
            newsletterSubscription: boolean;
            customerTerms: boolean;
        };
        validationRules: {
            minPasswordLength: number;
            minPasswordCharacterSets: number;
        };
        countryData: {
            countries: { value: string; label: string }[];
            regions: Record<string, Array<{ code: string; name: string }>>;
        };
    };
}

const RegisterForm: React.FC<RegisterFormProps> = ({ config }) => {
    const formElement = useRef<HTMLFormElement | null>(null);
    const [tab, setTab] = useState(UserType.GENERAL);
    const [captchaToken, setCaptchaToken] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const hasTabs = config.enabledFeatures.studentRegistration || config.enabledFeatures.businessGroupRegistration;

    const validationSchema = Yup.object({
        firstname: Yup.string().required(config.labels.requiredField),
        lastname: Yup.string().required(config.labels.requiredField),
        email: Yup.string().email(config.labels.invalidEmail).required(config.labels.requiredField),
        password: Yup.string()
            .min(config.validationRules.minPasswordLength, config.labels.passwordTooShort)
            .test('character-sets', config.labels.passwordCharacterSetsError, (value) => {
                if (!value) return false;
                const requiredSets = config.validationRules.minPasswordCharacterSets;

                let characterSetCount = 0;
                if (/[a-z]/.test(value)) characterSetCount += 1;
                if (/[A-Z]/.test(value)) characterSetCount += 1;
                if (/[0-9]/.test(value)) characterSetCount += 1;
                if (/[^a-zA-Z0-9]/.test(value)) characterSetCount += 1;

                return characterSetCount >= requiredSets;
            })
            .required(config.labels.requiredField),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password')], config.labels.passwordsMustMatch)
            .required(config.labels.requiredField),
        is_customer_terms_checked: Yup.bool().when([], {
            is: () => config.enabledFeatures.customerTerms,
            then: () => Yup.bool().oneOf([true], config.labels.requiredField),
        }),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            password_confirmation: '',
            is_subscribed: false,
            is_customer_terms_checked: false,
            is_company: false,
            school_name: '',
            student_image: '',
            company_name: '',
            fax: '',
            tax_vat: '',
        },
        validationSchema,
        onSubmit: () => {
            getCaptchaToken().then((token: string) => {
                setCaptchaToken(token);
                setCanSubmit(true);
            });
        },
    });

    useEffect(() => {
        if (formElement.current && canSubmit) {
            formElement.current.submit();
        }
    }, [canSubmit]);

    return (
        <div className="layout-login">
            <div className="layout-login__column">
                <h2>{config.labels.register}</h2>
                <form
                    action={config.urls.postActionUrl}
                    method="post"
                    ref={formElement}
                    onSubmit={formik.handleSubmit}
                    id="register-form"
                >
                    <input type="hidden" name="form_key" value={config.formKey} />
                    <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
                    <div className="radio-group">
                        {!!hasTabs && (
                            <fieldset className="radio-group__tabs">
                                <label>
                                    <input
                                        type="radio"
                                        name="formtype"
                                        value={UserType.GENERAL}
                                        checked={tab === UserType.GENERAL}
                                        onChange={() => setTab(UserType.GENERAL)}
                                    />
                                    <span>{config.labels.privateClient}</span>
                                </label>
                                {config.enabledFeatures.businessGroupRegistration && (
                                    <label>
                                        <input
                                            type="radio"
                                            name="formtype"
                                            value={UserType.BUSINESS}
                                            checked={tab === UserType.BUSINESS}
                                            onChange={() => setTab(UserType.BUSINESS)}
                                        />
                                        <span>{config.labels.businessClient}</span>
                                    </label>
                                )}
                                {config.enabledFeatures.studentRegistration && (
                                    <label>
                                        <input
                                            type="radio"
                                            name="formtype"
                                            value={UserType.STUDENT}
                                            checked={tab === UserType.STUDENT}
                                            onChange={() => setTab(UserType.STUDENT)}
                                        />
                                        <span>{config.labels.teacherOrStudent}</span>
                                    </label>
                                )}
                            </fieldset>
                        )}
                        <FormList className={classNames('radio-group__content', hasTabs)}>
                            <h2>{config.labels.personalInformation}</h2>
                            <FormListItem
                                label={config.labels.firstName}
                                required
                                labelFor="first_name"
                                error={formik.touched.firstname && formik.errors.firstname}
                            >
                                <ControlInput
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    value={formik.values.firstname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    hasError={!!(formik.touched.firstname && formik.errors.firstname)}
                                />
                            </FormListItem>
                            <FormListItem
                                label={config.labels.lastName}
                                required
                                labelFor="last_name"
                                error={formik.touched.lastname && formik.errors.lastname}
                            >
                                <ControlInput
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    value={formik.values.lastname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    hasError={!!(formik.touched.lastname && formik.errors.lastname)}
                                />
                            </FormListItem>

                            {tab === UserType.STUDENT && (
                                <>
                                    <h2>{config.labels.teacherOrStudent}</h2>
                                    <FormListItem
                                        label={config.labels.schoolName}
                                        required
                                        labelFor="school_name"
                                        error={formik.touched.school_name && formik.errors.school_name}
                                    >
                                        <ControlInput
                                            type="text"
                                            name="school_name"
                                            id="school_name"
                                            value={formik.values.school_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            hasError={!!(formik.touched.school_name && formik.errors.school_name)}
                                        />
                                    </FormListItem>
                                    <FormListItem
                                        label={config.labels.uploadPhoto}
                                        labelFor="student_image"
                                        error={formik.touched.student_image && formik.errors.student_image}
                                    >
                                        <input
                                            type="file"
                                            name="student_image"
                                            id="student_image"
                                            onChange={(event) => {
                                                formik.setFieldValue('student_image', event.currentTarget.files?.[0]);
                                            }}
                                            className="input-text"
                                        />
                                    </FormListItem>
                                </>
                            )}

                            {tab === UserType.BUSINESS && (
                                <>
                                    <h2>{config.labels.companyInformation}</h2>
                                    <FormListItem
                                        label={config.labels.companyName}
                                        required
                                        labelFor="company_name"
                                        error={formik.touched.company_name && formik.errors.company_name}
                                    >
                                        <ControlInput
                                            type="text"
                                            name="company_name"
                                            id="company_name"
                                            value={formik.values.company_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            hasError={!!(formik.touched.company_name && formik.errors.company_name)}
                                        />
                                    </FormListItem>
                                    <FormListItem
                                        label={config.labels.fax}
                                        labelFor="fax"
                                        error={formik.touched.fax && formik.errors.fax}
                                    >
                                        <ControlInput
                                            type="text"
                                            name="fax"
                                            id="fax"
                                            value={formik.values.fax}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormListItem>
                                    <FormListItem
                                        label={config.labels.taxVat}
                                        required
                                        labelFor="tax_vat"
                                        error={formik.touched.tax_vat && formik.errors.tax_vat}
                                    >
                                        <ControlInput
                                            type="text"
                                            name="tax_vat"
                                            id="tax_vat"
                                            value={formik.values.tax_vat}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            hasError={!!(formik.touched.tax_vat && formik.errors.tax_vat)}
                                        />
                                    </FormListItem>
                                </>
                            )}

                            <h2>{config.labels.signInInformation}</h2>
                            <FormListItem
                                label={config.labels.email}
                                required
                                labelFor="email"
                                error={formik.touched.email && formik.errors.email}
                            >
                                <ControlInput
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    hasError={!!(formik.touched.email && formik.errors.email)}
                                />
                            </FormListItem>
                            <FormListItem
                                label={config.labels.password}
                                required
                                labelFor="password"
                                error={formik.touched.password && formik.errors.password}
                            >
                                <ControlInput
                                    type="text"
                                    name="password"
                                    id="password"
                                    secureTextEntry={true}
                                    data-password-min-character-sets={config.validationRules.minPasswordCharacterSets}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    hasError={!!(formik.touched.password && formik.errors.password)}
                                />
                            </FormListItem>
                            <FormListItem
                                label={config.labels.confirmPassword}
                                required
                                labelFor="password_confirmation"
                                error={formik.touched.password_confirmation && formik.errors.password_confirmation}
                            >
                                <ControlInput
                                    type="text"
                                    name="password_confirmation"
                                    id="password_confirmation"
                                    secureTextEntry={true}
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    hasError={
                                        !!(formik.touched.password_confirmation && formik.errors.password_confirmation)
                                    }
                                />
                            </FormListItem>
                            {config.enabledFeatures.newsletterSubscription && (
                                <FormListItem>
                                    <CheckboxWithLabel
                                        label={config.labels.newsSignUp}
                                        id="is_subscribed"
                                        name="is_subscribed"
                                        checked={formik.values.is_subscribed}
                                        onChange={formik.handleChange}
                                    />
                                </FormListItem>
                            )}

                            {config.enabledFeatures.customerTerms && (
                                <FormListItem
                                    error={
                                        formik.touched.is_customer_terms_checked &&
                                        formik.errors.is_customer_terms_checked
                                    }
                                >
                                    <CheckboxWithLabel
                                        label={config.labels.agreeToTerms}
                                        id="is_customer_terms_checked"
                                        name="is_customer_terms_checked"
                                        checked={formik.values.is_customer_terms_checked}
                                        onChange={formik.handleChange}
                                        hasError={
                                            !!(
                                                formik.touched.is_customer_terms_checked &&
                                                formik.errors.is_customer_terms_checked
                                            )
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        name="is_customer_terms_checked"
                                        value={formik.values.is_customer_terms_checked ? 1 : 0}
                                    />
                                </FormListItem>
                            )}
                        </FormList>

                        <Buttons>
                            <Button type="submit" intent="primary" title={config.labels.createAccount} />
                            <Button type="anchor" href={config.urls.backUrl} title={config.labels.back} />
                        </Buttons>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;
