import React from 'react';
import MultiCarousel from 'vkid-ui/lib/Components/MultiCarousel';
import { Product } from 'components/Catalog/Interfaces/List/IListResponse';
import BoxProduct from 'components/Catalog/Product/ListView/BoxProduct';

interface IProps {
    relatedProducts: Product[];
}

const RelatedProductSlider = (props: IProps) => {
    const { relatedProducts } = props;
    return (
        <div className="products-carousel">
            <div className="carousel carousel-inline has-arrows size-auto type-multiple">
                <MultiCarousel renderArrowsOutside showArrows type="multiple">
                    {relatedProducts.map((product: Product, index) => (
                        <div className="layout-products__container" key={index}>
                            <BoxProduct product={product} />
                        </div>
                    ))}
                </MultiCarousel>
            </div>
        </div>
    );
};

export default RelatedProductSlider;
