import { request } from 'data/requests/request';

const paymentStatusRequest = (url, orderReference, paymentReference) =>
    request({
        type: 'everyPayPaymentStatus',
        url,
        data: {
            order_reference: orderReference,
            payment_reference: paymentReference,
        },
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default paymentStatusRequest;
