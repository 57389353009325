import * as React from 'react';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { empty } from '../../helpers/empty';

interface IProps {
    type: string;
    text: string;
    key: string | number;
}

const Message = (props: IProps) => {
    const { type, text, key } = props;
    const intent = `cart-callout intent-${type}`;

    // parse html entities &lt; etc
    const txt: Document = new DOMParser().parseFromString(text, 'text/html');

    return (
        <React.Fragment>
            {!empty(text) && (
                <div key={key} className={intent}>
                    <RenderHTML html={txt.documentElement.textContent} nowrapper={true} />
                </div>
            )}
        </React.Fragment>
    );
};

export default Message;
