import * as React from 'react';
import { CSSProperties } from 'react';
import { empty } from '../../../../helpers/empty';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { isArray } from 'lodash';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import Button from 'vkid-ui/lib/Components/Button';

interface IProps {
    logoUrl?: string;
    method: string;
    alt: string;
    onClick: (event) => void;
    style?: CSSProperties;
    disabled?: boolean;
    disableDefaultLogo?: boolean;
    paymentMethodProps: IPaymentMethodProps;
}

const SimplePaymentButton = (props: IProps) => {
    const { method } = props;
    let logoUrl = props.logoUrl;
    const excludedForShippingMethods = window?.paymentLogos?.payment[method]?.excludedForShipping;

    if (!props.logoUrl && window.paymentLogos && window.paymentLogos.payment && window.paymentLogos.payment[method]) {
        logoUrl = window.paymentLogos.payment[method].logo;
    }

    let excludedMessage = '';
    if (excludedForShippingMethods && isArray(excludedForShippingMethods)) {
        const excludedShippingMatch = excludedForShippingMethods.find(
            (excludedForShipping) =>
                excludedForShipping?.shippingMethodCode === props.paymentMethodProps?.shippingMethod?.methodCode,
        );
        excludedMessage = excludedShippingMatch?.message;
    }

    const showMessage = () => {
        Toaster.addToast({
            intent: 'danger',
            text: excludedMessage,
        });
    };

    return (
        <Button
            size="xsmall"
            title={<img height="30" src={logoUrl} alt={props.alt} />}
            onClick={!empty(excludedMessage) ? showMessage : props.onClick}
            disabled={props.disabled}
        />
    );
};

export default SimplePaymentButton;
