import * as React from 'react';
import { memo, useEffect, useState } from 'react';

import 'vkid-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import FormRow from 'vkid-ui/lib/Components/FormRow/index';
import CheckboxWithLabel from 'vkid-ui/lib/Components/CheckboxWithLabel/index';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm/index';
import { useTranslation } from 'react-i18next';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';

interface ISubscriptionProps {
    customer: ICustomer | null;
    email: string | undefined;
}

const Subscription = (props: ISubscriptionProps) => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);
    const [email, setEmail] = useState(props.customer?.email ? props.customer?.email : props.email);

    const changeSubscription = () => {
        setIsChecked(!isChecked);
    };

    const [{}] = useRequest(
        request({
            notApi: true,
            method: 'POST',
            type: `newsletterCheck${email}`,
            url: 'newsletter/newsletter/check',
            data: {
                email,
            },
        }),
    );
    const { subscriptionResponse } = useSelector((state) => {
        return {
            subscriptionResponse: (state as any).entities[`newsletterCheck${email}`],
        };
    });

    const [{}, subscribeRequest] = useMutation(() =>
        request({
            notApi: true,
            method: 'POST',
            type: 'newsletterSubscribe',
            url: 'newsletter/newsletter/subscribe',
            data: {
                email,
            },
        }),
    );

    useEffect(() => {
        if (
            subscriptionResponse &&
            typeof subscriptionResponse === 'object' &&
            subscriptionResponse.hasOwnProperty('isSubscribed')
        ) {
            setShowSubscription(!subscriptionResponse.isSubscribed);
        }
    }, [subscriptionResponse]);

    const saveSubscription = () => {
        if (!showSubscription || !email || !isChecked) {
            return;
        }
        subscribeRequest();
    };

    useEffect(() => {
        window.addEventListener('subscription-save', saveSubscription);
        return function cleanup() {
            window.removeEventListener('subscription-save', saveSubscription);
        };
    });

    return showSubscription ? (
        <LayoutForm layout="vertical">
            <FormRow>
                <CheckboxWithLabel
                    label={t('checkout.subscribeToNewsletter')}
                    checked={isChecked}
                    onChange={() => changeSubscription()}
                />
            </FormRow>
        </LayoutForm>
    ) : (
        <React.Fragment />
    );
};
export default memo(Subscription);
