import * as React from 'react';
import { empty } from '../empty';

const filtrateCompanyCode = (input: string, countryId?: string) => {
    let regex = new RegExp('', 'g');

    if (
        countryId &&
        window.companyCodeValidationRules &&
        window.companyCodeValidationRules[countryId] &&
        !empty(window.companyCodeValidationRules[countryId])
    ) {
        regex = new RegExp(window.companyCodeValidationRules[countryId], 'g');
    }

    return input.replace(regex, '');
};

export default filtrateCompanyCode;
