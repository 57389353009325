import * as React from 'react';

import 'vkid-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import Buttons from 'vkid-ui/lib/Components/Buttons';

interface IBanklinksProps {
    components: React.Component[];
}

const WideButtons = (props: IBanklinksProps) => {
    if (props.components.length === 0) {
        return <></>;
    }
    return <Buttons layout="vertical-wide">{props.components}</Buttons>;
};

export default WideButtons;
