import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { PaymentMethodEnum } from '../../../../components/checkout/Payment/Methods/MethodHandler';
import { useEffect } from 'react';
import SimplePaymentButton from 'components/Checkout/components/checkout/Payment/Methods/SimplePaymentButton';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    single: boolean;
}

const BanklinkComponent = (props: IProps) => {
    const { method } = props;

    const [{}, postData] = useMutation(() =>
        request({ type: 'esto', url: `esto/redirect/getPurchaseUrl`, notApi: true }),
    );

    const effect = (event) => {
        const methods = [
            PaymentMethodEnum.esto_hirepurchase,
            PaymentMethodEnum.esto_pay_later,
            PaymentMethodEnum.esto_x,
        ];
        if (event.detail?.setRedirectUrl && methods.includes(event.detail.methodCode)) {
            postData().then((response) => {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
                if (response.body.url) {
                    event.detail.setRedirectUrl({ to: response.body.url, absolute: true });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    return (
        <>
            {props.single ? (
                <li className="primary">
                    <SimplePaymentButton
                        method={method.code}
                        key={method.code}
                        disableDefaultLogo
                        alt={method.title}
                        onClick={() => {
                            props.paymentMethodProps.onClick({ redirect: undefined });
                        }}
                        paymentMethodProps={props.paymentMethodProps}
                    />
                </li>
            ) : (
                <SimplePayment
                    method={method.code}
                    key={method.code}
                    onClick={() => {
                        props.paymentMethodProps.onClick({ redirect: undefined });
                    }}
                    label={method.title}
                    paymentMethodProps={props.paymentMethodProps}
                />
            )}
        </>
    );
};

export default BanklinkComponent;
