import { postRequest } from 'data/requests/postRequest';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { IProductRequest } from 'data/requests/product/data/formProductRequest';

const toCartRequest = (data: IProductRequest, quoteIdMask: string, cartFlowType?: string) =>
    postRequest({
        method: 'POST',
        type: 'response',
        url: isLoggedIn() ? `carts/mine/items` : `guest-carts/${quoteIdMask}/items`,
        data: Object.assign({}, data, { cartFlowType }),
    });

export default toCartRequest;
