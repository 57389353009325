import React from 'react';
import RelatedProductSlider from 'components/Catalog/Product/DetailComponent/RelatedProducts/RelatedProductSlider';
import { Product } from 'components/Catalog/Interfaces/List/IListResponse';

interface IProps {
    config: {
        likelyToAttachProducts: Product[];
        crossSellProducts: Product[];
        labels: {
            crossSellProductsLabel: string;
            likelyToAttachProductsLabel: string;
        };
    };
}

const RelatedProducts = (props: IProps) => {
    const { config } = props;
    const { likelyToAttachProducts, crossSellProducts, labels } = config;

    if (likelyToAttachProducts.length === 0 && crossSellProducts.length === 0) {
        return null;
    }

    return (
        <>
            {likelyToAttachProducts.length > 0 && (
                <>
                    <h2 className="center">{labels.likelyToAttachProductsLabel}</h2>
                    <RelatedProductSlider relatedProducts={likelyToAttachProducts} />
                </>
            )}
            {crossSellProducts.length > 0 && (
                <>
                    <h2 className="center">{labels.crossSellProductsLabel}</h2>
                    <RelatedProductSlider relatedProducts={crossSellProducts} />
                </>
            )}
        </>
    );
};

export default RelatedProducts;
