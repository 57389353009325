import { ISelectedAttributes } from 'components/Catalog/Product/Configurator';

export interface IProductRequest {
    cartItem: {
        sku: string;
        qty: number;
        quote_id: string;
        product_type: string;
        meta?: string;
        product_option: {
            extension_attributes: {
                configurable_item_options: {
                    option_id: string;
                    option_value: string;
                }[];
            };
        };
    };
}

const formProductRequest = (
    quoteId: string,
    sku: string,
    productType: string,
    selectedAttributes?: ISelectedAttributes[],
    meta?: string,
): IProductRequest => {
    let selectedAttributeFields: { option_id: string; option_value: string }[] = [];
    if (selectedAttributes) {
        selectedAttributeFields = selectedAttributes.map((item) => ({
            option_id: item.attributeId,
            option_value: item.optionId,
        }));
    }
    return {
        cartItem: {
            sku: sku,
            qty: 1,
            quote_id: quoteId,
            product_type: productType,
            meta,
            product_option: {
                extension_attributes: {
                    configurable_item_options: selectedAttributeFields,
                },
            },
        },
    };
};

export default formProductRequest;
