import * as React from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'vkid-ui/lib/Components/Toaster';
import newsletterRequest from 'data/requests/newsletter/newsletterRequest';

export interface INewsletterProps {
    config: {
        websiteCode: string;
        inputLabel: string;
        buttonLabel: string;
    };
}

const Newsletter: React.FunctionComponent<INewsletterProps> = (props) => {
    const [{}, subscribeRequest] = useMutation((email: string) => newsletterRequest(email));

    const [email, setEmail] = useState<string>();

    const handleSubscribe = useCallback(async (email) => {
        const response = await subscribeRequest(email);
        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            setEmail('');
        }
    }, []);

    return (
        <React.Fragment>
            <form className={`frame-${props.config.websiteCode}__footer__newsletter`}>
                <div className="entry">
                    <label htmlFor="newsletter-email">{props.config.inputLabel}:</label>
                    <div className="field">
                        <input value={email} onChange={(event) => setEmail(event.target.value)} id="newsletter-email" />
                        <button onClick={() => handleSubscribe(email)} type="button">
                            {props.config.buttonLabel}
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

Newsletter.displayName = 'Newsletter';

export default Newsletter;
