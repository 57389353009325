import { default as React, useCallback, useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useMutation } from 'redux-query-react';
import authRequest from 'data/requests/auth/socialAuthRequest';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import OverlayConfirm from 'components/overlay/OverlayConfirm';

interface IProps<T extends string> {
    config: {
        buttonLabel: string;
        clientId: string;
        accountIdentifier: string;
        url: string;
        userIdentifier: string;
        isLogin: boolean;
        overlayTitle: string;
        overlayDescription: string;
        overlayButtonCancel: string;
        overlayButtonConfirm: string;
    };
}

const GoogleAuth = <T extends string>(props: IProps<T>): JSX.Element => {
    const { config } = props;
    const [{}, request] = useMutation((data: Object) => authRequest(data, config.url));
    const [showConfirm, setShowConfirm] = useState(false);

    const responseGoogle = (response) => {
        const formData = new FormData();

        if (!config.userIdentifier) {
            // login needs code
            if (response.code) {
                formData.append('accessToken', response.code);
            }
            // attach needs userId
            if (response.googleId) {
                formData.append('userId', response.googleId);
            }
        } else {
            formData.append('userId', config.userIdentifier);
        }
        formData.append('account', config.accountIdentifier);

        request(formData).then((magentoResponse) => {
            Toaster.addToast({
                intent: magentoResponse.status === 200 ? 'success' : 'danger',
                text: magentoResponse.body.message,
                asHtml: true,
            });

            if (magentoResponse.status === 200) {
                // magento side session isset so reload in order for magento to decide what to do with logged user
                window.location.reload();
            }
        });
    };

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    const handleDeleteClick = useCallback(async () => {
        setShowConfirm(true);
    }, []);

    return (
        <React.Fragment>
            {!config.userIdentifier ? (
                <GoogleLogin
                    clientId={config.clientId}
                    buttonText={config.buttonLabel}
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="form-buttons__social google"
                        >
                            {config.buttonLabel}
                        </button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    accessType="offline"
                    responseType={config.isLogin ? 'code' : undefined}
                />
            ) : !showConfirm ? (
                <button onClick={() => handleDeleteClick()} className="form-buttons__social google">
                    {config.buttonLabel}
                </button>
            ) : (
                <OverlayConfirm
                    title={props.config.overlayTitle}
                    description={props.config.overlayDescription}
                    buttonCancel={props.config.overlayButtonCancel}
                    buttonOk={props.config.overlayButtonConfirm}
                    onAccept={() => responseGoogle(null)}
                    onClose={handleClose}
                />
            )}
        </React.Fragment>
    );
};

export default GoogleAuth;
