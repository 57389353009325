import * as React from 'react';
import { empty } from '../../../../../helpers/empty';
import { CartType } from 'components/Cart/Enum/CartType';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import ControlSelect from 'vkid-ui/lib/Components/ControlSelect/index';
import FormRow from 'vkid-ui/lib/Components/FormRow';
import InsuranceSwitcher from 'components/InbankRental/InsuranceSwitcher';
import { memo } from 'react';

interface IProps {
    isInterest: boolean;
    inbankRentalEnabled?: boolean;
    cartFlowType: string;
    setCartFlowType: (value: string) => void;
    fee?: {
        label: string;
        value: string;
    };
    disableInbankRentalOption?: boolean;
    inbankRentalLabels?: string[];
    aboutInbankRental?: string;
    productDetailViewLogo?: string;
    chooseRenewalPeriodLabel?: string;
    extraInfo?: string;
    useInsuranceLabel?: string;
    inbankRentalTermsUrl?: string;
    renewalPeriods?: IRenewalPeriod[];
    selectedRenewalPeriod?: string;
    setRenewalPeriod: (value: string) => void;
    isInbankRentalProduct: boolean;
}

export interface IRenewalPeriod {
    value: string;
    label: string;
}

const InbankRentalOption = (props: IProps) => {
    const {
        inbankRentalEnabled,
        isInterest,
        cartFlowType,
        setCartFlowType,
        fee,
        disableInbankRentalOption,
        inbankRentalLabels,
        aboutInbankRental,
        productDetailViewLogo,
        chooseRenewalPeriodLabel,
        inbankRentalTermsUrl,
        renewalPeriods,
        selectedRenewalPeriod,
        setRenewalPeriod,
        extraInfo,
        useInsuranceLabel,
        isInbankRentalProduct,
    } = props;

    return (
        <>
            {!empty(inbankRentalEnabled) && (
                <li className="primary">
                    {!isInterest && (
                        <input
                            checked={cartFlowType === CartType.INBANK_RENTAL_CART}
                            onChange={() => setCartFlowType(CartType.INBANK_RENTAL_CART)}
                            type="radio"
                            name="r01"
                            id="r01_2"
                            disabled={!fee || disableInbankRentalOption}
                        />
                    )}
                    <div className="product-pricing__option">
                        {fee && (
                            <React.Fragment>
                                {productDetailViewLogo && (
                                    <p className="product-pricing__logo">
                                        <img src={productDetailViewLogo} alt="InbankRental" height="70" />
                                    </p>
                                )}
                                <label htmlFor="r01_2">
                                    <span className="label">{fee.label}</span>
                                    <span className="price">{fee.value}</span>
                                </label>
                            </React.Fragment>
                        )}
                        {renewalPeriods && renewalPeriods.length > 1 && (
                            <div className="mt2em">
                                <FormRow label={chooseRenewalPeriodLabel}>
                                    <ControlSelect
                                        value={selectedRenewalPeriod ?? ''}
                                        onChange={(e) => {
                                            setRenewalPeriod(e.target.value);
                                        }}
                                    >
                                        {renewalPeriods.map((period, i) => (
                                            <option key={period.value + i} value={period.value}>
                                                {period.label}
                                            </option>
                                        ))}
                                    </ControlSelect>
                                </FormRow>
                            </div>
                        )}
                        {fee && useInsuranceLabel && isInbankRentalProduct && (
                            <InsuranceSwitcher
                                config={{
                                    extraInfo: extraInfo,
                                    useInsuranceLabel: useInsuranceLabel,
                                }}
                            />
                        )}
                        {inbankRentalLabels && inbankRentalLabels.length > 1 && (
                            <ul>
                                {inbankRentalLabels.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                        )}
                        {inbankRentalLabels && inbankRentalLabels.length <= 1 && (
                            <React.Fragment>
                                {inbankRentalLabels.map((item) => (
                                    <React.Fragment>{item}</React.Fragment>
                                ))}
                            </React.Fragment>
                        )}

                        {inbankRentalTermsUrl && aboutInbankRental && (
                            <p>
                                {<RenderHTML html={aboutInbankRental} nowrapper={true} />}
                                <br />
                                {<RenderHTML html={inbankRentalTermsUrl} nowrapper={true} />}
                            </p>
                        )}
                    </div>
                </li>
            )}
        </>
    );
};

export default memo(InbankRentalOption);
