import { request } from 'data/requests/request';
import { ICookieSelection } from 'components/Cookie';

const saveCookieRequest = (cookies: ICookieSelection[]) =>
    request({
        type: 'response',
        url: 'rpdg/cookieconsent/save',
        data: cookies,
        method: 'POST',
        notApi: true,
    });

export default saveCookieRequest;
