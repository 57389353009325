import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { includes } from 'lodash';
import { useRequest } from 'redux-query-react';
import listRequest from 'data/requests/product/listRequest';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryListItem, IListResponse } from 'components/Catalog/Interfaces/List/IListResponse';
import CategoryTree from 'vkid-ui/lib/Components/CategoryTree';
import ReactDOMServer from 'react-dom/server';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import Filters from 'components/Catalog/Product/ListView/Filters';
import CategoryFilter from 'vkid-ui/lib/Components/CategoryFilter';
import Toolbar from 'components/Catalog/Product/ListView/Toolbar';
import Products from 'components/Catalog/Product/ListView/Products';
import { parse } from 'query-string';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import Paginator from 'components/Catalog/Product/ListView/Paginator';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import useScrollRestoration from '../../../hooks/useScrollRestoration';
import FilterTags from 'components/Filter/FilterTags';

interface IProps {
    dom: string | object[];
}

const mapStateToProps = (state): IListResponse => {
    return {
        filters: state.entities.productList?.filters,
        products: state.entities.productList?.products,
        categories: state.entities.productList?.categories,
        pagination: state.entities.productList?.pagination,
        orderField: state.entities.productList?.orderField,
        orders: state.entities.productList?.orders,
        assets: state.entities.productList?.assets,
        activeFilters: state.entities.productList?.activeFilters,
        afterProductListHtml: state.entities.productList?.afterProductListHtml,
    };
};
const ListView = (props: IProps) => {
    const location = useLocation();

    const isSearchPage = location.pathname.startsWith('/catalogsearch');

    const getSlug = (newLocation) =>
        newLocation.pathname.startsWith('/catalogsearch') ? null : newLocation.pathname.replace('/', '');
    let slug = getSlug(location);
    const initialQuery = { ...parse(location.search), slug };
    const [queryData, setQueryData] = useState(initialQuery);
    const [useDom, setUseDom] = useState(true);
    const currentQuery = useRef(initialQuery);
    const [{ isFinished }] = useRequest(listRequest(queryData));
    let {
        filters,
        products,
        pagination,
        orders,
        assets,
        activeFilters,
        categories,
        orderField,
        afterProductListHtml,
    } = useSelector(mapStateToProps);
    const history = useHistory();

    /** Overlays **/
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    /** CurrentPage **/
    const query = parse(location.search);
    const initialPage = parseInt((query.p as string) || pagination?.page.toString() || '1');
    const currentPage = useRef(initialPage);
    const [pageNumber, setPageNumber] = useState(initialPage);
    const [pageBreadCrumbs, setPageBreadCrumbs] = useState<CategoryListItem[]>([]);
    /** Doms **/
    const { dom } = props;
    const parser = new DOMParser();
    const doc = parser.parseFromString(dom.toString(), 'text/html');
    const toolbarDom = doc.querySelectorAll('[data-component="toolbar"]')[0]?.innerHTML || '';
    const filtersDom = doc.querySelectorAll('[data-component="listFilters"]')[0]?.innerHTML || '';
    const productsDom = doc.querySelectorAll('[data-component="productList"]')[0]?.innerHTML || '';

    useScrollRestoration(isFinished);

    let pageTitle = '';
    const handleCategoriesActive = (categories: CategoryListItem[]) => {
        categories.forEach((item: CategoryListItem) => {
            if (item.active) {
                pageBreadCrumbs.push(item);
                pageTitle = item.name;
                if (item.children && item.children.length) {
                    handleCategoriesActive(item.children);
                }
            }
        });
    };

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            slug = getSlug(newLocation);
            const searchQuery = parse(newLocation.search);
            if (searchQuery['p'] && currentQuery.current && currentQuery.current['p'] === searchQuery['p']) {
                // this breaks safari from loading the right previous page
                /*delete searchQuery['p'];
                currentQuery.current = { ...searchQuery, slug };
                history.push(location.pathname + '?' + stringify(searchQuery));*/
            }
            currentQuery.current = { ...searchQuery, slug };
            setQueryData({ ...searchQuery, slug });
            const newPageNumber = parseInt((searchQuery['p'] ?? '1') as string);
            if (currentPage.current !== newPageNumber) {
                setPageNumber(newPageNumber);
                currentPage.current = newPageNumber;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        });
        return function cleanup() {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        if (isFinished && useDom) {
            setUseDom(false);
        }
    }, [isFinished]);

    useEffect(() => {
        if (isFinished && categories.items) {
            setPageBreadCrumbs([]);
            handleCategoriesActive(categories.items);
            window.dispatchEvent(new CustomEvent('page-changed', { detail: { pageTitle, pageBreadCrumbs } }));
        }
    }, [isFinished, categories]);

    return (
        <React.Fragment>
            <div className={'layout-browse__sidebar'}>
                <CategoryTree
                    labelSubmit={assets?.labelSubmit}
                    dom={
                        useDom
                            ? filtersDom
                            : ReactDOMServer.renderToString(
                                  <Filters
                                      filters={filters}
                                      assets={assets}
                                      activeFilters={activeFilters}
                                      categories={categories}
                                  />,
                              )
                    }
                    openMobileFilter={(e) => {
                        e.preventDefault();
                        dispatch(closeOverlay({ name: 'all' }));
                        dispatch(openOverlay({ name: 'mobilefilter' }));
                    }}
                    closeMobileFilter={(e) => {
                        e.preventDefault();
                        dispatch(closeOverlay({ name: 'all' }));
                    }}
                    mobileFilterOpen={includes(openOverlays, 'mobilefilter')}
                    link={NavLink}
                    query={location.search}
                    location={location}
                    history={history}
                />
            </div>
            <div className={'layout-browse__main'}>
                <div className="product-toolbar">
                    <CategoryFilter
                        dom={
                            useDom
                                ? toolbarDom
                                : ReactDOMServer.renderToString(
                                      <Toolbar orderField={orderField} orders={orders} assets={assets} />,
                                  )
                        }
                        link={NavLink}
                        query={location.search}
                        navigate={(query) => {
                            history.push(query);
                        }}
                    />
                </div>
                {!isSearchPage && (
                    <FilterTags
                        queryData={queryData}
                        resetFilterLabel={assets?.resetFilterLabel}
                        activeFilters={activeFilters}
                    />
                )}
                {useDom && <RenderHTML html={productsDom} nowrapper={true} />}
                {!!products && <Products products={products} />}
                {pagination && (
                    <Paginator
                        pagination={pagination}
                        assets={assets}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                )}
                {!useDom && products && products.length < 1 && (
                    <div className="generic-message">
                        <div className="generic-message__limiter">
                            <div className="generic-message__title">{assets.noProductsTitleLabel}</div>
                            <div className="generic-message__description">{assets.noProductsMessageLabel}</div>
                        </div>
                    </div>
                )}
                {afterProductListHtml && <RenderHTML html={afterProductListHtml} nowrapper={true} />}
            </div>
        </React.Fragment>
    );
};

export default ListView;
