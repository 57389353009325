import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { PaymentMethodEnum } from '../../../../components/checkout/Payment/Methods/MethodHandler';
import { useEffect, useRef, useState } from 'react';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
}

const LhvHp = (props: IProps) => {
    const { method } = props;
    const [formData, setFormData] = useState<any>();
    if (!window.paymentLogos || !window.paymentLogos.payment || !window.paymentLogos.payment[method.code]?.url) {
        return <React.Fragment />;
    }
    const to = window.paymentLogos.payment[method.code].url;
    const [{}, postData] = useMutation(() => request({ type: 'lhvhp', url: `lhvhp/request/fields`, notApi: true }));
    const submitButton = useRef(null);
    const effect = (event) => {
        const methods = [PaymentMethodEnum.lhv_hp];
        if (event.detail?.setRedirectUrl && methods.includes(event.detail.methodCode)) {
            postData().then((response) => {
                const form: { key: string; value: string }[] = [];
                for (const [key, value] of Object.entries(response.body)) {
                    form.push({ key, value: value as string });
                }
                setFormData(form);
                (submitButton.current as any)?.submit();
            });
        }
    };
    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    return (
        <React.Fragment>
            {formData && (
                <form
                    action={to}
                    method="POST"
                    hidden={true}
                    encType="application/x-www-form-urlencoded"
                    ref={submitButton}
                >
                    {formData.map(({ key, value }) => (
                        <input key={key} value={value} name={key} type="hidden" />
                    ))}
                </form>
            )}
            <SimplePayment
                method={method.code}
                key={method.code}
                onClick={() => {
                    props.paymentMethodProps.onClick({ redirect: undefined });
                }}
                label={method.title}
                paymentMethodProps={props.paymentMethodProps}
            />
        </React.Fragment>
    );
};

export default LhvHp;
