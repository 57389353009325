import { request } from 'data/requests/request';

export interface IMonthlyFeeRequest {
    formattedMonthlyFee: string;
    productPrice: number;
    paymentData: IPaymentData[];
    extraData: string[];
}

export interface IPaymentData {
    smartdeal: IPaymentItem;
    regular: IPaymentItem;
    initialPayment: IPaymentItem;
}

interface IPaymentItem {
    label: string;
    value: string;
}

const getSmartdealB2BFee = (sku: string) => {
    return request({
        type: 'smartdealB2BFee',
        url: `smart_dealb2b/index?sku=${sku}`,
        method: 'GET',
        notApi: true,
        force: true,
    });
};

export default getSmartdealB2BFee;
