import * as React from 'react';
import { useEffect, useState } from 'react';
import { empty } from '../../helpers/empty';
import { useMutation } from 'redux-query-react';
import landingCalculatorRequest from 'components/InbankRentalLanding/requests/landingCalculatorRequest';
import { IValueLabel } from 'components/Financing/Interfaces/IFinance';
import Toaster from 'vkid-ui/lib/Components/Toaster';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import LoadingContent from 'vkid-ui/lib/Components/LoadingContent';

interface IProps {
    config: {
        selector: string;
    };
}

interface ICalculationSummary {
    devicePrice: IValueLabel;
    agreementPrice: IValueLabel;
    downPaymentAmount: IValueLabel;
    totalMonthlyPayment: IValueLabel;
    totalDailyPayment: IValueLabel;
    totalMonthlyPaymentWithInsurance?: IValueLabel;
    productName: string;
    productImage: string;
    productDetailLabel: string;
    productDetailUrl: string;
    renewalPeriod: number;
    allPeriod: number;
    monthLabel: string;
    agreementDescription: string;
}

const InbankRentalLandingCalculator = (props: IProps) => {
    const [productSku, setProductSku] = useState<string>();
    const [productName, setProductName] = useState<string>();
    const [imageUrl, setImageUrl] = useState<string | null>();
    const [calculationSummaryResponse, setCalculationSummaryResponse] = useState<ICalculationSummary>();
    const [{}, inbankRentalLandingQuery] = useMutation((sku: string) => landingCalculatorRequest(sku));

    useEffect(() => {
        if (!props.config.selector) {
            return;
        }

        const selectElement: HTMLSelectElement = document.getElementById(props.config.selector) as HTMLSelectElement;
        if (selectElement) {
            const currentValue = selectElement.options[selectElement.selectedIndex].value;
            const currentLabel = selectElement.options[selectElement.selectedIndex].label;
            const imageUrl = selectElement.options[selectElement.selectedIndex].getAttribute('data-image-url');

            setProductSku(currentValue);
            setProductName(currentLabel);
            setImageUrl(imageUrl);
            selectElement.onchange = (event: Event) => {
                const target = event.target as HTMLInputElement;

                if (target.value) {
                    const currentLabel = selectElement.options[selectElement.selectedIndex].label;
                    const imageUrl = selectElement.options[selectElement.selectedIndex].getAttribute('data-image-url');

                    setProductSku(target.value);
                    setProductName(currentLabel);
                    setImageUrl(imageUrl);
                }
            };
        }
    }, []);

    useEffect(() => {
        if (!empty(productSku)) {
            setCalculationSummaryResponse(undefined);
            inbankRentalLandingQuery(productSku).then((response) => {
                if (response.status !== 200) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.message,
                        asHtml: true,
                    });
                } else {
                    setCalculationSummaryResponse(response.body);
                }
            });
        }
    }, [productSku]);

    return (
        <>
            {calculationSummaryResponse ? (
                <div className="smartdeal-product-data">
                    <img
                        className="smartdeal-product-data__image"
                        src={imageUrl && !empty(imageUrl) ? imageUrl : calculationSummaryResponse.productImage}
                        alt={productName}
                    />
                    <div className="smartdeal-product-data__content">
                        <h5 className="smartdeal-product-data__title">{productName}</h5>
                        <RenderHTML html={calculationSummaryResponse.agreementDescription} nowrapper={true} />
                        <table>
                            <tbody>
                                <tr>
                                    <td>{calculationSummaryResponse.devicePrice.label}</td>
                                    <td>{calculationSummaryResponse.devicePrice.value}</td>
                                </tr>
                                <tr>
                                    <td>{calculationSummaryResponse.totalMonthlyPayment.label}</td>
                                    <td>{calculationSummaryResponse.totalMonthlyPayment.value}</td>
                                </tr>
                                {calculationSummaryResponse.totalMonthlyPaymentWithInsurance && (
                                    <tr>
                                        <td>{calculationSummaryResponse.totalMonthlyPaymentWithInsurance.label}</td>
                                        <td>{calculationSummaryResponse.totalMonthlyPaymentWithInsurance.value}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <a
                            href={calculationSummaryResponse.productDetailUrl}
                            className="smartdeal-button intent-primary"
                        >
                            {calculationSummaryResponse.productDetailLabel}
                        </a>
                    </div>
                </div>
            ) : (
                <LoadingContent layout={`flex`} />
            )}
        </>
    );
};

export default InbankRentalLandingCalculator;
