import * as React from 'react';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    additionalHtml: string;
}
const AdditionalProductHtml = (props: IProps) => {
    const { additionalHtml } = props;
    return (
        <React.Fragment>
            <RenderHTML nowrapper={true} html={additionalHtml} />
        </React.Fragment>
    );
};

export default AdditionalProductHtml;
