import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import {
    IFinanceModel,
    IFinanceProvider,
    IFinanceResponse,
    IValueLabel,
} from 'components/Financing/Interfaces/IFinance';
import { useMutation } from 'redux-query-react';
import calculateRequest from 'data/requests/product/financial/calculateRequest';
import { debounce } from 'lodash';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    config: IFinanceResponse;
    productId?: string;
}
interface IForm extends IFinanceModel {
    name: string;
    code: string;
    monthsList: IValueLabel[];
}

const FinanceCalculator = (props: IProps) => {
    const { config, productId } = props;
    const defaultProvider = config.providers.find((provider) => provider.isDefault);
    const [{}, calculationRequest] = useMutation((data) => calculateRequest(data));

    if (!defaultProvider || !config.defaultSum) {
        return <React.Fragment />;
    }
    const initialState = {
        name: defaultProvider.name,
        code: defaultProvider.code,
        downPaymentAllowed: defaultProvider.downPaymentAllowed,
        monthsList: defaultProvider.monthsList,
        ...config.defaultSum,
    };
    const [currentProvider, setCurrentProvider] = useState<IFinanceProvider | undefined>(defaultProvider);
    const [form, setForm] = useState<IForm>(initialState);
    const [sum, setSum] = useState(config.defaultSum.sum);
    const [isDisabled, setIsDisabled] = useState(false);
    const [warningMessage, setWarningMessage] = useState();
    const oldProductId = useRef(config.productId);

    const debouncedSave = useCallback(
        debounce(
            (values) =>
                calculationRequest(values).then((response) => {
                    setSum(response.body.sum);
                    setIsDisabled(response.body?.isDisabled ?? false);
                    setWarningMessage(response.body.failedMessage);
                    if (response.body?.downPayment) {
                        setForm({ ...form, downPayment: response.body?.downPayment });
                    }
                }),
            300,
        ),
        [],
    );

    useEffect(() => {
        if (productId && (form !== initialState || oldProductId.current !== productId)) {
            oldProductId.current = productId;
            debouncedSave({
                type: form.code,
                productId,
                period: form.months,
                downPayment: form.downPayment,
            });
        }
    }, [form, productId]);

    const validateDownPayment = (value) => {
        const filteredValue = value.replace(/[^0-9\.]/g, '');
        if (form.downPayment === filteredValue) {
            return;
        }
        setForm({
            ...form,
            downPayment: filteredValue,
        });
    };

    const effect = (e) => {
        if (!form || !e.detail?.defaultPeriod) {
            return;
        }

        const match = form.monthsList.find(
            (monthEntity: IValueLabel) => monthEntity.value.toString() === e.detail.defaultPeriod.toString(),
        );
        if (!match) {
            return;
        }

        setForm({
            ...form,
            months: match.value,
        });
    };

    useEffect(() => {
        window.addEventListener('financial-calculator-default-period-set', effect);
        return function cleanup() {
            window.removeEventListener('financial-calculator-default-period-set', effect);
        };
    }, []);

    return (
        <React.Fragment>
            {!isDisabled && (
                <div className="financing-calculator">
                    <div className="financing-calculator__form">
                        <div className="financing-calculator__form__row">
                            <label htmlFor="financing-provider">{config.assets.providerLabel}</label>
                            <div className="fields">
                                <select
                                    name="provider"
                                    id="financing-provider"
                                    value={form.code}
                                    onChange={(e) => {
                                        const selectedProvider = config.providers.find(
                                            (provider: IFinanceProvider) => provider.code === e.target.value,
                                        );
                                        if (!selectedProvider) {
                                            return;
                                        }
                                        setCurrentProvider(selectedProvider);

                                        const selectedMonth =
                                            selectedProvider.monthsList.find(
                                                (monthToCompare) =>
                                                    monthToCompare.value.toString() === form.months.toString(),
                                            ) || selectedProvider.monthsList[0];

                                        setForm({
                                            ...form,
                                            code: e.target.value,
                                            monthsList: selectedProvider.monthsList,
                                            months: selectedMonth.value,
                                        });
                                    }}
                                >
                                    {config.providers &&
                                        config.providers.map((provider) => {
                                            return (
                                                <option key={provider.code} value={provider.code}>
                                                    {provider.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="financing-calculator__form__row">
                            <label htmlFor="financing-downpayment">{config.assets.downPaymentLabel}</label>
                            <div className="fields">
                                <input
                                    type="text"
                                    name="downpayment"
                                    disabled={!currentProvider?.downPaymentAllowed}
                                    id="financing-downpayment"
                                    value={!currentProvider?.downPaymentAllowed ? 0 : form.downPayment}
                                    onChange={(e) => validateDownPayment(e.target.value)}
                                />
                                <span className="addon">{form.downPaymentSymbol}</span>
                            </div>
                        </div>
                        <div className="financing-calculator__form__row">
                            <label htmlFor="financing-period">{config.assets.periodLabel}</label>
                            <div className="fields">
                                <select
                                    name="provider"
                                    id="financing-period"
                                    value={form.months}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            months: e.target.value,
                                        });
                                    }}
                                >
                                    {!!form.monthsList &&
                                        form.monthsList.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="financing-calculator__result">
                        <div className="result-label">{config.assets.monthlyLabel}</div>
                        <div className="result-sum">{sum}</div>
                        <div className="result-disclaimer">
                            <RenderHTML nowrapper={true} html={config.assets.warningLabel} />
                        </div>
                        {warningMessage && <div className="result-disclaimer has-error">{warningMessage}</div>}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
export default FinanceCalculator;
