import * as React from 'react';
import { useCallback, useState } from 'react';
import { empty } from '../../helpers/empty';
import { useMutation } from 'redux-query-react';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import toCartRequest from 'data/requests/product/toCartRequest';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import { extractErrors } from '../../helpers/request/extractErrors';
import useAuth from '../../hooks/useAuth';
import checkoutInbankRentalRequest from 'data/requests/product/inbankRental/checkoutInbankRentalRequest';
import { ISliceConfiguration } from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import Slice3ValidationOverlay from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ValidationOverlay';
import { openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch } from 'react-redux';
import { CartType } from 'components/Cart/Enum/CartType';
import { request } from 'data/requests/request';
import { ResponseStatus } from '../../enums/ResponseStatus';

export interface ICrossSellProduct {
    sku: string;
    type: string;
    name: string;
    isOldPriceDifferent: boolean;
    image: string;
    preTitle?: string;
    price: string;
    oldPrice?: string;
    manufacturer?: string;
    inbankRentalMonthlyFee?: string;
    slice3Config?: ISliceConfiguration;
}

export interface ICrossSellProps {
    config: {
        isInbankRental: boolean;
        successAddMessage: string;
        addToCartLabel: string;
        crossSellProducts: ICrossSellProduct[];
    };
}

const CrossSell = (props: ICrossSellProps) => {
    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{}, addToCartRequest] = useMutation((data, quoteIdMask: string) => toCartRequest(data, quoteIdMask));
    const [{}, addProductRequest] = useMutation((data: IProductRequest) => checkoutInbankRentalRequest(data));

    const [addedToCart, setAddedToCart] = useState<string[]>([]);
    const [addToCartQueryData, setAddToCartQueryData] = useState<any>();
    const [cartFlow, setCartFlow] = useState<string>(
        window.sessionStorage.getItem('cartFlow') ?? CartType.REGULAR_CART,
    );

    const [{}, cartRequest] = useMutation(() =>
        request({ type: 'primitiveCart', url: 'fast/cart/info', notApi: true }),
    );
    const [cartTotal, setCartTotal] = useState<string>('0');

    const [productSlice3Config, setProductSlice3Config] = useState<ISliceConfiguration>();

    const { customer } = useAuth();
    const dispatch = useDispatch();

    const setAddedToCartAfterQuery = (product: ICrossSellProduct) => {
        const addedClone = [...addedToCart];
        addedClone.push(product.sku);
        setAddedToCart(addedClone);
        window.dispatchEvent(
            new CustomEvent('cart-altered', {
                detail: {
                    action: 'add-to-cart',
                    userId: customer?.id,
                    itemListName: 'Product cross sell',
                    sku: product?.sku,
                    name: product?.name,
                    priceValue: product?.price,
                    itemBrand: product?.manufacturer,
                },
            }),
        );

        if (cartFlow === CartType.SLICE3_CART) {
            cartRequest().then((response) => {
                if (response.status === ResponseStatus.ok) {
                    setCartTotal(response.body.rawGrandTotal);
                }
            });
        }
    };

    const addToCartInbankRental = useCallback(
        async (product: ICrossSellProduct, event) => {
            event.preventDefault();
            const productRequestData: IProductRequest = formProductRequest('', product.sku, product.type);
            const response = await addProductRequest(productRequestData);
            if (response.status !== 200) {
                Toaster.addToast({
                    intent: 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            } else {
                setAddedToCartAfterQuery(product);
            }
        },
        [quoteRequest, addProductRequest],
    );

    const queryAddToCart = async (productRequestData, quoteId, product) => {
        const response = await addToCartRequest(productRequestData, quoteId);
        if (response.status !== 200) {
            const errors = extractErrors(response);
            if (errors && errors.error) {
                Toaster.addToast({
                    intent: 'danger',
                    text: errors.error,
                    asHtml: true,
                });
            }
        } else {
            setAddedToCartAfterQuery(product);
        }
    };

    const isSliceValidationError = (product) => {
        const cartTotalExceeded =
            !empty(cartTotal) &&
            product.slice3Config.maxSum &&
            product.slice3Config.rawProductPrice &&
            parseFloat(cartTotal) + parseFloat(product.slice3Config.rawProductPrice) >
                parseFloat(product.slice3Config.maxSum);

        const cartTotalNotReached =
            !empty(cartTotal) &&
            product.slice3Config.minSum &&
            product.slice3Config.rawProductPrice &&
            parseFloat(cartTotal) + parseFloat(product.slice3Config.rawProductPrice) <
                parseFloat(product.slice3Config.minSum);

        return !empty(product.slice3Config.isSliceValidationError) || cartTotalExceeded || cartTotalNotReached;
    };

    const addToCart = useCallback(
        async (product: ICrossSellProduct, event) => {
            event.preventDefault();
            const quoteIdResponse = await quoteRequest();
            if (quoteIdResponse.status !== 200) {
                window.location.reload();
            }
            const { quoteId } = quoteIdResponse.body;

            const productRequestData: IProductRequest = formProductRequest(quoteId, product.sku, product.type);

            if (cartFlow === CartType.SLICE3_CART && product.slice3Config && isSliceValidationError(product)) {
                setProductSlice3Config(product.slice3Config);
                setAddToCartQueryData({
                    productRequestData,
                    quoteId,
                    product,
                });
                dispatch(openOverlay({ name: 'add-slice' }));
            } else {
                queryAddToCart(productRequestData, quoteId, product);
            }
        },
        [quoteRequest, addToCartRequest, cartTotal],
    );

    return (
        <React.Fragment>
            <div className="layout-products">
                <div className="layout-products__list">
                    {props.config.crossSellProducts.map((product: ICrossSellProduct) => {
                        return (
                            <div key={product.sku} className="layout-products__container">
                                <div className="box-product">
                                    <a
                                        href="#"
                                        onClick={(event) =>
                                            empty(props.config.isInbankRental)
                                                ? addToCart(product, event)
                                                : addToCartInbankRental(product, event)
                                        }
                                        className="box-product__link"
                                    >
                                        {product.name}
                                    </a>
                                    <div className="box-product__image">
                                        {product.isOldPriceDifferent && <div className="product-sale">%</div>}
                                        <span>
                                            <img src={product.image} alt={product.name} />
                                        </span>
                                    </div>
                                    <div className="box-product__content">
                                        <div className="box-product__text">
                                            <div className="box-product__pre-title">
                                                {empty(product.preTitle) ? '' : product.preTitle}
                                            </div>
                                            <div className="box-product__title">{product.name}</div>
                                            <div className="box-product__price">
                                                <strong>{product.price}</strong>
                                            </div>
                                            {product.isOldPriceDifferent && (
                                                <div className="box-product__price old">{product.oldPrice}</div>
                                            )}
                                            {product.inbankRentalMonthlyFee && (
                                                <div className="box-product__price">
                                                    <small>{product.inbankRentalMonthlyFee}</small>
                                                </div>
                                            )}
                                        </div>
                                        <p className="box-promo__actions">
                                            {!addedToCart.includes(product.sku) && (
                                                <a className="primary">{props.config.addToCartLabel}</a>
                                            )}
                                            {addedToCart.includes(product.sku) && (
                                                <div className={'cart-callout intent-success'}>
                                                    {props.config.successAddMessage}
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {addToCartQueryData && productSlice3Config && (
                    <Slice3ValidationOverlay
                        setCartFlowType={setCartFlow}
                        isInbankRentalAdd={false}
                        addToCart={() =>
                            queryAddToCart(
                                addToCartQueryData.productRequestData,
                                addToCartQueryData.quoteId,
                                addToCartQueryData.product,
                            )
                        }
                        slice3ModalLabels={productSlice3Config.modalLabels}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

CrossSell.displayName = 'CrossSell';

export default CrossSell;
