import React, { useState } from 'react';
import SmartdealB2BCalculatorPaymentData from 'components/SmartdealB2B/SmartdealB2BCalculatorPaymentData';

interface IProps {
    config: {
        selector: string;
        formInputId: string;
        agreementDescription: string;
        labels: IB2BCalculatorLabels;
    };
}

export interface IB2BCalculatorLabels {
    devicePriceExclVat: string;
    legalLabel: string;
    month: string;
}

const SmartdealB2BCalculator = (props: IProps) => {
    const { config } = props;
    const selectElement: HTMLSelectElement = document.getElementById(config.selector) as HTMLSelectElement;

    const [productName, setProductName] = useState(selectElement.options[selectElement.selectedIndex].label);
    const [sku, setSku] = useState(selectElement.options[selectElement.selectedIndex].value);
    const [imageUrl, setImageUrl] = useState(
        selectElement.options[selectElement.selectedIndex].getAttribute('image_url') ?? '',
    );

    if (!props.config.selector) {
        return <></>;
    }

    const setFormProduct = (productName: string): void => {
        const formItem = document.getElementsByName(`field[${config.formInputId}]`).item(0) as HTMLInputElement;
        formItem.value = productName;
    };

    setFormProduct(selectElement.options[selectElement.selectedIndex].label);

    selectElement.onchange = (event: Event) => {
        const target = event.target as HTMLInputElement;

        if (target.value) {
            setSku(target.value);
            setProductName(selectElement.options[selectElement.selectedIndex].label);
            setFormProduct(selectElement.options[selectElement.selectedIndex].label);
            setImageUrl(selectElement.options[selectElement.selectedIndex].getAttribute('image_url') ?? '');
        }
    };

    return (
        <div className="smartdeal-product-data">
            <SmartdealB2BCalculatorPaymentData
                agreementDescription={config.agreementDescription}
                sku={sku}
                imageUrl={imageUrl}
                productName={productName}
                labels={config.labels}
            />
        </div>
    );
};

export default SmartdealB2BCalculator;
