import * as React from 'react';
import { IProductConfig, ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import Block from 'vkid-ui/lib/Components/Block';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { useDispatch, useSelector } from 'react-redux';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { includes } from 'lodash';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { ICartTimeToRenewValue } from './CartButton';
import { ISelectedAttributes, ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { ISwatch } from 'components/Catalog/Interfaces/ISwatch';
import { IAttributeOptions } from 'components/Catalog/Interfaces/IAttribute';
import { useEffect, useState } from 'react';

interface IProps {
    notFittedInbankRentalProduct: ICartTimeToRenewValue;
    inbankRentalModalIntro?: string;
    productConfig?: IProductConfig;
    inbankRentalFee: {
        label: string;
        value: string;
    };
    inbankRentalModalFitLabels?: IInbankRentalFitModalLabels;
    addToCartInbankRental?: () => {};
    swatchConfig?: ISwatchConfig;
    selectedProductInformation?: ISelectedProductInformation;
}

export interface IInbankRentalFitModalLabels {
    inbankRentalModalIntro: string;
    inbankRentalModalConfirmLabel: string;
    inbankRentalModalAgreeButtonLabel: string;
    inbankRentalModalNotAgreeButtonLabel: string;
    inbankRentalModalToAddLabel: string;
    inbankRentalModalToRemoveLabel: string;
}

const InbankRentalCartButtonOverlay = (props: IProps) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    const [colorSwatch, setColorSwatch] = useState<ISwatch>();
    const [attributesOptions, setAttributeOptions] = useState<IAttributeOptions[]>([]);

    const getColor = (attributeId, optionId): ISwatch | undefined => {
        let result = undefined;
        if (!props.swatchConfig?.swatches) {
            return result;
        }
        Object.keys(props.swatchConfig?.swatches).forEach((foundAttributeId) => {
            if (foundAttributeId === attributeId) {
                result = props.swatchConfig?.swatches[foundAttributeId][optionId];
            }
        });
        return result;
    };

    const getAttribute = (attributeId, optionId): IAttributeOptions | undefined => {
        if (props.swatchConfig?.options?.attributes[attributeId]) {
            return props.swatchConfig?.options?.attributes[attributeId].options.find((option: IAttributeOptions) => {
                return option.id === optionId;
            });
        }
        return undefined;
    };

    useEffect(() => {
        if (props.selectedProductInformation) {
            let color: ISwatch | undefined;
            let attributes: IAttributeOptions[] = [];
            if (props.selectedProductInformation) {
                props.selectedProductInformation.selectedAttributes?.forEach((option: ISelectedAttributes) => {
                    let isColorAttribute;
                    if (!color) {
                        color = getColor(option.attributeId, option.optionId);
                        if (color) {
                            isColorAttribute = true;
                        }
                    }
                    const attribute = getAttribute(option.attributeId, option.optionId);
                    if (!isColorAttribute && attribute) {
                        attributes.push(attribute);
                    }
                });
            }
            setColorSwatch(color);
            setAttributeOptions(attributes);
        }
    }, [props.selectedProductInformation]);

    const attributeNames = attributesOptions.map((attribute: IAttributeOptions) => attribute.label).join(' ');

    const originProductName = `${props.productConfig?.name}${' ' + attributeNames}${
        colorSwatch ? ' ' + colorSwatch.label : ''
    }`;

    const introText = props.inbankRentalModalFitLabels?.inbankRentalModalIntro
        ?.replace('%1', originProductName)
        .replace('%2', props.notFittedInbankRentalProduct.name);

    const addToCart = () => {
        if (props.addToCartInbankRental) {
            props.addToCartInbankRental();
            dispatch(closeOverlay({ name: 'all' }));
        }
    };

    return (
        <React.Fragment>
            {
                <Overlay
                    layout="focusview"
                    title=" "
                    isOpen={includes(openOverlays, 'add-smart-deal')}
                    doClose={() => {
                        dispatch(closeOverlay({ name: 'all' }));
                    }}
                    zIndex={16000004} // cookie message + 1
                    size="wide"
                    buttonIcons={[
                        {
                            icon: 'close',
                            onClick: (e) => {
                                dispatch(closeOverlay({ name: 'all' }));
                            },
                        },
                    ]}
                >
                    <Block className="rental-add">
                        <p className="lead">{introText && <RenderHTML html={introText} nowrapper={true} />}</p>
                        <ul className="rental-products">
                            <li>
                                <p>{props.inbankRentalModalFitLabels?.inbankRentalModalToAddLabel}</p>
                                <div className="rental-products__item">
                                    <p className="title">{originProductName}</p>
                                    <p>
                                        {props.inbankRentalFee?.label} {props.inbankRentalFee?.value}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p>{props.inbankRentalModalFitLabels?.inbankRentalModalToRemoveLabel}</p>
                                <div className="rental-products__item">
                                    <p className="title">{props.notFittedInbankRentalProduct.name}</p>
                                    <p>
                                        {`${props.inbankRentalFee?.label} ${props.notFittedInbankRentalProduct.totalMonthlyPayment}`}
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <p>{props.inbankRentalModalFitLabels?.inbankRentalModalConfirmLabel}</p>
                        <ul className="rental-buttons">
                            <li>
                                <button onClick={() => addToCart()} className={`rental-button primary`}>
                                    {props.inbankRentalModalFitLabels?.inbankRentalModalAgreeButtonLabel}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => dispatch(closeOverlay({ name: 'all' }))}
                                    className={`rental-button`}
                                >
                                    {props.inbankRentalModalFitLabels?.inbankRentalModalNotAgreeButtonLabel}
                                </button>
                            </li>
                        </ul>
                    </Block>
                </Overlay>
            }
        </React.Fragment>
    );
};

export default InbankRentalCartButtonOverlay;
