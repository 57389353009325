import { request } from 'data/requests/request';

const paymentCheckRequest = (url, orderReference) =>
    request({
        type: 'everyPayPaymentCheck',
        url,
        data: {
            order_reference: orderReference,
        },
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default paymentCheckRequest;
