import * as React from 'react';
import { IImage } from 'components/Catalog/Interfaces/IImage';

interface IProps {
    images?: IImage[];
    productName?: string;
}

const Gallery = (props: IProps) => {
    const { images, productName } = props;
    return (
        <div className={'gallery has-overlay has-thumbs'}>
            {images &&
                images.map((image: IImage, key) => {
                    const srcSet = {
                        ['(min-width: 1080px)']: image.full,
                        ['(min-width: 575px) and (max-width: 1079px)']: image.img,
                        ['(max-width: 574px)']: image.thumb,
                    };
                    return (
                        <div
                            key={key}
                            data-full-srcset={JSON.stringify(srcSet)}
                            className={`gallery__slide ${image.isMain ? 'active' : ''}`}
                        >
                            <img src={image.thumb} alt={productName} />
                        </div>
                    );
                })}
        </div>
    );
};

export default Gallery;
