import * as React from 'react';
import { Helmet } from 'react-helmet';

import './styles.scss';
import { ContentLoading } from '../../components/loading/ContentLoading';

export interface IProps {
    title: string;
    isLoading?: boolean;
}

export interface IState {}

class FramePage extends React.Component<IProps, IState> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
                {this.props.isLoading ? <ContentLoading /> : this.props.children}
            </React.Fragment>
        );
    }
}

export default FramePage;
