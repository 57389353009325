import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import BoxProduct from 'components/Catalog/Product/ListView/BoxProduct';

interface IProps {
    products: Product[];
}

const Products = (props: IProps) => {
    const location = useLocation();
    const queryString = parse(location.search);
    const useListView = (queryString.mode && queryString.mode === 'list') || false;
    const { products } = props;
    return (
        <React.Fragment>
            <div className="layout-products">
                <div className={classNames('layout-products__list light', { list: useListView })}>
                    {products &&
                        products.map((product, index) => (
                            <div className="layout-products__container" key={index}>
                                <BoxProduct product={product} />
                            </div>
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Products;
