import * as React from 'react';
import { memo, useEffect } from 'react';
import { IAttribute, IAttributeOptions } from 'components/Catalog/Interfaces/IAttribute';
import Attribute from 'components/Catalog/Product/Attribute';
import { ISwatch } from 'components/Catalog/Interfaces/ISwatch';
import { IPrice } from 'components/Catalog/Interfaces/IPrice';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { IImage } from 'components/Catalog/Interfaces/IImage';
import { useHistory, useLocation } from 'react-router';
import { empty } from '../../../helpers/empty';

interface IProps {
    attributes: IAttribute[];
    secondaryAttributes: IAttribute[];
    selectedAttributes: ISelectedAttributes[];
    selectAttributes: (value) => void;
    config: ISwatchConfig;
    setSelectedProductInformation: (selectedProductInformation: ISelectedProductInformation) => void;
    defaultSelectedMainSwatch: ISelectedSwatch;
    setDefaultSelectedMainSwatch: (attributes: ISelectedSwatch) => void;
}

export interface ISwatchConfig {
    swatches: ISwatch[][];
    options: {
        attributes: IAttribute[];
        chooseText: string;
        currencyFormat: string;
        images: IImage[];
        index: any;
        optionPrices: IPrice;
        skus?: string[];
        names?: string[];
        priceFormat: any;
        prices: any;
        productId: any;
    };
}

export interface ISelectedAttributes {
    attributeId: string;
    optionId: string;
}

export interface ISelectedSwatch {
    attributeId: string;
    optionId: string;
    products: string;
}

interface ISelectedCodeOptionLabel {
    [code: string]: string;
}

const collectNoMatchAttributes = (secondaryAttributes: IAttribute[], selectedAttributes: ISelectedAttributes[]) => {
    const noMatchAttributes: IAttribute[] = [];
    // find if selected attributes and filtered out secondary attributes are presented
    secondaryAttributes.forEach((attribute: IAttribute) => {
        let hasMatch: boolean = false;
        attribute.options.forEach((option: IAttributeOptions) => {
            hasMatch =
                hasMatch ||
                selectedAttributes.some(
                    (selectedAttributeFilter: ISelectedAttributes) =>
                        selectedAttributeFilter.attributeId === attribute.id &&
                        option.id === selectedAttributeFilter.optionId,
                );
        });

        if (!hasMatch) {
            noMatchAttributes.push(attribute);
        }
    });

    return noMatchAttributes;
};

const modifySelectedAttributes = (noMatchAttributes: IAttribute[], selectedAttributes: ISelectedAttributes[]) => {
    noMatchAttributes.forEach((attribute: IAttribute) => {
        selectedAttributes.forEach((selectedAttributeFilter: ISelectedAttributes) => {
            if (selectedAttributeFilter.attributeId === attribute.id) {
                // overwrite the option value with first existing
                selectedAttributeFilter.optionId =
                    attribute.options.find((option: IAttributeOptions) => option)?.id ??
                    selectedAttributeFilter.optionId;
            }
        });
    });
};

const Configurator = (props: IProps) => {
    const {
        config,
        setSelectedProductInformation,
        selectedAttributes,
        selectAttributes,
        attributes,
        secondaryAttributes,
        defaultSelectedMainSwatch,
        setDefaultSelectedMainSwatch,
    } = props;
    const { options, swatches } = config;

    let selectedAttributeCodeOptionLabel: ISelectedCodeOptionLabel = {};

    const location = useLocation();
    const history = useHistory();

    // secondary attributes are filtered and set to include available only options after GET params are forwarded
    // find if there are not existing options selected, if yes change the selectedAttribute dynamically
    const noMatchAttributes: IAttribute[] = collectNoMatchAttributes(secondaryAttributes, selectedAttributes);

    if (noMatchAttributes.length > 0) {
        modifySelectedAttributes(noMatchAttributes, selectedAttributes);
        selectAttributes([...selectedAttributes]);
    }

    const ensureAvailableAttributesAreSelected = () => {
        selectedAttributeCodeOptionLabel = {};
        selectedAttributes.forEach((selectedAttribute: ISelectedAttributes, key: number) => {
            secondaryAttributes.forEach((attribute) => {
                if (attribute.id === selectedAttribute.attributeId) {
                    let hasOption = false;
                    Object.values(attribute.options).forEach((attributeOption) => {
                        if (hasOption) {
                            return;
                        }

                        if (attributeOption.id === selectedAttribute.optionId) {
                            selectedAttributeCodeOptionLabel[attribute.code] = attributeOption.id;
                            hasOption = true;
                        }
                    });
                    // assign first available option in case it is not available in this configuration
                    if (!hasOption && attribute.options.length > 0) {
                        selectedAttributes[key].optionId = attribute.options[0].id;
                        selectAttributes(selectedAttributes);
                        selectedAttributeCodeOptionLabel[attribute.code] = attribute.options[0].id;
                    }
                }
            });
        });
    };

    const getSelectedProducts = () => {
        let products: string[] | undefined;

        selectedAttributes.forEach((selectedAttribute: ISelectedAttributes) => {
            Object.values(options.attributes).forEach((attribute) => {
                if (attribute.id === selectedAttribute.attributeId) {
                    Object.values(attribute.options).forEach((attributeOption) => {
                        if (attributeOption.id === selectedAttribute.optionId) {
                            if (!products) {
                                products = attributeOption.products;
                            } else {
                                products = products.filter((value) => attributeOption.products.includes(value));
                            }
                        }
                    });
                }
            });
        });
        return products;
    };

    useEffect(() => {
        let products: string[] | undefined;

        if (options.attributes) {
            ensureAvailableAttributesAreSelected();
            products = getSelectedProducts();
        }

        if (products?.length) {
            const selectedProductId = products[0];
            const images = options.images[selectedProductId];
            let sku,
                name = undefined;
            if (options.skus) {
                sku = options.skus[selectedProductId];
            }
            const prices = options.optionPrices[selectedProductId];

            if (options.names) {
                name = options.names[selectedProductId];
            }

            setSelectedProductInformation({
                isAvailable: true,
                productId: selectedProductId,
                name: name,
                sku,
                images,
                prices,
                selectedAttributes,
            });
        } else {
            setSelectedProductInformation({ isAvailable: false });
        }
    }, [selectedAttributes]);

    const pushToHistory = () => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);

        Object.keys(selectedAttributeCodeOptionLabel).forEach((attributeCode: string) => {
            searchParams.set(attributeCode, selectedAttributeCodeOptionLabel[attributeCode]);
        });

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    useEffect(() => {
        if (!empty(selectedAttributeCodeOptionLabel)) {
            pushToHistory();
        }
    }, [selectedAttributes]);

    return (
        <React.Fragment>
            {attributes &&
                secondaryAttributes.map(
                    (secondarySwatch: IAttribute) =>
                        secondarySwatch.options.length > 1 && (
                            <Attribute
                                key={secondarySwatch.id}
                                swatches={swatches}
                                attribute={secondarySwatch}
                                selectedAttributes={selectedAttributes}
                                selectAttributes={selectAttributes}
                                setDefaultSelectedMainSwatch={setDefaultSelectedMainSwatch}
                                defaultSelectedMainSwatch={defaultSelectedMainSwatch}
                            />
                        ),
                )}
        </React.Fragment>
    );
};

export default memo(Configurator);
