import React from 'react';
import { Product } from 'components/Catalog/Interfaces/List/IListResponse';

interface IProps {
    product: Product;
}

const BoxProduct = (props: IProps) => {
    const { product } = props;

    return (
        <div className="box-product">
            <a href={product.url} className="box-product__link">
                {product.name}
            </a>
            <div className="box-product__image">
                {product.priceOld && <div className="product-sale">%</div>}
                <span>
                    <img src={product.image.image_url} alt={product.name} />
                </span>
            </div>
            <div className="box-product__content">
                <div className="box-product__text">
                    {product.preTitle && product.preTitle !== '-1' && (
                        <div className="box-product__pre-title">{product.preTitle}</div>
                    )}
                    <div className="box-product__title">{product.name}</div>
                    {product.description && <div className="box-product__description">{product.description}</div>}
                    {product.configurablePriceFromLabel && (
                        <div className="box-product__price from">{product.configurablePriceFromLabel}</div>
                    )}
                    <div className="box-product__price">{product.price}</div>
                    {product.priceOld && <div className="box-product__price old">{product.priceOld}</div>}
                </div>
                {product.swatches && (
                    <ul className="box-product__swatches">
                        {Object.values(product.swatches).map((swatches) => {
                            return Object.values(swatches).map((swatch, swatchIndex) => {
                                if (swatch.value) {
                                    return (
                                        <li key={swatchIndex}>
                                            <span style={{ background: swatch.value }} />
                                        </li>
                                    );
                                }
                                return <React.Fragment key={swatchIndex} />;
                            });
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default BoxProduct;
