import { request } from 'data/requests/request';

const authRequest = (data: Object, url) =>
    request({
        type: 'response',
        url,
        data,
        method: 'POST',
        notApi: true,
        // in order to send form params no Content Type header needs to be provided
        clearContentType: true,
    });
export default authRequest;
