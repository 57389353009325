import { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import customerAuthStatusRequest, {
    ICustomerAuthStatusResponse,
} from 'data/requests/customer/customerAuthStatusRequest';
import { useSelector } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        customerStatus: state.entities.customerAuthStatusRequest as ICustomerAuthStatusResponse,
    };
};

interface ICustomer {
    id: number;
    name: string;
    email: string;
}

const useAuth = () => {
    const [{ isFinished }] = useRequest(customerAuthStatusRequest());
    const { customerStatus } = useSelector(mapStateToProps);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [customer, setCustomer] = useState<ICustomer>();
    const [isCustomerRequestFinished, setIsCustomerRequestFinished] = useState(false);

    useEffect(() => {
        if (isFinished) {
            setIsLoggedIn(customerStatus.isLoggedIn);
            setCustomer(customerStatus.customer);
            setIsCustomerRequestFinished(isFinished);
        }
    }, [isFinished]);

    return {
        isLoggedIn,
        customer,
        isCustomerRequestFinished,
    };
};

export default useAuth;
