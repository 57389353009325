import * as React from 'react';

import { useTranslation } from 'react-i18next';
import ProceedButton from '../../../components/checkout/ProceedButton';
import { useContext, useEffect, useRef, useState } from 'react';
import Selectable from 'vkid-ui/lib/Applications/Checkout/Components/Selectable/index';
import LayoutSplit from 'vkid-ui/lib/Layouts/LayoutSplit/index';
import LayoutSplitColumn from 'vkid-ui/lib/Layouts/LayoutSplitColumn/index';
import { useHistory } from 'react-router-dom';
import { empty } from '../../../helpers/empty';
import { IAddress } from '../../../interfaces/checkout/customer/ICustomer';
import useOverlays from '../../../components/overlay/Overlay';
import { CartContext } from '../../../components/checkout/Content';

export interface IProps {
    proceedAction: any | null;
    selectedAddress: IAddress | null;
    title: string;
    selectAddress: any;
    addresses: any;
    showButton: boolean;
    continueDisabled: boolean;
}

const ExistingAddressForm = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { openOverlay } = useOverlays();
    const cart = useContext(CartContext);
    const { proceedAction, addresses, selectedAddress, title, showButton, continueDisabled } = props;
    const [requestStateChange, setRequestStateChange] = useState<boolean>();

    const addChangeAction = (e) => {
        e.preventDefault();
        history.push({ hash: 'add-address' });
        openOverlay('add-address', true);
    };

    if (addresses) {
        addresses.map((address) => {
            if (address.isNew) {
                address.addOn = {
                    label: t('checkout.Change new Address'),
                    onClick: (e) => addChangeAction(e),
                };
            }
        });
    }
    const hasNewAddress = () => !empty(addresses) && !empty(addresses.find((address) => address.isNew));
    const buttons = useRef(
        hasNewAddress()
            ? []
            : [
                  {
                      title: t('checkout.New Address'),
                      onClick: (e) => addChangeAction(e),
                  },
              ],
    );

    useEffect(() => {
        if (requestStateChange) {
            setRequestStateChange(false);
            proceedAction();
        }
        if (hasNewAddress()) {
            buttons.current = [];
        }
    });

    return (
        <React.Fragment>
            <p>
                <b>{title}</b>
            </p>
            <Selectable
                options={addresses}
                buttons={buttons.current}
                labelSelect={t('checkout.Select')}
                labelSelected={t('checkout.Selected')}
            />
            <LayoutSplit>
                <LayoutSplitColumn />
                <LayoutSplitColumn>
                    {showButton && (
                        <ProceedButton
                            disabled={!selectedAddress || continueDisabled}
                            proceedAction={() => {
                                setRequestStateChange(true);
                                window.dispatchEvent(
                                    new CustomEvent('checkout-step-proceed', {
                                        detail: {
                                            action: 'add-contact-info',
                                            items: cart.cartItems,
                                            customer: cart.customer,
                                        },
                                    }),
                                );
                            }}
                        />
                    )}
                </LayoutSplitColumn>
            </LayoutSplit>
        </React.Fragment>
    );
};

export default ExistingAddressForm;
