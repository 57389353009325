import { default as React, useCallback, useEffect, useState } from 'react';
import ControlSelect from 'vkid-ui/lib/Components/ControlSelect/index';

interface IProps<T extends string> {
    config: {
        disabled: boolean;
        default?: string;
        selectedValue: string;
        selectedValueLabel: string;
    };
}

interface IPreparedOption {
    value: string;
    label: string;
}

const RegionSelect = <T extends string>(props: IProps<T>): JSX.Element => {
    const { config } = props;

    // outside country select element to rely on
    const countryElement = document.getElementById('country') as HTMLSelectElement;
    const [preparedOptions, setPreparedOptions] = useState<IPreparedOption[]>([]);
    const [selectedItemValue, setSelectedItemValue] = useState(config.selectedValue ? config.selectedValue : '');
    const [selectedItemLabel, setSelectedItemLabel] = useState(
        config.selectedValueLabel ? config.selectedValueLabel : '',
    );

    const effect = (countryCode?: string) => {
        setPreparedOptions([]);
        if (!countryCode || !config[countryCode]) {
            return;
        }

        const options: IPreparedOption[] = [];
        Object.entries(config[countryCode]).map((entity: Object) => {
            options.push({
                value: entity[0],
                label: entity[1].name,
            });
        });

        options.sort((a: IPreparedOption, b: IPreparedOption) => {
            return a.label > b.label ? 1 : -1;
        });

        setPreparedOptions(options);
    };

    useEffect(() => {
        if (!countryElement) {
            return;
        }
        effect(countryElement.value);
        countryElement.addEventListener('change', (e: Event) => {
            const target = e.target as HTMLSelectElement;
            effect(target.value);
        });

        return function cleanup() {
            countryElement.removeEventListener('change', (e: Event) => {
                const target = e.target as HTMLSelectElement;
                effect(target.value);
            });
        };
    }, [countryElement]);

    const selectItem = (value, label) => {
        setSelectedItemValue(value);
        setSelectedItemLabel(label);
    };

    return (
        <React.Fragment>
            <select
                disabled={config.disabled}
                placeholder={config.default}
                value={selectedItemValue}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedValue = e.target.value;
                    const selectedLabel = e.target.options[e.target.selectedIndex].text;
                    selectItem(selectedValue, selectedLabel);
                }}
            >
                {config.default && <option value="">{config.default}</option>}
                {preparedOptions.length &&
                    preparedOptions.map((item: IPreparedOption, key) => (
                        <option key={key} value={item.value}>
                            {item.label}
                        </option>
                    ))}
            </select>
            <input type="hidden" value={selectedItemValue} name="region_id" />
            <input type="hidden" value={selectedItemLabel} name="region" />
        </React.Fragment>
    );
};

export default RegionSelect;
