import * as React from 'react';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import Block from 'vkid-ui/lib/Components/Block';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { useDispatch, useSelector } from 'react-redux';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { includes } from 'lodash';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';
import { ISlice3ModalLabels } from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import { memo } from 'react';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { CartType } from 'components/Cart/Enum/CartType';
import { setLastIsRegularOrSlice } from 'components/Cart/Hooks/useLastRegularOrSlice';

interface IProps {
    isInbankRentalAdd?: boolean;
    slice3ModalLabels?: ISlice3ModalLabels;
    addToCart?: (cartFlowType?: string) => void;
    setCartFlowType: (value: string) => void;
    setLastRegularOrSlice?: (value: string) => void;
}

const Slice3ValidationOverlay = (props: IProps) => {
    const { slice3ModalLabels } = props;
    if (!slice3ModalLabels) {
        return <></>;
    }

    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    const confirm = () => {
        if (props.addToCart) {
            let cartFlowType = '';
            if (!props.isInbankRentalAdd) {
                window.sessionStorage.setItem('cartFlow', CartType.REGULAR_CART);
                props.setCartFlowType(CartType.REGULAR_CART);
                cartFlowType = CartType.REGULAR_CART;
                window.dispatchEvent(
                    new CustomEvent('tab-cart-select-change', { detail: { cartSelection: CartType.REGULAR_CART } }),
                );
            } else {
                window.sessionStorage.setItem('cartFlow', CartType.INBANK_RENTAL_CART);
                props.setCartFlowType(CartType.INBANK_RENTAL_CART);
                cartFlowType = CartType.INBANK_RENTAL_CART;

                window.dispatchEvent(
                    new CustomEvent('tab-cart-select-change', {
                        detail: { cartSelection: CartType.INBANK_RENTAL_CART },
                    }),
                );
            }
            if (props.setLastRegularOrSlice) {
                props.setLastRegularOrSlice(CartType.REGULAR_CART);
            }
            setLastIsRegularOrSlice(CartType.REGULAR_CART);
            props.addToCart(cartFlowType);
            dispatch(closeOverlay({ name: 'all' }));
        }
    };

    return (
        <Overlay
            layout="focusview"
            title={slice3ModalLabels.slice3ModalTitle}
            isOpen={includes(openOverlays, 'add-slice')}
            doClose={() => {
                dispatch(closeOverlay({ name: 'all' }));
            }}
            zIndex={16000004}
            size="medium"
            buttonIcons={[
                {
                    icon: 'close',
                    onClick: () => {
                        dispatch(closeOverlay({ name: 'all' }));
                    },
                },
            ]}
        >
            <Block>
                <RenderHTML html={slice3ModalLabels.slice3ModalDescription} nowrapper={true} />
                <Buttons>
                    <Button
                        onClick={() => confirm()}
                        title={slice3ModalLabels.slice3ModalConfirmLabel}
                        intent="primary"
                    />
                    <Button
                        onClick={() => dispatch(closeOverlay({ name: 'all' }))}
                        title={slice3ModalLabels.slice3ModalNotAgreeButtonLabel}
                    />
                </Buttons>
            </Block>
        </Overlay>
    );
};

export default memo(Slice3ValidationOverlay);
