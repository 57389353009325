import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { CLIENT_TYPE, IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { postRequest } from 'data/requests/postRequest';
import { isLoggedIn } from '../../../../helpers/customer/isLoggedIn';
import { useEffect, useState } from 'react';
import { IShippingAddress } from '../../../../interfaces/checkout/quote/IQuote';
import useOverlays from '../../../../components/overlay/Overlay';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const EveryPay = (props: IProps) => {
    const { method, email, extensionAttributes, setAllowQuery } = props;
    const { openOverlay, closeOverlay } = useOverlays();

    const [everyPayClicked, setEveryPayClicked] = useState<boolean>(false);

    const [{}, postData] = useMutation((data) =>
        postRequest({
            type: 'everyPayPayment',
            url: isLoggedIn ? 'everypay/payment-adapter/mine' : `everypay/payment-adapter/${window.quoteIdMask}`,
            data,
            useStoreCode: true,
        }),
    );

    const effectBusinessAddress = (e) => {
        if (e.detail && e.detail.address && everyPayClicked) {
            makeQuery(e.detail.address);
            setEveryPayClicked(false);
        }
    };

    useEffect(() => {
        window.addEventListener('business-address-forward', effectBusinessAddress);
        return function cleanup() {
            window.removeEventListener('business-address-forward', effectBusinessAddress);
        };
    }, [everyPayClicked]);

    const makeQuery = (businessAddress?: IShippingAddress) => {
        openOverlay('payment-loading-overlay', true);
        setAllowQuery(true);
        postData({
            cartId: window.quoteIdMask,
            email,
            billing_address: businessAddress,
            methodId: 'card', // only one method is chosen by customer. Additional query is needed for other methods
            tokenId: 0, // card selection query needs to be implemented, not in scope at the moment
            paymentMethod: {
                method: method.code,
                extension_attributes: extensionAttributes ? extensionAttributes() : [],
            },
        }).then((response) => {
            closeOverlay('payment-loading-overlay', false);
            window.dispatchEvent(new CustomEvent('subscription-save'));
            setAllowQuery(false);
            if (response.status === 200 && response.body) {
                window.location.href = response.body;
            }
        });
    };

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                if (props.paymentMethodProps.clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
                    setEveryPayClicked(true);
                    window.dispatchEvent(new CustomEvent('business-address-save'));
                } else {
                    makeQuery();
                }
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default EveryPay;
