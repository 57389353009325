import * as React from 'react';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import TfBankCommon from '../../../../components/checkout/Payment/Methods/TfBankCommon';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
}

const TfBank = (props: IProps) => {
    return (
        <TfBankCommon
            paymentMethodProps={props.paymentMethodProps}
            method={props.method}
            requestUrl={`tfbankhp/request/redirect`}
        />
    );
};

export default TfBank;
