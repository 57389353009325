import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormList from 'vkid-ui/lib/Components/FormList';
import FormListItem from 'vkid-ui/lib/Components/FormListItem';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';
import { useMutation } from 'redux-query-react';
import loginRequest from 'components/Customer/requests/LoginRequest';
import Callout from 'vkid-ui/lib/Components/Callout';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
            formKey: string;
            forgotPasswordUrl: string;
        };
        labels: {
            email: string;
            password: string;
            signIn: string;
            forgotPassword: string;
            requiredField: string;
            invalidEmail: string;
            errorMessage: string;
        };
        email: string;
        additionalLoginMethods: string[];
    };
}

const LoginForm: React.FC<IProps> = ({ config }) => {
    const [{}, userLoginRequest] = useMutation((email: string, password: string) => loginRequest(email, password));
    const formElement = useRef<HTMLFormElement | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const formik = useFormik({
        initialValues: {
            email: config.email || '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(config.labels.invalidEmail).required(config.labels.requiredField),
            password: Yup.string().required(config.labels.requiredField),
        }),
        onSubmit: (formValues) => loginUser(formValues.email, formValues.password),
    });

    const loginUser = (email, password) => {
        setErrorMessage(undefined);
        void userLoginRequest(email, password).then((response) => {
            if (response.status === 400 || response.body.errors) {
                setErrorMessage(response.body.message);
            } else {
                window.location.assign(response.body.redirectUrl);
            }
        });
    };

    return (
        <div className="layout-login">
            <div className="layout-login__column">
                {!!errorMessage && (
                    <>
                        <Callout intent="danger" isAlert={true} ariaLive="assertive">
                            {errorMessage}
                        </Callout>
                    </>
                )}
                <form
                    action={config.formConfig.actionUrl}
                    method="post"
                    ref={formElement}
                    onSubmit={formik.handleSubmit}
                    id="login-form"
                >
                    <input type="hidden" name="form_key" value={config.formConfig.formKey} />
                    <FormList>
                        <FormListItem
                            label={config.labels.email}
                            required={true}
                            labelFor="email"
                            error={formik.touched.email && formik.errors.email}
                        >
                            <ControlInput
                                type="text"
                                name="email"
                                id="email"
                                hasError={!!(formik.touched.email && formik.errors.email)}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormListItem>
                        <FormListItem
                            label={config.labels.password}
                            required={true}
                            labelFor="password"
                            error={formik.touched.password && formik.errors.password}
                        >
                            <ControlInput
                                type="text"
                                name="password"
                                id="password"
                                secureTextEntry={true}
                                hasError={!!(formik.touched.password && formik.errors.password)}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormListItem>
                    </FormList>
                    <Buttons>
                        <Button type="submit" intent="primary" title={config.labels.signIn} />
                        <Button
                            type="anchor"
                            href={config.formConfig.forgotPasswordUrl}
                            title={config.labels.forgotPassword}
                        />
                    </Buttons>
                </form>
                {config.additionalLoginMethods && config.additionalLoginMethods.length > 0 && (
                    <div className="additional-login-methods">
                        {config.additionalLoginMethods.map((methodHtml, index) => (
                            <div key={index} dangerouslySetInnerHTML={{ __html: methodHtml }} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginForm;
