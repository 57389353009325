import * as React from 'react';
import { memo, useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import requiredProductRequest from 'data/requests/product/requiredProductRequest';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { DefaultRootState, useSelector } from 'react-redux';

interface IProps {
    productId?: string;
    inbankRentalDisabled?: boolean | undefined;
    showFinalPrice?: boolean | undefined;
}

const RequiredProduct = (props: IProps) => {
    const { productId, inbankRentalDisabled, showFinalPrice } = props;

    if (!productId) {
        return <></>;
    }

    const [{ isFinished }, reloadQuery] = useRequest(requiredProductRequest(productId));

    const { requiredProduct } = useSelector((state: DefaultRootState) => {
        return {
            requiredProduct: (state as any).entities[`requiredProductRequest${productId}`],
        };
    });

    useEffect(() => {
        if (productId) {
            reloadQuery();
        }
    }, [productId]);

    const requiredBlockComponent = () => {
        return (
            <>
                {isFinished &&
                    requiredProduct?.requiredProductsPrice &&
                    requiredProduct?.requiredProductsPrice.products &&
                    requiredProduct?.requiredProductsPrice.products.map((requiredProduct, number) => {
                        return !inbankRentalDisabled ? (
                            <span key={number} className="label mt10">
                                {`+${requiredProduct.priceTotal}`}{' '}
                                <a href={requiredProduct.pageUrl} className="has-text-color-primary">
                                    {requiredProduct?.shortName ? requiredProduct.shortName : requiredProduct.name}
                                </a>
                            </span>
                        ) : (
                            <div key={number} className="label mt10">
                                {`+${requiredProduct.priceTotal}`}{' '}
                                <a href={requiredProduct.pageUrl} className="has-text-color-primary">
                                    {requiredProduct?.shortName ? requiredProduct.shortName : requiredProduct.name}
                                </a>
                            </div>
                        );
                    })}
                {showFinalPrice &&
                    requiredProduct?.requiredProductsPrice?.priceWithRequiredLabel &&
                    requiredProduct?.requiredProductsPrice?.priceWithRequired &&
                    (!inbankRentalDisabled ? (
                        <>
                            <span className="label mt10">
                                {requiredProduct?.requiredProductsPrice.priceWithRequiredLabel}
                            </span>
                            <span className="price">{requiredProduct?.requiredProductsPrice.priceWithRequired}</span>
                        </>
                    ) : (
                        <>
                            <div className="label mt10">
                                {requiredProduct?.requiredProductsPrice.priceWithRequiredLabel}
                            </div>
                            <div className="price">{requiredProduct?.requiredProductsPrice.priceWithRequired}</div>
                        </>
                    ))}
            </>
        );
    };

    return (
        <React.Fragment>
            {requiredBlockComponent()}

            {isFinished && requiredProduct && requiredProduct?.html && (
                <React.Fragment>
                    <span className={'label'}>
                        <RenderHTML html={requiredProduct.html} nowrapper={true} />
                    </span>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default memo(RequiredProduct);
