import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { CartType } from 'components/Cart/Enum/CartType';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import useLastRegularOrSlice, { setLastIsRegularOrSlice } from 'components/Cart/Hooks/useLastRegularOrSlice';
import { empty } from '../../helpers/empty';

export interface ICartTabProps {
    config: {
        cartType: CartType;
        monthlyPaymentLabel: string;
        cartTotalLabel: string;
        sliceCartTotalLabel: string;
        cartTotalValue: string;
        cartTotalValueSlice: string;
        regularBasketProductCount: number;
        inbankRentalBasketProductCount: number;
    };
}

interface TabRequestReponse {
    regularBasketProductCount?: number;
    inbankRentalBasketProductCount?: number;
}

const CartTabs: React.FunctionComponent<ICartTabProps> = (props) => {
    const [cartTotalValue, setCartTotalValue] = useState<string>(props.config.cartTotalValue);
    const [cartTotalValueSlice, setCartTotalValueSlice] = useState<string>(props.config.cartTotalValueSlice);
    const [regularBasketProductCount, setRegularBasketProductCount] = useState(props.config.regularBasketProductCount);
    const [inbankRentalBasketProductCount, setInbankRentalBasketProductCount] = useState(
        props.config.inbankRentalBasketProductCount,
    );
    const [lastRegularOrSlice, setLastRegularOrSlice] = useLastRegularOrSlice();

    const [activeBasket, setActiveBasket] = useState<string>(
        window.sessionStorage.getItem('cartFlow') ?? props.config.cartType,
    );

    useEffect(() => {
        if (
            ([CartType.SLICE3_CART, CartType.REGULAR_CART, CartType.ESTO_SLICE3_CART] as string[]).includes(
                activeBasket,
            )
        ) {
            setLastIsRegularOrSlice(activeBasket);
            setLastRegularOrSlice(activeBasket);
        }
    }, [activeBasket]);

    const [monthlyPaymentLabel, setMonthlyPaymentLabel] = useState<string>(props.config.monthlyPaymentLabel);
    const [{}, tabRequest] = useMutation(() =>
        request({ type: 'cart', url: 'inbank_rental/cart/count', notApi: true }),
    );

    const changeBasket = (event) => {
        doChangeBasket(event.target.value);
    };

    const doChangeBasket = (value: string) => {
        // update checkout values too
        window.dispatchEvent(new CustomEvent('active-basket-changed', { detail: value }));
        window.sessionStorage.setItem('cartFlow', value);
        setActiveBasket(value);
        if (([CartType.SLICE3_CART, CartType.REGULAR_CART, CartType.ESTO_SLICE3_CART] as string[]).includes(value)) {
            setLastIsRegularOrSlice(value);
            setLastRegularOrSlice(value);
        }
    };

    const monthlyPaymentChanged = (e) => {
        setMonthlyPaymentLabel(e.detail.tabMonthlyPaymentLabel);
    };

    useEffect(() => {
        window.addEventListener('tab-inbank-rental-monthly-payment-change', monthlyPaymentChanged);
        return function cleanup() {
            window.removeEventListener('tab-inbank-rental-monthly-payment-change', monthlyPaymentChanged);
        };
    }, []);

    const cartSelectionChange = (e) => {
        doChangeBasket(e.detail.cartSelection);
    };

    useEffect(() => {
        window.addEventListener('tab-cart-select-change', cartSelectionChange);
        return function cleanup() {
            window.removeEventListener('tab-cart-select-change', cartSelectionChange);
        };
    }, []);

    const cartTotalChange = (e) => {
        setCartTotalValue(e.detail.grandTotal.value);
        setCartTotalValueSlice(e.detail.sliceGrandTotal.value);
    };

    useEffect(() => {
        window.addEventListener('tab-cart-total-change', cartTotalChange);
        return function cleanup() {
            window.removeEventListener('tab-cart-total-change', cartTotalChange);
        };
    }, []);

    const effect = useCallback(async () => {
        const response = await tabRequest();
        const tabResponse: TabRequestReponse = response.body;

        if (tabResponse?.regularBasketProductCount !== undefined) {
            setRegularBasketProductCount(tabResponse.regularBasketProductCount);
        }

        if (tabResponse?.inbankRentalBasketProductCount !== undefined) {
            setInbankRentalBasketProductCount(tabResponse.inbankRentalBasketProductCount);
        }
    }, [tabRequest]);

    useEffect(() => {
        window.addEventListener('cart-altered', effect); //effect override
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    });

    if (empty(inbankRentalBasketProductCount) && activeBasket === CartType.INBANK_RENTAL_CART) {
        // Fix for browser back button pressed after submit order is pressed
        doChangeBasket(CartType.REGULAR_CART);
    }

    return (
        <React.Fragment>
            {(inbankRentalBasketProductCount > 0 || regularBasketProductCount > 0) && (
                <ul className="page-header__tabs">
                    {inbankRentalBasketProductCount > 0 && (
                        <li>
                            <input
                                type={'radio'}
                                name={'cartType'}
                                id={CartType.INBANK_RENTAL_CART}
                                value={CartType.INBANK_RENTAL_CART}
                                checked={activeBasket == CartType.INBANK_RENTAL_CART}
                                onChange={changeBasket}
                            />
                            <label htmlFor={CartType.INBANK_RENTAL_CART}>{monthlyPaymentLabel}</label>
                        </li>
                    )}

                    {regularBasketProductCount > 0 && (
                        <li>
                            <input
                                type={'radio'}
                                name={'cartType'}
                                id={CartType.REGULAR_CART}
                                value={lastRegularOrSlice}
                                checked={
                                    activeBasket === CartType.ESTO_SLICE3_CART ||
                                    activeBasket === CartType.REGULAR_CART ||
                                    activeBasket === CartType.SLICE3_CART
                                }
                                onChange={changeBasket}
                            />
                            <label htmlFor={CartType.REGULAR_CART}>
                                {lastRegularOrSlice == CartType.REGULAR_CART &&
                                    props.config.cartTotalLabel.replace('%1', cartTotalValue)}
                                {(lastRegularOrSlice === CartType.SLICE3_CART ||
                                    lastRegularOrSlice === CartType.ESTO_SLICE3_CART) &&
                                    props.config.sliceCartTotalLabel.replace('%1', cartTotalValueSlice)}
                            </label>
                        </li>
                    )}
                </ul>
            )}
        </React.Fragment>
    );
};

export default CartTabs;
