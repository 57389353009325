import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import Block from 'vkid-ui/lib/Components/Block';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm';
import Button from 'vkid-ui/lib/Components/Button';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import { closeOverlay } from 'data/overlays/overlayHandler';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import termsVerificationRequest, {
    ITermsVerificationResponse,
} from 'components/TermsVerify/requests/termsVerificationRequest';
import { useMutation, useRequest } from 'redux-query-react';
import termsSaveRequest from 'components/TermsVerify/requests/termsSaveRequest';
import Toaster from 'vkid-ui/lib/Components/Toaster';

export interface IVerifyProps {
    config: {
        modalTitle: string;
        modalBody: string;
        agreeButtonLabel: string;
    };
}
const mapStateToProps = (state) => {
    return {
        termsVerification: state.entities.termsVerificationRequest as ITermsVerificationResponse,
    };
};

const TermsVerify = (props: IVerifyProps) => {
    const [isOpened, setIsOpened] = useState(true);
    const [{ isFinished }] = useRequest(termsVerificationRequest());
    const { termsVerification } = useSelector(mapStateToProps);
    const [{}, saveRequest] = useMutation(() => termsSaveRequest());

    const dispatch = useDispatch();

    const handleAgree = useCallback(async () => {
        saveRequest().then((response) => {
            Toaster.addToast({
                intent: response.body.errors ? 'danger' : 'success',
                text: response.body.message,
                asHtml: true,
            });
            if (!response.body.errors) {
                setIsOpened(false);
            }
        });
    }, []);

    useEffect(() => {
        if (isFinished && termsVerification.needToRender && isOpened) {
            document.body.closest('html')?.classList.add('overlayopen');
        } else {
            document.body.closest('html')?.classList.remove('overlayopen');
        }
        return function cleanup() {
            document.body.closest('html')?.classList.remove('overlayopen');
        };
    }, [isFinished, termsVerification, isOpened]);

    return (
        <React.Fragment>
            <Overlay
                layout="focusview"
                title={props.config.modalTitle}
                isOpen={isFinished && termsVerification.needToRender && isOpened}
                doClose={() => {
                    dispatch(closeOverlay({ name: 'all' }));
                }}
                zIndex={16000004}
                size="medium"
            >
                <Block>
                    <RenderHTML nowrapper={true} html={props.config.modalBody} />
                    <LayoutForm>
                        <Buttons layout="vertical">
                            <Button
                                title={props.config.agreeButtonLabel}
                                intent="primary"
                                onClick={() => handleAgree()}
                            />
                        </Buttons>
                    </LayoutForm>
                </Block>
            </Overlay>
        </React.Fragment>
    );
};

TermsVerify.displayName = 'TermsVerify';

export default TermsVerify;
