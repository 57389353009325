import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import questionsRequest from 'components/BuyBack/requests/questionsRequest';
import { useSelector } from 'react-redux';
import FormRow from 'vkid-ui/lib/Components/FormRow/index';
import RadioWithLabel from 'vkid-ui/lib/Components/RadioWithLabel';
import { IAnswer, IBuyBackDevice } from 'components/BuyBack/BuyBack';
import LoadingContent from 'vkid-ui/lib/Components/LoadingContent';

interface IProps {
    selectedDevice: IBuyBackDevice;
    setParentAnswers: ([]) => void;
}

export interface IBuyBackQuestion {
    id: number;
    text: string;
    answers: [
        {
            id: number;
            name: string;
        },
    ];
}

const mapStateToProps = (state) => {
    return {
        buyBackQuestions: state.entities?.buyBackQuestions?.questions as IBuyBackQuestion[],
    };
};

const Questions = (props: IProps) => {
    const { selectedDevice, setParentAnswers } = props;
    const [{ isPending }] = useRequest(questionsRequest(selectedDevice.model_id));
    const { buyBackQuestions } = useSelector(mapStateToProps);
    const [selectedAnswers, setSelectedAnswers] = useState<IAnswer[]>([]);

    const isAnswerSelected = (questionId: number, answerId: number) => {
        return selectedAnswers.some((answer) => answer.answer_id === answerId && answer.question_id === questionId);
    };

    const allAnswered = (answers: IAnswer[]) => answers.length === buyBackQuestions.length;

    if (isPending) {
        return <LoadingContent layout="inline" />;
    }

    return (
        <>
            {buyBackQuestions &&
                buyBackQuestions.map((question, index) => {
                    return (
                        <>
                            <>
                                <FormRow key={index} label={question.text}>
                                    {question.answers.map((answer, i) => {
                                        return (
                                            <RadioWithLabel
                                                key={`i${answer.name}`}
                                                label={answer.name}
                                                checked={isAnswerSelected(question.id, answer.id)}
                                                value={answer.id.toString()}
                                                onChange={() => {
                                                    const newAnswers = selectedAnswers.filter((selectedAnswer) => {
                                                        return (
                                                            selectedAnswer && selectedAnswer.question_id !== question.id
                                                        );
                                                    });

                                                    newAnswers.push({
                                                        answer_id: answer.id,
                                                        question_id: question.id,
                                                    });
                                                    setSelectedAnswers(newAnswers);

                                                    if (allAnswered(newAnswers)) {
                                                        setParentAnswers(newAnswers);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </FormRow>
                                <hr />
                            </>
                        </>
                    );
                })}
        </>
    );
};

export default Questions;
