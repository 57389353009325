import * as React from 'react';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { useMutation } from 'redux-query-react';
import { request } from '../../../../data/requests/request';
import { ICartItem } from '../../../../interfaces/checkout/cart/cartType';
import { useEffect, useState } from 'react';
import { empty } from '../../../../helpers/empty';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
}

interface IAppleLineItem {
    label: string;
    amount: number;
    type: string;
}

interface IPaymentData {
    actionSuccess: string;
    allowedCardNetworks: string[];
    merchantCapability: string[];
    transactionInfoLabel: string;
}

const BluesnapApplepay = (props: IProps) => {
    const { method } = props;
    const paymentData: IPaymentData = window.paymentLogos.payment[method.code]?.additionalData ?? null;

    const [successApplePaymentInfo, setSuccessApplePaymentInfo] = useState<string>('');

    const [{}, getTokenQuery] = useMutation((appleValidationUrl: string) =>
        request({
            type: 'getBluesnapToken',
            url: `bluesnap/applepay/auth/?validationURL=${encodeURIComponent(appleValidationUrl)}`,
            method: 'GET',
            notApi: true,
        }),
    );

    const handleClick = () => {
        const newLineItems: IAppleLineItem[] = [];

        props.paymentMethodProps?.cart?.items.forEach((cartItem: ICartItem) => {
            newLineItems.push({
                label: cartItem.name,
                amount: cartItem.row_total_incl_tax,
                type: 'final',
            });
        });

        newLineItems.push({
            label: props.paymentMethodProps.shippingMethod?.methodLabel ?? '',
            amount: props.paymentMethodProps.cart?.shipping_incl_tax ?? 0,
            type: 'final',
        });

        // @ts-ignore
        if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
            // @ts-ignore
            const session = new ApplePaySession(3, {
                countryCode: props.paymentMethodProps.selectedAddress?.countryId,
                currencyCode: `${props.paymentMethodProps.cart?.quote_currency_code}`,
                total: {
                    label: paymentData.transactionInfoLabel,
                    amount: `${props.paymentMethodProps.cart?.base_grand_total}`,
                    type: 'final',
                },
                requiredBillingContactFields: ['postalAddress', 'name'],
                requiredShippingContactFields: ['postalAddress', 'name', 'email', 'phone'],
                supportedNetworks: paymentData.allowedCardNetworks,
                merchantCapabilities: paymentData.merchantCapability,
            });

            session.onvalidatemerchant = (event) => {
                getTokenQuery(event.validationURL).then((response) => {
                    if (response.body.result === 'success') {
                        const decodedToken = response.body.token;
                        session.completeMerchantValidation(JSON.parse(decodedToken));
                    }
                });
            };

            session.onshippingmethodselected = (event) => {
                const shippingSelection = {
                    newTotal: `${props.paymentMethodProps.cart?.base_grand_total}`,
                    newLineItems,
                };
                session.completeShippingMethodSelection(shippingSelection);
            };

            session.onshippingcontactselected = (event) => {
                const shippingSelection = {
                    newTotal: {
                        label: paymentData.transactionInfoLabel,
                        amount: `${props.paymentMethodProps.cart?.base_grand_total}`,
                    },
                    newLineItems,
                    newShippingMethods: [
                        {
                            label: `${props.paymentMethodProps.shippingMethod?.methodLabel}`,
                            detail: `${props.paymentMethodProps.shippingMethod?.methodLabel}`,
                            amount: `${props.paymentMethodProps.cart?.shipping_amount}`,
                            identifier: `${props.paymentMethodProps.shippingMethod?.methodCode}`,
                        },
                    ],
                    errors: [],
                };

                event.target.completeShippingContactSelection(shippingSelection);
            };

            session.onpaymentauthorized = (event) => {
                // @ts-ignore
                window.bsAuthorised = event;
                let paymentInfo;

                if (event.isTrusted && event.payment) {
                    paymentInfo = event.payment;
                    // @ts-ignore
                    session.completePayment(ApplePaySession.STATUS_SUCCESS);
                } else {
                    // @ts-ignore
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                }
                setSuccessApplePaymentInfo(JSON.stringify(paymentInfo));
            };

            session.begin();
        }
    };

    useEffect(() => {
        if (!empty(successApplePaymentInfo)) {
            setTimeout(() => {
                const form = document.getElementById('success-form-apple-pay');
                if (!form) {
                    return;
                }
                // @ts-ignore
                form.submit();
            }, 50);
        }
    }, [successApplePaymentInfo]);

    // Apple specific global object that exists in safari only and if apple pay ready device
    // @ts-ignore
    if (!(window.ApplePaySession && ApplePaySession.canMakePayments())) {
        return <></>;
    }

    return (
        <>
            <form id={`success-form-apple-pay`} hidden={true} method="POST" action={paymentData.actionSuccess}>
                <input type={`hidden`} name={`paymentCode`} value={method.code} />
                <input type={`hidden`} name={`email`} value={props.paymentMethodProps.email} />
                <input
                    id={`success-apple-payment-input`}
                    type={`hidden`}
                    name={`result`}
                    value={successApplePaymentInfo}
                />
            </form>
            <SimplePayment
                method={method.code}
                key={method.code}
                onClick={() => {
                    handleClick();
                }}
                label={method.title}
                paymentMethodProps={props.paymentMethodProps}
            />
        </>
    );
};

export default BluesnapApplepay;
