import * as React from 'react';
import { CartType } from 'components/Cart/Enum/CartType';
import PriceLabel from 'components/Catalog/Product/DetailComponent/PriceLabel';
import RequiredProduct from 'components/Catalog/Product/RequiredProduct';
import { IConfigPrice, IProductConfig, ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { memo } from 'react';

interface IProps {
    isInterest: boolean;
    isOnlyOneMethod: boolean;
    cartFlowType: string;
    setCartFlowType: (value: string) => void;
    price: IConfigPrice;
    oldPrice?: string;
    selectedProductInformation?: ISelectedProductInformation;
    productConfig: IProductConfig;
}

const RegularCartOption = (props: IProps) => {
    const {
        isInterest,
        isOnlyOneMethod,
        cartFlowType,
        setCartFlowType,
        price,
        oldPrice,
        selectedProductInformation,
        productConfig,
    } = props;

    return (
        <li>
            {!isInterest && !isOnlyOneMethod && (
                <input
                    checked={cartFlowType === CartType.REGULAR_CART}
                    onChange={() => setCartFlowType(CartType.REGULAR_CART)}
                    type="radio"
                    name="r01"
                    id="r01_1"
                />
            )}
            <div className="product-pricing__option">
                <label htmlFor="r01_1">
                    <PriceLabel price={price} oldPrice={oldPrice}>
                        <RequiredProduct
                            key={`general-required`}
                            showFinalPrice={true}
                            productId={selectedProductInformation?.productId || productConfig.id}
                        />
                    </PriceLabel>
                </label>
            </div>
        </li>
    );
};

export default memo(RegularCartOption);
