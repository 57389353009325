import { request } from 'data/requests/request';
import { IAnswer } from 'components/BuyBack/BuyBack';

export interface IPriceQuoteRequestData {
    answers: IAnswer[];
    extras: number[];
    model_id: string;
}

export interface IPriceQuote {
    price_quote_id: string;
    offered_price_formatted: string;
    offered_price: number;
    model_name: string;
    model_id: string;
}

const priceQuoteRequest = (data: IPriceQuoteRequestData) =>
    request({
        type: 'buyBackPriceQuote',
        url: `buyback/ajax/querypricequote`,
        method: 'POST',
        force: true,
        notApi: true,
        data,
    });

export default priceQuoteRequest;
