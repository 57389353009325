import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Detail, { IDetailViewConfig } from 'components/Catalog/Product/Detail';

interface IProps {
    dom: string;
    config: IDetailViewConfig;
}

const DetailViewWrapper = (props: IProps) => {
    const { dom, config } = props;

    return (
        <React.Fragment>
            <BrowserRouter>
                <Detail dom={dom} config={config} />
            </BrowserRouter>
        </React.Fragment>
    );
};

export default DetailViewWrapper;
