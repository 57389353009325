import React, { useEffect, useState } from 'react';
import { CartType } from 'components/Cart/Enum/CartType';

export const setLastIsRegularOrSlice = (value: string) => {
    window.sessionStorage.setItem('cartFlowSliceOrRegular', value);
};

const useLastRegularOrSlice = (): [string, React.Dispatch<string>] => {
    const [lastRegularOrSlice, setLastRegularOrSlice] = useState((): string => {
        return window.sessionStorage.getItem('cartFlowSliceOrRegular') ?? CartType.REGULAR_CART;
    });

    const effect = (e) => {
        if (e.detail.isSlice3) {
            setLastRegularOrSlice(CartType.SLICE3_CART);
            setLastIsRegularOrSlice(CartType.SLICE3_CART);
        } else {
            setLastRegularOrSlice(CartType.REGULAR_CART);
            setLastIsRegularOrSlice(CartType.REGULAR_CART);
        }
    };

    useEffect(() => {
        window.addEventListener('cart-altered-regular-or-slice', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered-regular-or-slice', effect);
        };
    }, []);

    return [lastRegularOrSlice, setLastRegularOrSlice];
};

export default useLastRegularOrSlice;
