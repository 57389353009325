import { request } from 'data/requests/request';

const crossSaleRequestBySku = (sku: string) =>
    request({
        type: 'crossSaleBySku',
        url: `crosssell/check/byProductSku`,
        data: { productSku: sku },
        notApi: true,
    });

export default crossSaleRequestBySku;
