import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import Product from 'components/Cart/Product/Product';
import classNames from 'classnames';
import { CartType } from 'components/Cart/Enum/CartType';
import { IInbankRentalProductLabels } from 'components/Cart/CheckoutCart';
import { IInbankRentalLabels } from 'components/Cart/InbankRental/InbankRentalSidebar';

export interface IInbankRentalProductsProps {
    products: ICartProduct[];
    quoteId?: string;
    emptyMessage?: string;
    tabActive: boolean;
    inbankRentalProductLabels?: IInbankRentalProductLabels;
    inbankRentalLabels?: IInbankRentalLabels;
}

const InbankRentalProducts = (props: IInbankRentalProductsProps) => {
    const { products, quoteId, emptyMessage, tabActive, inbankRentalProductLabels, inbankRentalLabels } = props;
    const [error, setError] = useState<string>();

    const effect = () => {
        setError(undefined);
    };
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [error]);

    if (!products || !quoteId) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <div className={classNames('layout-cart__main', { hidden: !tabActive })}>
                {error && <div className="cart-callout intent-danger">{error}</div>}
                {emptyMessage && <div className="cart-callout">{emptyMessage}</div>}
                <table className={'cart-table'}>
                    <tbody>
                        {products.map((product, key) => (
                            <Product
                                inbankRentalProductLabels={inbankRentalProductLabels}
                                product={product}
                                key={key}
                                quoteId={quoteId}
                                cartType={CartType.INBANK_RENTAL_CART}
                                setError={setError}
                                isInbankRental={true}
                                inbankRentalLabels={inbankRentalLabels}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default InbankRentalProducts;
