import * as React from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import RenderLink from 'vkid-ui/lib/Components/RenderLink';
import { includes } from 'lodash';
import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;
import { useKeypress } from 'vkid-ui/lib/Utilities/Generic';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { updateStore } from 'data/overlays/updateStore';
import { useCallback, useState } from 'react';
import Result from 'components/Catalog/Search/Results';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { empty } from '../../../helpers/empty';

interface IProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
    /** Output type */
    output: 'field' | 'desktop' | 'button';
}

const Search = (props: IProps) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const openSearch = (mobile: boolean) => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: mobile ? 'search-mobile' : 'search-desktop' }));
    };

    const closeSearch = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    const [str, setStr] = useState('');
    const [queryString, setQueryString] = useState('');

    const debouncedSave = useCallback(
        debounce((nextValue) => setQueryString(nextValue), 300),
        [],
    );

    useKeypress(
        'Escape',
        () => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
                setStr('');
                debouncedSave('');
                closeSearch();
            }
        },
        [str],
    );

    useKeypress(
        'Enter',
        () => {
            const submit = document.getElementById('mobileSubmit');
            if (submit && !empty(str)) {
                submit.click();
            }
        },
        [str],
    );

    const RenderResults = () => {
        const open = queryString.length >= 3;
        return (
            <div className={classNames('search__results', { open })}>
                {open && <Result queryString={queryString} />}
            </div>
        );
    };

    const RenderField = (autofocus: boolean, results: boolean) => {
        return (
            <React.Fragment>
                <label>
                    <span className="icon">
                        <img src={props.config.iconSearch || ''} alt={props.config.label || ''} />
                    </span>
                    <span className="label">{props.config.label || ''}</span>
                    <input
                        type="text"
                        autoComplete="off"
                        autoFocus={autofocus}
                        name="q"
                        value={str}
                        placeholder={props.config.placeholder}
                        onChange={(e) => {
                            setStr(e.target.value);
                            debouncedSave(e.target.value);
                        }}
                    />
                    <button onClick={closeSearch} className="icon">
                        <img src={props.config.iconClose || ''} alt="" />
                    </button>
                    <input type="submit" id="mobileSubmit" value={props.config.label || ''} />
                </label>
                {results && RenderResults()}
            </React.Fragment>
        );
    };

    const RenderOverlay = () => {
        return (
            <Overlay
                isOpen={includes(openOverlays, 'search-mobile')}
                doClose={closeSearch}
                layout="search"
                className="search-mobile"
                customHeader={
                    <div className="search-mobile-heading">
                        <div className="search">{RenderField(true, false)}</div>
                    </div>
                }
            >
                <div className="search-mobile-content">{RenderResults()}</div>
            </Overlay>
        );
    };
    const focusInput = () => {
        const list = document.querySelectorAll<HTMLInputElement>('input[type="text"][name="q"]');
        Array.from(list).map((item) => {
            if (item.offsetParent !== null) {
                item.focus();
            }
        });
    };
    if (props.output === 'button') {
        return (
            <React.Fragment>
                <a
                    href="#"
                    className="desktop"
                    onClick={(e) => {
                        e.preventDefault();
                        openSearch(false);
                        focusInput();
                    }}
                />
                <RenderLink
                    dom={props.dom}
                    onClick={(e) => {
                        e.preventDefault();
                        openSearch(true);
                        focusInput();
                    }}
                />
            </React.Fragment>
        );
    }

    if (props.output === 'field') {
        return (
            <React.Fragment>
                {RenderField(false, true)}
                {RenderOverlay()}
            </React.Fragment>
        );
    }

    if (props.output === 'desktop') {
        return (
            <div className={classNames('search-desktop__content', { open: includes(openOverlays, 'search-desktop') })}>
                <div className="search">
                    {RenderField(false, true)}
                    {RenderOverlay()}
                    {createPortal(
                        <div
                            className={classNames('search-desktop__backdrop', {
                                open: includes(openOverlays, 'search-desktop'),
                            })}
                            onClick={(e) => {
                                e.preventDefault();
                                closeSearch();
                            }}
                        />,
                        document.body,
                    )}
                </div>
            </div>
        );
    }

    return null;
};

export default Search;
