import * as React from 'react';
import { useEffect, useState } from 'react';
import { empty } from '../../../../../helpers/empty';
import { IDetailProductBreadcrumb, ProductType } from 'components/Catalog/Product/Detail';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    dom: string;
    config: {
        homeLabel: string;
    };
}

const DetailPageBreadcrumbs = (props: IProps) => {
    const [breadCrumbs, setBreadcrumbs] = useState<IDetailProductBreadcrumb[]>([]);
    const [currentName, setCurrentName] = useState<string>();

    const effect = (e) => {
        let selectedId = e.detail.selectedProductInformation?.productId;

        if (!selectedId && e.detail.productConfig?.type === ProductType.SIMPLE_PRODUCT_TYPE) {
            selectedId = e.detail.productConfig.id;
        }

        if (empty(selectedId)) {
            setBreadcrumbs([]);
            setCurrentName('');
            return;
        }

        const selectedBreadcrumbs = e.detail.productConfig?.breadcrumbs[selectedId] ?? undefined;

        if (empty(selectedBreadcrumbs)) {
            setBreadcrumbs([]);
            setCurrentName('');
            return;
        }
        setCurrentName(e.detail.productConfig?.name ?? '');
        setBreadcrumbs(selectedBreadcrumbs);
    };

    useEffect(() => {
        window.addEventListener('product-configuration-change', effect);
        return function cleanup() {
            window.removeEventListener('product-configuration-change', effect);
        };
    }, []);

    return (
        <>
            {empty(breadCrumbs.length) && <RenderHTML html={props.dom} nowrapper={true} />}
            {breadCrumbs.length > 0 && (
                <ul className="path">
                    <li>
                        <a href="/">{props.config.homeLabel}</a>
                    </li>
                    {breadCrumbs.map((breadcrumb: IDetailProductBreadcrumb) => {
                        return (
                            <li key={breadcrumb.label}>
                                <a href={breadcrumb.url}>{breadcrumb.label}</a>
                            </li>
                        );
                    })}
                    {!empty(currentName) && <li>{currentName}</li>}
                </ul>
            )}
        </>
    );
};

export default DetailPageBreadcrumbs;
