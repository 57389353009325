import { request } from 'data/requests/request';

const productSlice3Request = (productId: string) =>
    request({
        type: 'productSlice3Request',
        url: `slice3_config/index/index`,
        data: {
            productId,
        },
        notApi: true,
    });

export default productSlice3Request;
