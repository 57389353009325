import React, { useEffect, useState } from 'react';
import { InsuranceSwitcherConfig } from 'components/InbankRental/InsuranceSwitcher';
import { useMutation } from 'redux-query-react';
import { HttpStatusCode } from '../../enums/HttpStatusCode';
import InsuranceChangedRequest, {
    InsuranceChangedResponse,
} from 'components/CrossSell/requests/InsuranceChangedRequest';
import InbankRentalCrossSellBanner from 'components/CrossSell/InbankRentalCrossSellBanner';

interface IProps {
    config: {
        mainProduct: IMainProduct;
        inbankRentalCrosssellBannerConfig: IInbankRentalCrosssellBanner;
        slice3Message?: string;
        checkoutUrl: string;
        storeUrl: string;
        labels: {
            addedToCart: string;
            goToCart: string;
            continueShopping: string;
        };
    };
}

interface IMainProduct {
    id: number;
    image: string;
    name: string;
    taxPrice: string;
    inbankRentalMonthlyFee: string;
}

interface IInbankRentalCrosssellBanner {
    isInbankRental: boolean;
    image?: string;
    text?: string;
    description?: string;
    insuranceSwitcherConfig: InsuranceSwitcherConfig;
}

const CrossSellCart = (props: IProps) => {
    const { config } = props;

    const { mainProduct, inbankRentalCrosssellBannerConfig, slice3Message, checkoutUrl, storeUrl, labels } = config;
    const [inbankRentalFee, setInbankRentalFee] = useState(mainProduct.inbankRentalMonthlyFee);

    const [{}, inbankRentalRequest] = useMutation((productId: number) => InsuranceChangedRequest(productId));

    const effect = () => {
        void inbankRentalRequest(mainProduct.id).then((res) => {
            if (res.status === HttpStatusCode.OK) {
                const body = res.body as InsuranceChangedResponse;

                setInbankRentalFee(body.inbankRentalMonthlyFee);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('hasInsuranceChanged', effect);
        return function cleanup() {
            window.removeEventListener('hasInsuranceChanged', effect);
        };
    });

    return (
        <>
            <div className="frame-ideal__pre-cart">
                <div className="frame-ideal__pre-cart__limiter">
                    <div className="frame-ideal__pre-cart__image">
                        <div>
                            <img src={mainProduct.image} alt={mainProduct.name} />
                        </div>
                    </div>
                    <div className="frame-ideal__pre-cart__product">
                        <div className="frame-ideal__pre-cart__title">{mainProduct.name}</div>
                        <div className="frame-ideal__pre-cart__price">
                            {mainProduct.taxPrice}
                            {inbankRentalFee && ` | ${inbankRentalFee}`}
                        </div>
                        <div className="frame-ideal__pre-cart__message">{labels.addedToCart}</div>
                        {slice3Message && (
                            <div className="frame-ideal__pre-cart__message text-intent-danger">{slice3Message}</div>
                        )}
                    </div>
                    <div className="frame-ideal__pre-cart__actions">
                        <ul className="cart-buttons">
                            <li>
                                <a href={checkoutUrl} className="cart-button primary">
                                    {labels.goToCart}
                                </a>
                            </li>
                            <li>
                                <a href={storeUrl} className="cart-button">
                                    {labels.continueShopping}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {inbankRentalFee &&
                inbankRentalCrosssellBannerConfig.image &&
                inbankRentalCrosssellBannerConfig.isInbankRental && (
                    <InbankRentalCrossSellBanner
                        image={inbankRentalCrosssellBannerConfig.image}
                        description={inbankRentalCrosssellBannerConfig.description}
                        text={inbankRentalCrosssellBannerConfig.text}
                        insuranceSwitcherConfig={inbankRentalCrosssellBannerConfig.insuranceSwitcherConfig}
                    />
                )}
        </>
    );
};

export default CrossSellCart;
