import { request } from 'data/requests/request';

export interface ISetHasInsuranceRequestData {
    hasInsurance: boolean;
}

export interface ISetHasInsuranceResponseData {
    hasInsurance: boolean;
}

const setHasInsuranceRequest = (data: ISetHasInsuranceRequestData) => {
    return request({
        type: 'hasInbankRentalInsurance',
        url: 'inbank_rental/insurance/set',
        method: 'POST',
        data,
        notApi: true,
    });
};

export default setHasInsuranceRequest;
