import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { useEffect } from 'react';
import Block from 'vkid-ui/lib/Components/Block';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import { useTranslation } from 'react-i18next';
import useOverlays from '../../../../components/overlay/Overlay';
import { useHistory } from 'react-router';
import { empty } from '../../../../helpers/empty';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    requestUrl: string;
}

const TfBankCommon = (props: IProps) => {
    const { method, requestUrl } = props;

    const paymentData = window.paymentLogos.payment[method.code]?.additionalData ?? null;

    const totalToPay = props.paymentMethodProps?.cart?.base_grand_total;

    const { t } = useTranslation();
    const { overlays, closeOverlay } = useOverlays();
    const history = useHistory();
    const isOpen = overlays.indexOf('confirm-tf-bank-down-payment') !== -1;
    const { openOverlay } = useOverlays();

    const close = (e) => {
        e.preventDefault();
        history.goBack();
        closeOverlay('confirm-tf-bank-down-payment');
    };

    const [{}, postData] = useMutation(() => request({ type: method.code, url: requestUrl, notApi: true }));

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && method.code === event.detail.methodCode) {
            postData().then((response) => {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
                if (response.body.redirect_url) {
                    event.detail.setRedirectUrl({ to: response.body.redirect_url, absolute: true });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    const handleClick = () => {
        if (
            totalToPay &&
            paymentData?.requireDownPaymentAmountFrom &&
            totalToPay > parseFloat(paymentData?.requireDownPaymentAmountFrom)
        ) {
            history.push({ hash: 'confirm-tf-bank-down-payment' });
            openOverlay('confirm-tf-bank-down-payment', true);
        } else {
            props.paymentMethodProps.onClick({ redirect: undefined });
        }
    };

    const proceedAction = () => {
        props.paymentMethodProps.onClick({ redirect: undefined });
    };

    return (
        <>
            {!empty(paymentData) && (
                <Overlay
                    isOpen={isOpen}
                    layout="focusview"
                    title={t('checkout.Confirm down payment')}
                    size="small"
                    doClose={(e) => close(e)}
                >
                    <Block>
                        {t('checkout.down_payment_tf_bank_needed_message', {
                            requireDownPaymentAmountFrom: paymentData.requireDownPaymentAmountFrom,
                            requireDownPaymentPercent: paymentData.requireDownPaymentPercent,
                        })}
                        <Buttons layout="vertical-wide">
                            <Button title={t('checkout.Confirm')} intent="primary" onClick={(e) => proceedAction()} />
                            <Button
                                title={t('checkout.Cancel')}
                                intent="primary"
                                layout="link"
                                onClick={(e) => close(e)}
                            />
                        </Buttons>
                    </Block>
                </Overlay>
            )}
            <SimplePayment
                method={method.code}
                key={method.code}
                onClick={() => {
                    handleClick();
                }}
                label={method.title}
                paymentMethodProps={props.paymentMethodProps}
            />
        </>
    );
};

export default TfBankCommon;
