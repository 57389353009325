import * as React from 'react';
import Tooltip from 'vkid-ui/lib/Components/Tooltip/index';
import Icon from 'vkid-ui/lib/Components/Icon/index';

export interface IRenderTooltipProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const RenderTooltip: React.FunctionComponent<IRenderTooltipProps> = (props) => {
    return (
        <Tooltip content={props.config?.content} interactionKind="hover">
            <Icon kind="question" width={14} height={14} />
        </Tooltip>
    );
};

RenderTooltip.displayName = 'RenderTooltip';

export default RenderTooltip;
