import * as React from 'react';
import { ICartProduct, IWarrantyProduct } from 'components/Cart/Interfaces/ICartProduct';
import { useMutation } from 'redux-query-react';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { extractErrors } from '../../../helpers/request/extractErrors';
import { request } from 'data/requests/request';
import useAuth from '../../../hooks/useAuth';
import { CartType } from 'components/Cart/Enum/CartType';
import productSlice3Request from 'data/requests/product/slice3/productSlice3Request';
import {
    slice3ConfigReducer,
    slice3Types,
} from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import Slice3ValidationOverlay from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ValidationOverlay';
import { openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch } from 'react-redux';
import { empty } from '../../../helpers/empty';

export interface IProps {
    relatedProduct: IWarrantyProduct;
    mainProduct: ICartProduct;
    loading: boolean;
    isInbankRental: boolean;
    setIsLoading: (loading: boolean) => void;
    setError: (error: string) => void;
    setActiveBasket?: (value: string) => void;
}

const RelatedProduct = (props: IProps) => {
    const { relatedProduct, mainProduct, loading, isInbankRental, setIsLoading, setError } = props;
    const { customer } = useAuth();
    const [{}, slice3Request] = useMutation((id: string) => productSlice3Request(id));
    const [slice3Config, dispatchSlice] = useReducer(slice3ConfigReducer, undefined);
    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useState<any>();

    const [{}, warrantyRequest] = useMutation((data) =>
        request({
            method: 'POST',
            type: 'response',
            url: isInbankRental
                ? `cart/warranty/inbankRentalAdd/productId/${data.productId}/warrantyProductId/${data.warrantyProductId}/warrantyChildId/${data.warrantyChildId}`
                : `cart/warranty/add/productId/${data.productId}/warrantyProductId/${data.warrantyProductId}/warrantyChildId/${data.warrantyChildId}`,
            notApi: true,
        }),
    );

    const warrantyQuery = useCallback(
        async (data) => {
            const response = await warrantyRequest(data);
            setIsLoading(false);
            if (response.status === 200) {
                const selectedItem = relatedProduct.items.find((item) => item.id === data.warrantyChildId);
                window.dispatchEvent(
                    new CustomEvent('cart-altered', {
                        detail: {
                            action: 'add-to-cart',
                            userId: customer?.id,
                            itemListName: 'Product insurance-warranty',
                            sku: selectedItem?.sku,
                            name: selectedItem?.name,
                            priceValue: selectedItem?.price,
                        },
                    }),
                );
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [queryParams],
    );

    const alterProduct = useCallback(
        async (warrantyChildId: string) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const data: any = {
                productId: mainProduct.id,
                warrantyProductId: relatedProduct.id,
                warrantyChildId,
            };

            if (window.sessionStorage.getItem('cartFlow') === CartType.SLICE3_CART) {
                const sliceResponse = await slice3Request(warrantyChildId);
                dispatchSlice({ type: slice3Types.UPDATE, payload: sliceResponse.body });

                if (sliceResponse.body?.isSliceValidationError) {
                    setQueryParams(data);
                    dispatch(openOverlay({ name: 'add-slice' }));
                    setIsLoading(false);
                    return;
                }
            }
            warrantyQuery(data);
        },
        [warrantyRequest],
    );

    const relatedProductTitle: JSX.Element = (
        <>
            <p className="cart-table-title">{relatedProduct.name}</p>
        </>
    );

    return (
        <React.Fragment>
            <tr className="cart-table-subproduct">
                <td className="cart-table-image">
                    <span className={'image'}>
                        <img src={relatedProduct.image} alt={relatedProduct.name} />
                    </span>
                </td>
                <td>
                    {!empty(relatedProduct?.pageUrl) && <a href={relatedProduct?.pageUrl}>{relatedProductTitle}</a>}
                    {empty(relatedProduct?.pageUrl) && relatedProductTitle}
                    {relatedProduct.callout && <p className="cart-callout intent-promotion">relatedProduct.callout</p>}
                    <ul className="cart-table-config">
                        {relatedProduct.items &&
                            relatedProduct.items.map((relatedSubItem) => {
                                const inputName = `section${mainProduct.id}${relatedSubItem.id}`;
                                return (
                                    <li key={`${relatedProduct.id}-${relatedSubItem.id}`}>
                                        <label>
                                            <input
                                                type="radio"
                                                name={inputName}
                                                checked={relatedSubItem.active}
                                                onChange={() => alterProduct(relatedSubItem.id)}
                                            />
                                            <span>
                                                {relatedSubItem.name} (+{relatedSubItem.price})
                                            </span>
                                        </label>
                                    </li>
                                );
                            })}
                    </ul>
                </td>
                <td>
                    <ul className="cart-table-price">
                        <li>
                            <span className="label">{mainProduct.price.label}</span>{' '}
                            <span className="value sum">{relatedProduct.priceSingle}</span>
                        </li>
                        <li>
                            <span className="label">{mainProduct.qty.label}</span>{' '}
                            <span className="value">{relatedProduct.qty}</span>
                        </li>
                        <li className="total">
                            <span className="label">{mainProduct.total.label}</span>{' '}
                            <span className="value sum">{relatedProduct.priceRow}</span>
                        </li>
                    </ul>
                </td>
            </tr>
            {!loading && slice3Config && props.setActiveBasket && (
                <Slice3ValidationOverlay
                    setCartFlowType={props.setActiveBasket}
                    isInbankRentalAdd={false}
                    addToCart={() => warrantyQuery(queryParams)}
                    slice3ModalLabels={slice3Config.modalLabels}
                />
            )}
        </React.Fragment>
    );
};

export default RelatedProduct;
