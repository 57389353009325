import { request } from 'data/requests/request';

const landingCalculatorRequest = (sku: string) =>
    request({
        type: 'LandingCalculatorRequest',
        url: `inbank_rental_landing/landing/calculate?sku=${sku}&storeCode=${window.STORE_CODE}`,
        method: 'GET',
        notApi: true,
    });

export default landingCalculatorRequest;
