import { request } from 'data/requests/request';

const questionsRequest = (modelId: string) =>
    request({
        type: 'buyBackQuestions',
        url: `buyback/ajax/queryquestions?model_id=${modelId}`,
        method: 'GET',
        notApi: true,
        force: true,
    });

export default questionsRequest;
