import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { postRequest } from 'data/requests/postRequest';
import { isLoggedIn } from '../../../../helpers/customer/isLoggedIn';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const Montonio = (props: IProps) => {
    const { method, email, extensionAttributes, setAllowQuery } = props;
    const methodConfig = window.montonioConfig?.cnf;
    const defaultRegion = methodConfig?.defaultRegion;

    const [{}, postData] = useMutation((data) =>
        postRequest({
            type: 'setPayment',
            url: isLoggedIn
                ? 'carts/mine/set-payment-information'
                : `guest-carts/${window.quoteIdMask}/set-payment-information`,
            data,
            useStoreCode: true,
        }),
    );

    const redirect = { to: `checkout/onepage/success` };
    return methodConfig?.setup.paymentMethods.paymentInitiation.setup[defaultRegion].paymentMethods.map(
        (montonioMethod) => {
            return (
                <SimplePayment
                    method={method.code}
                    key={montonioMethod.identifer}
                    onClick={() => {
                        setAllowQuery(true);
                        postData({
                            cartId: window.quoteIdMask,
                            email,
                            paymentMethod: {
                                method: method.code,
                                extension_attributes: extensionAttributes ? extensionAttributes() : [],
                                additional_data: {
                                    bank_id: montonioMethod.identifer,
                                    pref_region: defaultRegion,
                                },
                            },
                        }).then((response) => {
                            setAllowQuery(false);
                            if (response.body === true || response.body === 'true') {
                                props.paymentMethodProps.onClick({ redirect });
                            }
                        });
                    }}
                    label={montonioMethod.logoUrl ? '' : montonioMethod.name}
                    logoUrl={montonioMethod.logoUrl}
                    paymentMethodProps={props.paymentMethodProps}
                    style={{ backgroundSize: 'contain', backgroundPosition: '50% 50%', backgroundRepeat: 'norepeat' }}
                    description={montonioMethod.name}
                />
            );
        },
    );
};

export default Montonio;
