import React, { useState } from 'react';
import { IBuyBackQuestion } from 'components/BuyBack/Questions';
import { useSelector } from 'react-redux';
import FormRow from 'vkid-ui/lib/Components/FormRow';
import RadioWithLabel from 'vkid-ui/lib/Components/RadioWithLabel';

interface IProps {
    setParentExtras: ([]) => void;
    yesLabel: string;
    noLabel: string;
}

const mapStateToProps = (state) => {
    return {
        buyBackExtras: state.entities?.buyBackQuestions?.extras as IBuyBackQuestion[],
    };
};

const Extras = (props: IProps) => {
    const { setParentExtras, yesLabel, noLabel } = props;

    const { buyBackExtras } = useSelector(mapStateToProps);
    const [selectedExtras, setSelectedExtras] = useState<number[]>([]);

    return (
        <>
            {buyBackExtras &&
                buyBackExtras.map((extra, index) => {
                    return (
                        <FormRow key={index} label={extra.text}>
                            <RadioWithLabel
                                label={yesLabel}
                                checked={selectedExtras.includes(extra.id)}
                                value={extra.id.toString()}
                                onChange={() => {
                                    const newSelectedExtras = [...selectedExtras];
                                    newSelectedExtras.push(extra.id);
                                    setSelectedExtras(newSelectedExtras);

                                    setParentExtras(newSelectedExtras);
                                }}
                            />
                            <RadioWithLabel
                                label={noLabel}
                                checked={!selectedExtras.includes(extra.id)}
                                value={extra.id.toString()}
                                onChange={() => {
                                    const newSelectedExtras = [...selectedExtras];
                                    newSelectedExtras.splice(newSelectedExtras.indexOf(extra.id, 1));
                                    setSelectedExtras(newSelectedExtras);

                                    setParentExtras(newSelectedExtras);
                                }}
                            />
                        </FormRow>
                    );
                })}
        </>
    );
};

export default Extras;
