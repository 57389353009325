import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { CLIENT_TYPE, IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';

import { request } from 'data/requests/request';
import { empty } from '../../../../helpers/empty';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const ResursBank = (props: IProps) => {
    const { method, setAllowQuery, paymentMethodProps } = props;
    const [personalCode, setPersonalCode] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const { t } = useTranslation();

    const effectPersonalCode = (e: CustomEvent) => {
        if (e.detail && e.detail.personalCode) {
            setPersonalCode(e.detail.personalCode);
        }
    };

    useEffect(() => {
        const listener = (e: Event) => effectPersonalCode(e as CustomEvent);
        window.addEventListener('personalCodeChange', listener);
        return function cleanup() {
            window.removeEventListener('personalCodeChange', listener);
        };
    }, []);

    const effectCompanyCode = (e: CustomEvent) => {
        if (e.detail && e.detail.code) {
            setCompanyCode(e.detail.code);
        }
    };

    useEffect(() => {
        const listener = (e: Event) => effectCompanyCode(e as CustomEvent);

        window.addEventListener('companyCodeChange', listener);
        return function cleanup() {
            window.removeEventListener('personalCodeChange', listener);
        };
    }, []);

    const [{}, postData] = useMutation((data) =>
        request({
            type: 'resursBank',
            url: 'resursbank_simplified/checkout/session',
            data,
            method: 'POST',
            notApi: true,
            clearContentType: true,
        }),
    );

    const proceedResursBankClick = (personalCodeValue: string) => {
        setPersonalCode(personalCodeValue);
        const formData = new FormData();
        formData.append('method_code', method.code);
        formData.append('is_company', paymentMethodProps.clientType === CLIENT_TYPE.CLIENT_BUSINESS ? 'true' : 'false');
        formData.append(
            'gov_id',
            paymentMethodProps.clientType === CLIENT_TYPE.CLIENT_BUSINESS ? companyCode : personalCodeValue,
        );

        postData(formData)
            .then((response) => {
                if (response.status !== 200 || !empty(response.body?.error?.message)) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body?.error?.message
                            ? response.body?.error?.message
                            : t('resursBank.Something went wrong'),
                        asHtml: true,
                    });
                } else {
                    const redirect = { to: `resursbank_upitech/checkout/redirect` };
                    props.paymentMethodProps.onClick({ redirect });
                }
            })
            .catch(() => {
                Toaster.addToast({
                    intent: 'danger',
                    text: t('resursBank.Something went wrong'),
                    asHtml: true,
                });
            });
    };

    const handleResursBankClick = () => {
        if (!personalCode) {
            window.dispatchEvent(
                new CustomEvent('personalCodeNeeded', { detail: { callbackMethod: proceedResursBankClick } }),
            );
            return;
        }
        proceedResursBankClick(personalCode);
    };

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => handleResursBankClick()}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default ResursBank;
