import * as React from 'react';

import CartButton from 'components/CartButton';
import LongPage from 'components/LongPage';
import CheckoutCart from 'components/Cart/CheckoutCart';
import AuthorizationHandler from 'components/Authorization/AuthorizationHandler';
import ListViewWrapper from 'components/Catalog/Product/ListViewWrapper';
import Newsletter from 'components/Newsletter';
import Messages from 'components/Messages/Messages';
import CustomerDelete from 'components/AccountDelete/CustomerDelete';
import AddressDelete from 'components/AccountDelete/AddressDelete';
import WebformSubmit from 'components/WebformSubmit';
import GeneralForm from 'components/GeneralForm/GeneralForm';
import RegionSelect from 'components/RegionSelect';
import Search from 'components/Catalog/Search/Search';
import { Title } from 'components/Page/Title';
import { Breadcrumbs } from 'components/Page/Breadcrumbs';
import Carousel from 'vkid-ui/lib/Components/RenderCarousel/index';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import MainMenu from 'components/ui/MainMenu';
import RenderLink from 'vkid-ui/lib/Components/RenderLink/index';
import Cookie from 'components/Cookie';
import GoogleAuth from 'components/GoogleLogin';
import FacebookAuth from 'components/FacebookLogin';
import ComponentMapperRecaptcha from 'components/GoogleRecaptcha/ComponentMapperRecaptcha';
import EveryPayLanding from 'components/EveryPayLanding';
import CrossSell from 'components/CrossSell';
import CtaPopup from 'components/CtaPopup/CtaPopup';
import TermsVerify from 'components/TermsVerify/TermsVerify';
import RenderTooltip from 'components/ui/RenderTooltip';
import CartTabs from 'components/Cart/CartTabs';
import InbankRentalLandingCalculator from 'components/InbankRentalLanding/InbankRentalLandingCalculator';
import DetailViewWrapper from 'components/Catalog/Product/DetailViewWrapper';
import ProductTechnicalSpecs from 'components/Catalog/Product/ProductTechicalSpecs';
import CheckoutProvider from 'components/Checkout/CheckoutProvider';
import DetailPageBreadcrumbs from 'components/Catalog/Product/DetailComponent/Breadcrumbs/DetailBreadcrumbs';
import InsuranceSwitcher from 'components/InbankRental/InsuranceSwitcher';
import CrossSellCart from 'components/CrossSell/CrossSellCart';
import SmartdealB2BCalculator from 'components/SmartdealB2B/SmartdealB2BCalculator';
import BuyBack from 'components/BuyBack/BuyBack';
import ForgotPasswordForm from 'components/Customer/ForgotPasswordForm';
import ResetPasswordForm from 'components/Customer/ResetPasswordForm';
import LoginForm from 'components/Customer/LoginForm';
import RegisterForm from 'components/Customer/RegisterForm';
import RelatedProducts from 'components/Catalog/Product/DetailComponent/RelatedProducts/RelatedProducts';

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        menu: <MainMenu dom={maybeContent} config={maybeConfig} />,
        carousel: <Carousel dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        dropdown: <RenderLink dom={maybeContent} config={maybeConfig} />,
        cart: <CartButton dom={maybeContent} config={maybeConfig} />,
        product: <DetailViewWrapper dom={maybeContent} config={maybeConfig} />,
        checkoutCart: <CheckoutCart config={maybeConfig} />,
        authorization: <AuthorizationHandler config={maybeConfig} />,
        productList: <ListViewWrapper dom={maybeContent} />,
        cookieSelection: <Cookie config={maybeConfig} />,
        ctaPopup: <CtaPopup dom={maybeContent} config={maybeConfig} />,
        search: <Search dom={maybeContent} config={maybeConfig} output={'field'} />,
        searchButton: <Search dom={maybeContent} config={maybeConfig} output="button" />,
        searchDesktop: <Search dom={maybeContent} config={maybeConfig} output="desktop" />,
        newsletter: <Newsletter config={maybeConfig} />,
        messages: <Messages />,
        accountDelete: <CustomerDelete config={maybeConfig} />,
        addressDelete: <AddressDelete config={maybeConfig} />,
        webformSubmit: <WebformSubmit config={maybeConfig} />,
        form: <GeneralForm config={maybeConfig} />,
        longpage: <LongPage dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        regionSelect: <RegionSelect config={maybeConfig} />,
        googleLogin: <GoogleAuth config={maybeConfig} />,
        facebookLogin: <FacebookAuth config={maybeConfig} />,
        crossSell: <CrossSell config={maybeConfig} />,
        title: <Title config={maybeConfig} />,
        tooltip: <RenderTooltip dom={maybeContent} config={maybeConfig} />,
        breadcrumbs: <Breadcrumbs config={maybeConfig} />,
        detailBreadCrumbs: <DetailPageBreadcrumbs dom={maybeContent} config={maybeConfig} />,
        recaptcha: <ComponentMapperRecaptcha />,
        everyPayLanding: <EveryPayLanding config={maybeConfig} />,
        gdprConsentHandler: <ConsentHandler />,
        termsVerify: <TermsVerify config={maybeConfig} />,
        cartTabs: <CartTabs config={maybeConfig} />,
        inbankRentalLandingCalculator: <InbankRentalLandingCalculator config={maybeConfig} />,
        productTechnicalSpecs: <ProductTechnicalSpecs config={maybeConfig} />,
        checkout: <CheckoutProvider />,
        inbankRentalInsurance: <InsuranceSwitcher config={maybeConfig} />,
        crossSellCart: <CrossSellCart config={maybeConfig} />,
        smartdealB2BCalculator: <SmartdealB2BCalculator config={maybeConfig} />,
        buyBack: <BuyBack config={maybeConfig} />,
        customerForgotPasswordForm: <ForgotPasswordForm config={maybeConfig} />,
        customerResetPasswordForm: <ResetPasswordForm config={maybeConfig} />,
        customerLoginForm: <LoginForm config={maybeConfig} />,
        customerRegisterForm: <RegisterForm config={maybeConfig} />,
        detailRelatedProducts: <RelatedProducts config={maybeConfig} />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export default componentMapper;

export const componentMap = {
    ctaPopup: CtaPopup,
};
