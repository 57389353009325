import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormList from 'vkid-ui/lib/Components/FormList';
import FormListItem from 'vkid-ui/lib/Components/FormListItem';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
            formKey: string;
            loginUrl: string;
        };
        labels: {
            requiredField: string;
            yourEmail: string;
            resetMyPassword: string;
            goBack: string;
            instructions: string;
            errorMessage: string;
        };
    };
}

const ForgotPasswordForm: React.FC<IProps> = ({ config }) => {
    const formElement = useRef<HTMLFormElement | null>(null);
    const [email, setEmail] = useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required(config.labels.requiredField),
        }),
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <div className="layout-login">
            <div className="layout-login__column">
                {formik.errors.email && <div className="cart-callout intent-danger">{config.labels.errorMessage}</div>}
                <form
                    action={config.formConfig.actionUrl}
                    method="post"
                    ref={formElement}
                    onSubmit={formik.handleSubmit}
                    id="form-validate"
                >
                    <input type="hidden" name="form_key" value={config.formConfig.formKey} />
                    <FormList>
                        <FormListItem
                            label={config.labels.yourEmail}
                            error={formik.touched.email && formik.errors.email}
                            description={config.labels.instructions}
                            required={true}
                            labelFor="email"
                        >
                            <ControlInput
                                type="text"
                                name="email"
                                id="email"
                                hasError={!!(formik.touched.email && formik.errors.email)}
                                value={formik.values.email}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setEmail(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                            />
                        </FormListItem>
                    </FormList>
                    <Buttons>
                        <Button type="submit" intent="primary" title={config.labels.resetMyPassword} />
                        <Button type="anchor" href={config.formConfig.loginUrl} title={config.labels.goBack} />
                    </Buttons>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
