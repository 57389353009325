import * as React from 'react';
import { useEffect, useState } from 'react';
import { empty } from '../../../helpers/empty';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { ISelectedAttributes, ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { IValueLabel } from 'components/Financing/Interfaces/IFinance';
import { IAttribute, IAttributeOptions } from 'components/Catalog/Interfaces/IAttribute';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        additionalTechSpecs?: string;
        initialTechSpecs: IValueLabel[];
    };
}

const ProductTechnicalSpecs = (props: IProps) => {
    const { config } = props;
    const [selectedProductInformation, setSelectedProductInformation] = useState<ISelectedProductInformation>();
    const [swatchConfig, setSwatchConfig] = useState<ISwatchConfig>();
    const [techData, setTechData] = useState<IValueLabel[]>(config.initialTechSpecs);

    const effect = (e) => {
        setSelectedProductInformation(undefined);
        setSwatchConfig(undefined);

        if (!empty(e.detail.selectedProductInformation)) {
            setSelectedProductInformation(e.detail.selectedProductInformation);
        }

        if (!empty(e.detail.swatchConfig)) {
            setSwatchConfig(e.detail.swatchConfig);
        }
    };

    useEffect(() => {
        window.addEventListener('product-configuration-change', effect);
        return function cleanup() {
            window.removeEventListener('product-configuration-change', effect);
        };
    }, [selectedProductInformation, swatchConfig]);

    const getSelectedAttributesData = () => {
        if (!selectedProductInformation || !swatchConfig) {
            return [];
        }

        const techDataPairs: IValueLabel[] = [];

        selectedProductInformation?.selectedAttributes?.forEach((selectedAttribute: ISelectedAttributes) => {
            Object.values(swatchConfig.options.attributes).forEach((attribute: IAttribute) => {
                if (attribute.id === selectedAttribute.attributeId) {
                    Object.values(attribute.options).forEach((attributeOption: IAttributeOptions) => {
                        if (attributeOption.id === selectedAttribute.optionId) {
                            techDataPairs.push({
                                label: attribute.label,
                                value: attributeOption.label,
                            });
                        }
                    });
                }
            });
        });

        return selectedProductInformation?.selectedAttributes ? techDataPairs : config.initialTechSpecs;
    };

    useEffect(() => {
        if (!empty(selectedProductInformation) && !empty(swatchConfig)) {
            setTechData([]);
            setTechData(getSelectedAttributesData());
        }
    }, [selectedProductInformation, swatchConfig]);

    return (
        <>
            {techData &&
                techData.length > 0 &&
                techData.map((techDataItem: IValueLabel) => {
                    return (
                        <tr key={techDataItem.value}>
                            <th>{techDataItem.label}</th>
                            <td>{techDataItem.value}</td>
                        </tr>
                    );
                })}
            {!empty(props.config.additionalTechSpecs) && (
                <RenderHTML html={config.additionalTechSpecs} nowrapper={true} />
            )}
        </>
    );
};

export default ProductTechnicalSpecs;
