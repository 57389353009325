import { request } from 'data/requests/request';

export interface IGetHasInsuranceResponse {
    hasInsurance: boolean;
    switchingEnabled: boolean;
}

const getHasInsuranceRequest = request({
    type: 'hasInbankRentalInsurance',
    url: 'inbank_rental/insurance/get',
    method: 'GET',
    notApi: true,
});

export default getHasInsuranceRequest;
