import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import SiteMenu from 'vkid-ui/lib/Components/SiteMenu';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { updateStore } from 'data/overlays/updateStore';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { useEffect } from 'react';

export interface IMainMenuProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const MainMenu: React.FunctionComponent<IMainMenuProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    const [scroll, setScroll] = React.useState<number>(0);

    const handleScroll = () => {
        const currentScroll = window.pageYOffset;
        const doc = document.documentElement;
        if (currentScroll >= 48) {
            doc.classList.add('is-sticky');
            if (currentScroll < scroll) {
                doc.classList.add('show-menu');
            } else {
                doc.classList.remove('show-menu');
            }
            setScroll(currentScroll);
        } else {
            doc.classList.remove('is-sticky');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scroll]);

    return (
        <SiteMenu
            dom={props.dom}
            mobileMenuOpen={includes(openOverlays, 'mobilemenu')}
            openMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'mobilemenu' }));
            }}
            closeMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
            }}
            target={props.config?.target || undefined}
        />
    );
};

MainMenu.displayName = 'MainMenu';

export default MainMenu;
