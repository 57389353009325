import { postRequest } from 'data/requests/postRequest';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';

const addCouponRequest = (code: string, quoteIdMask?: string) =>
    postRequest({
        method: 'PUT',
        type: 'response',
        url: isLoggedIn() ? `carts/mine/coupons/${code}` : `guest-carts/${quoteIdMask}/coupons/${code}`,
    });

export default addCouponRequest;
